<template>
  <div class="row" v-has-permission="`mytrip_formPersonDetails`">
    <div class="col-12">
      <div class="card">
        <div class="row">

          <!-- AGENCY INFORMATION -> NAME EMAIL URL AND PHONE -->
          <div v-has-permission="`mytrip_formPersonDetails_agency`" class="Agency col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Agency:
                </h4>
                <ul class="list-inline card-body">
                  <li class="list-inline-item">Name:  {{fullInformation.mytrip_agencies.name}}</li>
                  <li class="list-inline-item">Email: {{fullInformation.mytrip_agencies.email}}</li>
                  <li class="list-inline-item">Url:   {{fullInformation.mytrip_agencies.url}}</li>
                  <li class="list-inline-item">Phone : {{fullInformation.mytrip_agencies.phone}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END AGENCY INFORMATION -> NAME EMAIL URL AND PHONE -->

          <!-- PERSON INFORMATION -> NAME EMAIL AND PHONE  -->
          <div v-has-permission="`mytrip_formPersonDetails_person`" class="person col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                    Person:
                  </h4>
                <ul class="list-inline card-body">
                  <li class="list-inline-item">Name: {{fullInformation.person_name}}</li>
                  <li class="list-inline-item">Email: {{fullInformation.person_email}}</li>
                  <li class="list-inline-item">Phone: {{fullInformation.person_phone}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END PERSON INFORMATION -> NAME EMAIL AND PHONE  -->

          <!-- PERSONAL DETAILS -> ,DATETIME,DAYS,FULL DATETIME  -->
          <div v-has-permission="`mytrip_formPersonDetails_personData`" class="person-travel-data col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Person Data:
                </h4>
                <ul class="list-inline card-body">
                  <li v-has-permission="`mytrip_formPersonDetails_abouts`" class="list-inline-item">Group type: {{fullInformation.about_group_type}}</li>
                  <li v-has-permission="`mytrip_formPersonDetails_abouts`" class="list-inline-item">Adults : {{fullInformation.about_adult}}</li>
                  <li v-has-permission="`mytrip_formPersonDetails_abouts`" class="list-inline-item">Children : {{fullInformation.about_children}}</li>
                  <li class="list-inline-item w-100" v-if="fullInformation.visit_dateTime !== 'undefined' && fullInformation.last_date_selected !== null && fullInformation.last_date_selected !== 'undefined' ">Travel date:
                    <ul class="card-body">
                      <li class="color-red"    v-if="fullInformation.visit_dateTime === fullInformation.last_date_selected">Date Time: {{fullInformation.visit_dateTime}}</li>
                      <li class="color-silver" v-if="fullInformation.visit_dateTime !== fullInformation.last_date_selected">Date Time: {{fullInformation.visit_dateTime}}</li>
                      <li class="color-red"    v-if="fullInformation.visit_monthDateTime !==null && fullInformation.visit_monthDateTime !== 'undefined' && fullInformation.visit_monthDateTime === fullInformation.last_date_selected">month Date Time: {{fullInformation.visit_monthDateTime}}</li>
                      <li class="color-silver" v-if="fullInformation.visit_monthDateTime !==null && fullInformation.visit_monthDateTime !== 'undefined' && fullInformation.visit_monthDateTime !== fullInformation.last_date_selected">month Date Time: {{fullInformation.visit_monthDateTime}}</li>
                      <li v-if="fullInformation.index_orginalDateTime !==null && fullInformation.index_orginalDateTime !=='null' && fullInformation.index_orginalDateTime !== 'undefined'">Full Date: {{fullInformation.index_orginalDateTime}}</li>
                    </ul>
                  </li>
                  <li class="list-inline-item">Days : {{fullInformation.visit_numberDays}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END PERSONAL DETAILS -> GROUP TYPE,DATETIME,DAYS,FULL DATETIME  -->

          <!-- TRAVEL THINGS  -->
          <div v-has-permission="`mytrip_formPersonDetails_travelThings`" class="TravelThings col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item coloring card-header">
                <h4 class="text-bold card-title">
                TravelThings:
                </h4>

                <ul class="list-inline card-body" v-if="fullInformation.things!== [] || fullInformation.things !== null">
                  <li class="list-group-item  card-header" v-for="(thing,key) in fullInformation.things"  v-bind:key="key">
                    <span class="text-bold color-red">{{key}}:</span>
                    <ul class="list-inline card-body">
                      <li class="list-inline-item" v-for="item in thing"  v-bind:key="item">({{item}})</li>
                    </ul>
                  </li>
                </ul>
                <div class="text-bold color-red" v-if="fullInformation.things === [] || fullInformation.things === null">Nothing is Selected</div>
              </li>
            </ul>
          </div>
          <!-- END TRAVEL THINGS  -->

          <!-- EXTRA SERVICES  -->
          <div v-has-permission="`mytrip_formPersonDetails_extraServices`" class="Extra_Services col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Extra Services:
                </h4>
                <ul class="list-inline card-body">
                  <li class="list-inline-item" v-if="fullInformation.extra_services !== null" v-for="(extraItem,key) in fullInformation.extra_services" v-bind:key="key">({{extraItem}})</li>
                  <div class="text-bold color-red" v-if="fullInformation.extra_services === null">
                    Nothing is Selected
                  </div>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END EXTRA SERVICES  -->

          <!-- DESCRIBE  -->
          <div v-has-permission="`mytrip_formPersonDetails_describe`" class="Describe col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Describe:
                </h4>
                <ul class="list-inline  card-body" v-if="fullInformation.describe_services!== [''] && fullInformation.describe_services!== null">
                  <li class="list-inline-item" v-for="describe_services in fullInformation.describe_services" v-bind:key="describe_services">({{describe_services}})</li>
                </ul>
                <div class="text-bold color-red" v-if="fullInformation.describe_services === [''] || fullInformation.describe_services === null">Nothing is Selected</div>
              </li>
            </ul>
          </div>
          <!-- END DESCRIBE  -->

          <!-- MESSAGE  -->
          <div v-has-permission="`mytrip_formPersonDetails_message`" class="Message col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">Message:</h4><br>
                <ul class="list-inline  card-body">
                  <li class="list-inline-item">{{fullInformation.describe_description}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END MESSAGE  -->

          <!-- BUDGET  -->
          <div v-has-permission="`mytrip_formPersonDetails_budget`" class="Budget col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Budget:
                </h4>
                <ul class="list-inline card-body">
                  <li class="list-inline-item">Budget : {{fullInformation.budget_type}}</li>
                  <li class="list-inline-item">TotalBudget : {{fullInformation.budget_total}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END BUDGET  -->

          <!-- URL  -->
          <div v-has-permission="`mytrip_formPersonDetails_url`" class="url col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Url:
                </h4>
                <ul class="list-inline card-body">
                  <li class="list-inline-item">{{fullInformation.webURL}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END URL  -->

          <!-- SEND EMAIL FORM  -->
          <div v-has-permission="`mytrip_formPersonDetails_sendEmail`" class="col-11 m-t-b-3">
            <div class="form-group">
              <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
            </div>
            <div class="text-center">
              <button class="btn btn-primary" @click="email">Send Email</button>
            </div>
          </div>
          <!-- END SEND EMAIL FORM  -->

        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import Vue from 'vue';
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import axios from 'axios';
    import { Modal, BaseAlert } from 'src/components';

    Vue.use( CKEditor );
    export default {
    components:{
        Modal,
        BaseAlert
    },
    data(){
        return {
            fullInformation :{
                "id": 1,
                "agency_id": 1,
                "user_id": 1,
                "index_number_days": "",
                "index_group_type": "",
                "index_date_time": "",
                "index_month_date": "",
                "index_orginalDateTime": null,
                "info_number_days": "",
                "info_group_type": "",
                "info_date_time": "",
                "about_group_type": "",
                "about_adult": "1",
                "about_children": "1",
                "visit_dateTime": "",
                "visit_monthDateTime": "",
                "visit_numberDays": "",
                "person_name": "",
                "person_email": "",
                "person_phone": "",
                "budget_type": "",
                "budget_total": "",
                "status": "",
                "describe_services": [],
                "describe_description": "",
                "extra_services": [],
                "things": {
                    "Things" : [],
                    "Hotel" : [],
                    "Place" : [],
                },
                "webURL": "",
                "last_date_selected": "",
                "created_at": "",
                "updated_at": "",
                "mytrip_agencies": {
                    "id": 1,
                    "user_id": 1,
                    "operator_id": 1,
                    "name": "",
                    "email": "",
                    "url": "",
                    "phone": "",
                    "image_logo": "",
                    "image_background": "",
                    "website_logo": "",
                    "website_background": "",
                    "status": "",
                    "created_at": "",
                    "updated_at": ""
                },
                "user": {}
            },
            editor: ClassicEditor ,
            editorData: `Dear [USERNAME],<br>
                <br>Thanks for your tour request. Your request number is DATETIME via [COMPANY_NAME]<br>
                If you have any questions, please feel free to give us a call: [COMPANY_PHONE]<br>
                <br>Regards,<br>`,
            editorConfig: {
                // The configuration of the editor.
            }
        }
    },
    mounted(){
        this.values();
    },
    methods:{
        values (){
            this.fullInformation = this.$route.params.data;
            this.fullInformation.things = JSON.parse(this.fullInformation.things);
            this.update();
        },
        email(){

            let data = {
                'body'              : this.editorData,
                'agency_id'         : this.fullInformation.agency_id,
                'mytrip_data_id'    : this.fullInformation.id,
            };

            axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/mytripData/send-email-from-admin' , data , User.prototype.getHeader())
                .then(response => {
                    this.notifyVue('top','right','success','The operation you requested was successful');
                })
                .catch(response =>{
                    this.notifyVue('top','right','warning','The operation you requested did not succeed');
                });
        },
        update(){
            if (this.fullInformation.status !== 'read'){
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/mytripData/update-mytrip-info  ' , {'id' : this.fullInformation.id} , User.prototype.getHeader());
            }
        },
        notifyVue(verticalAlign , horizontalAlign , status , message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: status
            });
        }
    }
};
</script>

<style>

  .Agency ul.list-group.list-group-flush,
  .person-travel-data ul.list-group.list-group-flush,
  .Extra_Services ul.list-group.list-group-flush,
  .Message ul.list-group.list-group-flush,
  .url ul.list-group.list-group-flush{
    background: rgba(130,130,130,00.1);
  }
  .m-t-b-3{
    margin: 30px auto !important;
  }
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline{
    padding: 14px;
  }

  .card .alert {
    position: relative !important;
    width: 100%;
  }
  .color-red{
    color:red !important;
  }
  .color-silver{
    color:silver !important;
  }
</style>
