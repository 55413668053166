<template>
  <div>
    <div class="content">
      <div class="row">
        <div class="col-12" v-has-permission="`transfer_dashboard_chart`">
          <card class="card-chart" v-if="MonthlyChart.status">
            <template slot="header">
              <div class="row">
                <div class="col-6 text-left">
                  <h5 class="card-category">Monthly Chart</h5>
                </div>
                <!-- BUTTONS FOR CHANGE CHART.JS  -->
                <div class="col-sm-6 d-flex d-sm-block">
                  <div
                    class="btn-group btn-group-toggle float-right"
                    data-toggle="buttons"
                  >
                    <label
                      v-for="(option, index) in MonthlyCategories"
                      :key="option.name"
                      class="btn btn-sm btn-primary btn-simple"
                      :class="{ active: Monthly.activeIndex === index }"
                      :id="index"
                    >
                      <input
                        type="radio"
                        @click="initMonthChart(index)"
                        name="options"
                        autocomplete="off"
                        :checked="Monthly.activeIndex === index"
                      />
                      <span class="d-none d-sm-block">{{ option.name }}</span>
                      <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                    </label>
                  </div>
                </div>
                <!-- END BUTTONS FOR CHANGE CHART.JS  -->

              </div>
            </template>
            <!-- CHART -->
            <div class="chart-area">
              <line-chart
                style="height: 100%"
                ref="monthChart"
                :chart-data="Monthly.chartData"
                :gradient-colors="Monthly.gradientColors"
                :gradient-stops="Monthly.gradientStops"
                :extra-options="Monthly.extraOptions"
              >
              </line-chart>
            </div>
            <!-- END CHART -->

          </card>
        </div>
      </div>
    </div>
      <div v-has-permission="`transfer_dashboard_formList`">
        <div class="col-12">
          <card card-body-classes="table-full-width">
            <div>
              <!-- BASE INPUT FOR SEARCH  -->
              <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <base-input>
                  <el-input
                    v-has-permission="`transfer_search`"
                    type="search"
                    class="mb-3 search-input"
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    aria-controls="datatables"
                    v-model="searchQuery"
                  >
                  </el-input>
                </base-input>
              </div>
              <!-- END BASE INPUT FOR SEARCH  -->

              <!-- TABLE SHOW DETAILS -->
              <el-table style="width: 100%" :data="arrayData">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>

                <!-- STATUS CHECK -->
                <el-table-column min-width="185" label="Status" align="center">
                  <div slot-scope="{ row }">
                    <p v-if="row.status === 'YES'" style="color: green">read</p>
                    <p v-if="row.status === 'NO'"  style="color: red;">unread</p>
                  </div>
                </el-table-column>
                <!-- END STATUS CHECK -->

                <!-- VISA CHECK -->
                <el-table-column min-width="185" label="Visa" align="center">
                  <div slot-scope="{ row }">
                    <i v-if="row.visa === 'YES'" class="fab fa-cc-visa" style="font-size: 25px;color: green"></i>
                    <i v-if="row.visa === 'NO'" class="fab fa-cc-visa"  style="font-size: 25px;color: red"></i>
                  </div>
                </el-table-column>
                <!-- END VISA CHECK -->

                <!-- PAYMENT CHECK -->
                <el-table-column min-width="185" label="Payment" align="center">
                  <div slot-scope="{ row }">
                    <i v-if="row.payment_status === 'cancel_paid'" class="fas fa-credit-card" style="font-size: 25px;color:red" title="cancel payment"></i>
                    <i v-if="row.payment_status === 'paid'"        class="fas fa-credit-card" style="font-size: 25px;color:green" title="success payment"></i>
                    <i v-if="row.payment_status === 'fail_paid'"   class="fas fa-credit-card" style="font-size: 25px;color:yellow" title="failed payment"></i>
                  </div>
                </el-table-column>
                <!-- END PAYMENT CHECK -->

                <!-- BUTTON FOR READ MORE -->
                <el-table-column min-width="135" align="right" label="Actions">
                  <div slot-scope="props">
                    <router-link
                      v-has-permission="`transfer_dashboard_formList_read`"
                      :to="{ name: 'Transfer information', params: { data: props.row } }"
                      type="success"
                      class="animation-on-hover btn btn-behance"
                      style="padding: 12px; font-size: 14px"
                    >Read More
                    </router-link>
                  </div>
                </el-table-column>
                <!-- END BUTTON FOR READ MORE -->

              </el-table>
              <!-- END TABLE SHOW DETAILS -->

            </div>
            <div class="pagination_number">
              <pagination :data="tableData" @pagination-change-page="getResults" :limit="3"></pagination>
            </div>
          </card>
        </div>
      </div>
  </div>
</template>
<script>
    import Vue from 'vue';
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import { BasePagination } from 'src/components';
    import laravel_vue_pagination from 'laravel-vue-pagination';
    import axios from 'axios';
    import LineChart from '@/components/Charts/LineChart';
    import config from '@/config';
    import * as chartConfigs from '@/components/Charts/config';
    let monthChartData = [];
    let monthChartDatasetOptions = {
        fill: true,
        borderColor: config.colors.primary,
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: config.colors.primary,
        pointBorderColor: 'rgba(255,255,255,0)',
        pointHoverBackgroundColor: config.colors.primary,
        pointBorderWidth: 20,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 4,
    };
    Vue.component('pagination', laravel_vue_pagination);

    export default {
        components: {
            BasePagination,
            LineChart,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        computed: {
            MonthlyCategories() {
                return [{ name: 'Success', icon: 'tim-icons icon-single-02' }, { name: 'Fail', icon: 'tim-icons icon-gift-2' }, { name: 'Cancel', icon: 'tim-icons icon-tap-02' }];
            }
        },
        data() {
            return {
                tableData :{},
                tableColumns: [
                    {
                        prop: 'from',
                        label: 'From',
                        minWidth: 100
                    },
                    {
                        prop: 'to',
                        label: 'To',
                        minWidth: 100
                    },
                    {
                        prop: 'city',
                        label: 'City',
                        minWidth: 150
                    },
                    {
                        prop: 'email',
                        label: 'Email',
                        minWidth: 250
                    },
                    {
                        prop: 'arrival_date',
                        label: 'Arrival Date',
                        minWidth: 250
                    }
                ],
                arrayData :[],
                searchQuery :"",
                MonthlyChart: {
                    monthChartLabels :[],
                    monthChartData :[],
                    status : false
                },
                Monthly: {
                    activeIndex: 0,
                    chartData: {
                        datasets: [{
                            ...monthChartDatasetOptions,
                            data: monthChartData[0]
                        }],

                        labels: []
                    },
                    extraOptions: {
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                        responsive: true,
                        tooltips: {
                            backgroundColor: '#f5f5f5',
                            titleFontColor: '#333',
                            bodyFontColor: '#666',
                            bodySpacing: 4,
                            xPadding: 12,
                            mode: "nearest",
                            intersect: 0,
                            position: "nearest"
                        },
                        scales: {
                            yAxes: [{
                                barPercentage: 1.0,
                                gridLines: {
                                    drawBorder: false,
                                    color: 'rgba(29,140,248,0.0)',
                                    zeroLineColor: "transparent",
                                },
                                ticks: {
                                    suggestedMin: 10,
                                    suggestedMax: 10,
                                    padding: 0,
                                    fontColor: "#000000"
                                }
                            }],

                            xAxes: [{
                                barPercentage: 1.0,
                                gridLines: {
                                    drawBorder: false,
                                    color: 'rgba(220,53,69,0.1)',
                                    zeroLineColor: "transparent",
                                },
                                ticks: {
                                    padding: 0,
                                    fontColor: "#000000"
                                }
                            }]
                        }
                    },
                    gradientColors: config.colors.primaryGradient,
                    gradientStops: [1, 0.4, 0],
                    categories: []
                },
            };
        },
        watch:{
            'searchQuery'(){
                if (!! this.searchQuery.length){
                    this.search(this.searchQuery)
                } else {
                    this.getResults(1);
                }
            }
        },
        mounted() {
            this.getResults();
            this.getSuccessMonthlyChart();
        },
        methods: {

            /**
             * get values and data page by page
            **/
            getResults(page = 1 ){
                let data = {
                    'page'  : page
                };
                axios.post(Vue.prototype.websiteURL + 'admin/transfer/panel/transfer/get-all/transfer-data' , data , User.prototype.getHeader())
                    .then((response)=>{
                        this.tableData = response.data.data;
                        this.arrayData = this.tableData.data;
                    })
            },

            /**
             * get success monthly chart
            **/
            getSuccessMonthlyChart(){
                axios.post(Vue.prototype.websiteURL + 'admin/transfer/panel/transfer/chart/get-chart-data' , {'type' : 'paid'} , User.prototype.getHeader())
                    .then((response)=>{
                        this.MonthlyChart.monthChartLabels      = response.data.labels;
                        this.Monthly.chartData.labels           = this.MonthlyChart.monthChartLabels;
                        this.MonthlyChart.monthChartData.push(Object.values(response.data.values));
                        this.Monthly.chartData.datasets[0].data = this.MonthlyChart.monthChartData[0];
                        this.getFailedMonthlyChart();
                    })
            },

            /**
             * get failed values in 12 month
            **/
            getFailedMonthlyChart(){
                axios.post(Vue.prototype.websiteURL + 'admin/transfer/panel/transfer/chart/get-chart-data' , {'type' : 'fail_paid'} , User.prototype.getHeader())
                    .then((response)=>{
                        this.MonthlyChart.monthChartLabels      = response.data.labels;
                        this.Monthly.chartData.labels           = this.MonthlyChart.monthChartLabels;
                        this.MonthlyChart.monthChartData.push(Object.values(response.data.values));
                        this.Monthly.chartData.datasets[0].data = this.MonthlyChart.monthChartData[0];
                        this.getCancelMonthlyChart();
                    })
            },

            /**
             * get canceled transfers in 12 month
            **/
            getCancelMonthlyChart(){
                axios.post(Vue.prototype.websiteURL + 'admin/transfer/panel/transfer/chart/get-chart-data' , {'type' : 'cancel_paid'} , User.prototype.getHeader())
                    .then((response)=>{
                        this.MonthlyChart.monthChartLabels      = response.data.labels;
                        this.Monthly.chartData.labels           = this.MonthlyChart.monthChartLabels;
                        this.MonthlyChart.monthChartData.push(Object.values(response.data.values));
                        monthChartData                          = this.MonthlyChart.monthChartData;
                        this.Monthly.chartData.datasets[0].data = this.MonthlyChart.monthChartData[0];
                        this.MonthlyChart.status = true;

                    })
            },
            /**
             * search
            **/
            search(search){
                let data = {
                    'searchData'  : search
                };
                axios.post(Vue.prototype.websiteURL + 'admin/transfer/panel/transfer/search/transfer-data' , data , User.prototype.getHeader())
                    .then((response)=>{
                        this.tableData = response.data.data.data;
                        this.arrayData = this.tableData.data;
                    })

            },

            /**
             * init month chart to success, failed, cancel
            **/
            initMonthChart(index) {
                let chartData = {
                    datasets: [{
                        ...monthChartDatasetOptions,
                        data: monthChartData[index]
                    }],
                    labels: this.MonthlyChart.monthChartLabels
                };
                this.$refs.monthChart.updateGradients(chartData);
                this.Monthly.chartData   = chartData;
                this.Monthly.activeIndex = index;
            }
        },
    };
</script>

<style>
  .mytrip_dashboard td,.mytrip_dashboard th{
    text-align: center !important;
  }
</style>
