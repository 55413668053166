<template>
  <div class="row mytrip_operators">
    <HotelRegistrationForm></HotelRegistrationForm>
  </div>
</template>
<script>
    import HotelRegistrationForm from 'src/components/Form/HotelRegistrationForm.vue';
    export default {
        components: {
            HotelRegistrationForm
        },
        data() {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
        mounted() {

        },
        beforeDestroy() {

        }
    };
</script>

<style></style>
