<template>
    <div class="review_dashboard">
        <review-table></review-table>
    </div>
</template>
<script>
import reviewTable from "src/components/Review/DashboardTable.vue";
export default {
    components: {
        reviewTable
    },
    data() {
        return {};
    },
    methods: {}
};
</script>
