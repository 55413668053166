<template>
  <div class="transfer_dashboard">
    <transfer-table></transfer-table>
  </div>
</template>
<script>
    import transferTable from 'src/components/Transfer/DashboardTable.vue';
    export default {
        components: {
            transferTable
        },
        data() {
            return {

            };
        },
        methods: {

        }
    };
</script>
