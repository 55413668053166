<template>
    <div class="row mytrip_things">
        <hotels-table></hotels-table>
    </div>
</template>
<script>
import hotelsTable from "src/components/Hotel/HotelsTable.vue";
export default {
    components: {
        hotelsTable
    }
};
</script>
