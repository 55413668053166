<template>
    <div class="row review-info" v-has-permission="`review`">
        <div class="col-12">
            <div class="card">
                <div class="row">
                    <div class="col-12 pt-3 pl-5">
                        <div>
                            <div>Tags:</div>
                            <el-select
                                multiple
                                class="select-primary"
                                collapse-tags
                                v-model="selects.multiple"
                                placeholder="Multiple Select"
                                @change="changetags()"
                            >
                                <el-option
                                    v-for="option in selects.values"
                                    class="select-primary"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <!-- PERSON INFORMATION-->
                    <div v-has-permission="`review`" class=" col-12">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item card-header">
                                <h4 class="text-bold card-title">
                                    Person:
                                </h4>
                                <ul class="list-inline card-body">
                                    <li>Name: {{ data.name }}</li>
                                    <li>Email:{{ data.email }}</li>
                                    <li>arrival: {{ data.options.arrival }}</li>
                                    <li>
                                        departure: {{ data.options.departure }}
                                    </li>
                                    <li v-show="data.options.phone != null">
                                        Phone: {{ data.options.phone }}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- END PERSON INFORMATION-->
                    <!-- Options INFORMATION-->
                    <div v-has-permission="`review`" class=" col-12">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item card-header">
                                <h4 class="text-bold card-title">
                                    Options:
                                </h4>
                                <ul class="list-inline card-body">
                                    <li>evaluation: {{ data.evaluation }}</li>
                                    <li>group_type:{{ data.group_type }}</li>
                                    <li>recommended: {{ data.recommended }}</li>
                                    <li>origin: {{ data.options.origin }}</li>
                                    <li>object: {{ data.options.object }}</li>
                                    <li>nights: {{ data.options.nights }}</li>
                                    <li>city: {{ data.options.city }}</li>
                                    <li>country: {{ data.options.country }}</li>
                                    <li>
                                        accommodation:
                                        {{ data.options.accommodation }}
                                    </li>
                                    <li>adults: {{ data.options.adults }}</li>
                                    <li>
                                        children: {{ data.options.children }}
                                    </li>
                                    <li v-show="data.pricing != null">
                                        pricing: {{ data.pricing }}
                                    </li>
                                    <li v-show="data.options.phone != null">
                                        phone: {{ data.options.phone }}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- END Options INFORMATION-->
                    <!-- More INFORMATION-->
                    <div v-has-permission="`review`" class=" col-12">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item card-header">
                                <h4 class="text-bold card-title">
                                    More:
                                </h4>
                                <ul class="list-inline card-body">
                                    <li v-show="data.title != null">
                                        title: {{ data.title }}
                                    </li>
                                    <li v-show="data.body != null">
                                        body: {{ data.body }}
                                    </li>
                                    <li>like: {{ data.like_count }}</li>
                                    <li>dislike: {{ data.dislike_count }}</li>
                                    <li
                                        v-show="data.options.images.length != 0"
                                    >
                                        images:
                                        <ol>
                                            <li
                                                v-for="img in data.options
                                                    .images"
                                                :key="img"
                                            >
                                                <a :href="img" target="_blank"
                                                    >image link</a
                                                >
                                            </li>
                                        </ol>
                                    </li>
                                    <div v-show="data.answers.length != 0">
                                        answers:
                                        <ol
                                            v-for="answer in data.answers"
                                            :key="answer.title"
                                            class="answer-block"
                                        >
                                            <div>
                                                <h4 class="silver-color">
                                                    Title:
                                                </h4>
                                                {{ answer.title }}
                                            </div>
                                            <div>
                                                <h4 class="silver-color">
                                                    Body:
                                                </h4>
                                                {{ answer.body }}
                                            </div>
                                        </ol>
                                    </div>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- END More INFORMATION-->
            <div class="row" v-show="data.status == 'deactive'">
                <button class="btn btn-behance m-auto" @click="changeStatus">
                    active review
                </button>
            </div>

            <div v-show="data.status == 'active'">
                <card>
                    <h4 class="text-bold card-title">
                        Answer to this review by Apochi
                    </h4>

                    <form @submit.prevent>
                        <base-input
                            label="Title"
                            type="text"
                            placeholder="Review Title"
                            required
                            v-model="answer.title"
                        >
                        </base-input>
                        <base-input label="Body for Answer">
                            <textarea
                                class="form-control"
                                id="answer-body"
                                rows="7"
                                required
                                placeholder="Review Body"
                                v-model="answer.body"
                            ></textarea>
                        </base-input>
                        <div class=" m-auto text-center mt-3">
                            <button
                                class="btn btn-behance"
                                @click="submitAnswer"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import { Modal, BaseAlert } from "src/components";
import { Select, Option } from "element-ui";

export default {
    components: {
        Modal,
        BaseAlert,
        [Select.name]: Select,
        [Option.name]: Option
    },
    data() {
        return {
            id: "",
            data: {
                id: null,
                name: null,
                email: null,
                evaluation: null,
                evaluation_type: null,
                title: null,
                body: null,
                group_type: null,
                recommended: null,
                pricing: null,
                answers: [],
                hashtags: [],
                options: {
                    origin: null,
                    object: null,
                    partner: null,
                    arrival: null,
                    departure: null,
                    nights: null,
                    city: null,
                    phone: null,
                    country: null,
                    accommodation: null,
                    adults: null,
                    children: null,
                    images: []
                },
                status: null,
                like_count: 0,
                dislike_count: 0
            },
            selects: {
                values: [],
                multiple: []
            },
            answer: {
                title: "",
                body: ""
            }
        };
    },
    mounted() {
        this.values();
        this.hashtags();
    },
    methods: {
        values() {
            this.id = this.$route.params.id;
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            let data = {
                id: this.id
            };
            axios
                .post(
                    Vue.prototype.websiteURL + "admin/review/show",
                    data,
                    headers
                )
                .then(response => {
                    this.data = response.data.data;
                    response.data.data.hashtags.forEach(element => {
                        this.selects.multiple.push(element.id);
                    });
                });
        },
        hashtags() {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            axios
                .get(Vue.prototype.websiteURL + "admin/hashtag", headers)
                .then(response => {
                    response.data.data.forEach(element => {
                        if (element.service === "Hotel") {
                            this.selects.values.push({
                                value: element.id,
                                label: element.service + ": " + element.name
                            });
                        }
                    });
                });
        },
        changetags() {
            let data = [];
            data.push({
                product_model: "Review",
                product_id: this.data.id,
                hashtag_id: this.selects.multiple
            });
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            axios
                .post(
                    Vue.prototype.websiteURL + "admin/hashtag/assign-tag",
                    data,
                    headers
                )
                .then(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                });
        },
        changeStatus() {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            let data = {
                id: this.id
            };
            axios
                .post(
                    Vue.prototype.websiteURL + "admin/review/update-status",
                    data,
                    headers
                )
                .then(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                    this.$router.push({ name: "Review Form" });
                });
        },
        notifyVue(verticalAlign, horizontalAlign, status, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: status
            });
        },
        submitAnswer() {
            if (this.answer.title == "" || this.answer.body == "") {
                return;
            }
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            let data = {
                id: this.data.id,
                title: this.answer.title,
                body: this.answer.body
            };
            axios
                .post(
                    Vue.prototype.websiteURL + "admin/review/answer",
                    data,
                    headers
                )
                .then(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                    this.$router.push({ name: "Review Form" });
                })
                .catch(error => {
                    this.notifyVue(
                        "top",
                        "right",
                        "warning",
                        "The operation you requested did not succeed"
                    );
                    console.log(error);
                });
        }
    }
};
</script>

<style>
.Agency ul.list-group.list-group-flush,
.person-travel-data ul.list-group.list-group-flush,
.Extra_Services ul.list-group.list-group-flush,
.Message ul.list-group.list-group-flush,
.url ul.list-group.list-group-flush {
    background: rgba(130, 130, 130, 00.1);
}
.m-t-b-3 {
    margin: 30px auto !important;
}
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    padding: 14px;
}

.card .alert {
    position: relative !important;
    width: 100%;
}
.color-red {
    color: red !important;
}
.color-silver {
    color: silver !important;
}
.review-info li {
    padding: 20px;
    font-size: 15px;
}
.review-info ul li:nth-child(even) {
    background: #ccc;
}
.review-info ol li:nth-child(even) {
    background: white;
}
.mt-3 {
    margin-top: 30px !important;
}
.answer-block {
    margin: 10px;
    border: solid 1px silver;
}
.silver-color {
    color: silver;
}
</style>
