<template>
  <div class="row">
    <div class="col-md-12">
      <el-table :data="companies">

        <!-- Index -->
        <el-table-column min-width="50"
                         type="index"
                         label="#"
        ></el-table-column>
        <!-- /Index -->

        <!-- Name -->
        <el-table-column min-width="300"
                         prop="name"
                         label="Name"
        >
        </el-table-column>
        <!-- /Name -->

        <!-- URL -->
        <el-table-column min-width="450"
                         prop="url"
                         label="URL"
        >
        </el-table-column>
        <!-- /URL -->

        <!-- Logo URL -->
        <el-table-column min-width="600"
                         prop="logoAddress"
                         label="Logo Address"
        >
        </el-table-column>
        <!-- /Logo URL -->

        <!-- White Logo URL -->
        <el-table-column min-width="600"
                         prop="whiteLogoAddress"
                         label="White Logo Address"
        >
        </el-table-column>
        <!-- /White Logo URL -->

        <!-- Email -->
        <el-table-column min-width="500"
                         prop="email"
                         label="Email"
        >
        </el-table-column>
        <!-- /Email -->

        <!-- Inside Payment Logo -->
        <el-table-column min-width="700"
                         prop="insideLogoPayment"
                         label="Inside Payment Logo"
        >
        </el-table-column>
        <!-- /Inside Payment Logo -->

        <!-- Invoice Logo -->
        <el-table-column min-width="600"
                         prop="invoiceLogo"
                         label="Invoice Logo"
        >
        </el-table-column>
        <!-- /Invoice Logo -->

        <!-- Direct Payment Email -->
        <el-table-column min-width="300"
                         prop="directPaymentEmail"
                         label="Direct Payment Email"
        >
        </el-table-column>
        <!-- /Direct Payment Email -->

        <!-- Service URL -->
        <el-table-column min-width="300"
                         prop="serviceUrl"
                         label="Service URL"
        >
        </el-table-column>
        <!-- /Service URL -->

        <!-- Domestic Trip Euro Rate -->
        <el-table-column min-width="170"
                         prop="domesticEur"
                         label="Domestic EUR"
        >
        </el-table-column>
        <!-- /Domestic Trip Euro Rate -->

        <!-- International Trip Euro Rate -->
        <el-table-column min-width="170"
                         prop="internationalEur"
                         label="International EUR"
        >
        </el-table-column>
        <!-- /International Trip Euro Rate -->

        <!-- Ticket Ad URL -->
        <el-table-column min-width="450"
                         prop="tktAdAddress"
                         label="TKT Ad Address"
        >
        </el-table-column>
        <!-- /Ticket Ad URL -->

      </el-table>
    </div>
  </div>
</template>


<script>
  import {Table, TableColumn} from 'element-ui'
  import axios from 'axios'
  import Vue from 'vue'

  export default {
      name: 'AgentsList',


      components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
      },


      data(){
          return{
              companies: []
          }
      },


      mounted() {
          this.getData();
      },

      methods:{
          getData(){
              axios.get( Vue.prototype.websiteURL + "admin/flight/" + "agents" )
                  .then(res => {
                      res.data.companies.forEach(function (item) {
                          this.companies.push({
                              'id'                : item.id,
                              'name'              : item.name,
                              'url'               : item.url,
                              'logoAddress'       : item.logo_address,
                              'whiteLogoAddress'  : item.white_logo_address,
                              'email'             : item.emails,
                              'insideLogoPayment' : item.logo_inside_payment,
                              'invoiceLogo'       : item.invoice_logo,
                              'directPaymentEmail': item.email_direct_payment,
                              'serviceUrl'        : item.service_url,
                              'domesticEur'       : item.dms_uro_price,
                              'internationalEur'  : item.int_uro_price,
                              'tktAdAddress'      : item.tkt_ad_address,
                          })
                      }.bind(this))

                    this.$root.$emit('get-companies', this.companies);
                  })
                  .catch(e => {
                      //
                  })
          }
      },
  }
</script>
