<template>
    <div>
        <div v-has-permission="`review`">
            <div class="col-12">
                <card card-body-classes="table-full-width">
                    <div>
                        <!-- CREATE A NEW THING -->
                        <div
                            class="col-6 d-flex justify-content-center justify-content-sm-between flex-wrap float-right"
                            style="text-align: right;display: block;width: 100%;"
                            v-has-permission="`hashtag`"
                        >
                            <!-- CREATE A NEW THING BUTTON -->
                            <base-input style="width: 100%;">
                                <router-link
                                    :to="{ name: 'Create Hashtag' }"
                                    type="success"
                                    class="animation-on-hover btn btn-behance"
                                    style="padding: 12px; font-size: 14px"
                                    >Create New Tag
                                </router-link>
                            </base-input>
                            <!-- END CREATE A NEW THING BUTTON -->
                        </div>
                        <!-- END CREATE A NEW THING -->

                        <!-- TABLE SHOW DETAILS -->
                        <el-table
                            style="width: 100%"
                            :data="arrayData"
                            class="center-header-table"
                        >
                            <el-table-column
                                v-for="column in tableColumns"
                                :key="column.label"
                                :min-width="column.minWidth"
                                :prop="column.prop"
                                :label="column.label"
                            >
                            </el-table-column>
                            <!-- BUTTON FOR READ MORE -->
                            <el-table-column min-width="135" label="Edit">
                                <div slot-scope="props">
                                    <!-- UPDATE A THING IF STATUS === YES  -->
                                    <router-link
                                        :to="{
                                            path:
                                                `/hashtag/update/` +
                                                props.row.name +
                                                `/` +
                                                props.row.service,
                                            params: props.row
                                        }"
                                        class="edit btn-link"
                                        type="warning"
                                        size="sm"
                                        icon
                                        v-has-permission="`hashtag`"
                                    >
                                        <i
                                            class="tim-icons icon-pencil"
                                            style="color: green"
                                        ></i>
                                    </router-link>
                                    <!-- END UPDATE A THING IF STATUS === YES  -->
                                </div>
                            </el-table-column>
                            <!-- END BUTTON FOR READ MORE -->
                        </el-table>
                        <!-- END TABLE SHOW DETAILS -->
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn, Select, Option } from "element-ui";
import axios from "axios";

export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            tableData: {},
            tableColumns: [
                {
                    prop: "name",
                    label: "name",
                    minWidth: 200
                },
                {
                    prop: "service",
                    label: "service",
                    minWidth: 250
                }
            ],
            arrayData: [],
            searchQuery: ""
        };
    },
    mounted() {
        this.getResults();
    },
    methods: {
        /**
         * get values and data page by page
         **/
        getResults() {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            axios
                .get(Vue.prototype.websiteURL + "admin/hashtag", headers)
                .then(response => {
                    this.tableData = response.data;
                    this.arrayData = this.tableData.data;
                });
        }
    }
};
</script>

<style>
.center-header-table td,
.center-header-table th {
    text-align: center !important;
}
</style>
