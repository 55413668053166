<template>
    <div class="content" id="visaSubmissionAdmin">
        <div id="preloader">
            <div class="loader">
                <div class="loader"></div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <card card-body-classes="table-full-width">
                    <div class="col-md-8 ml-auto mr-auto">
                        <h2 class="text-center">Visa Submissions</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                                <base-input>
                                    <el-input
                                        type="search"
                                        class="mb-3 search-input"
                                        clearable
                                        prefix-icon="el-icon-search"
                                        placeholder="Search records"
                                        v-model="searchQuery"
                                        aria-controls="datatables"
                                    >
                                    </el-input>
                                </base-input>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <el-table stripe :data="queriedData">
                                <el-table-column
                                    :min-width="80"
                                    sortable="custom"
                                    align="center"
                                    label="#"
                                    @sort-change="sortBy('id')"
                                >
                                    <div slot-scope="props">
                                        <base-button
                                            @click.native="sortBy('id')"
                                            class="btn-link"
                                            type="info"
                                            size="sm"
                                            icon
                                        >
                                            {{props.row.id}}
                                        </base-button>
                                    </div>
                                </el-table-column>

                                <el-table-column
                                    v-for="column in tableColumns"
                                    :key="column.label"
                                    :min-width="column.minWidth"
                                    :prop="column.prop"
                                    :label="column.label"
                                >
                                </el-table-column>
                                <el-table-column :min-width="100" align="center" label="Passport">
                                    <div slot-scope="props">
                                        <base-button
                                            @click.native="getFiles(props.row, 'passportScan')"
                                            class="btn-link"
                                            type="info"
                                            size="sm"
                                            icon
                                            v-if="props.row.passportScan"
                                        >
                                            <i class="tim-icons el-icon-download"></i>
                                        </base-button>
                                    </div>
                                </el-table-column>
                                <el-table-column :min-width="80" align="center" label="Photo">
                                    <div slot-scope="props">
                                        <base-button
                                            @click.native="getFiles(props.row, 'personalPhoto')"
                                            class="btn-link"
                                            type="info"
                                            size="sm"
                                            icon
                                            v-if="props.row.personalPhoto"
                                        >
                                            <i class="tim-icons el-icon-download"></i>
                                        </base-button>
                                    </div>
                                </el-table-column>
                                <el-table-column :min-width="80" align="center" label="Visa">
                                    <div slot-scope="props">
                                        <base-button
                                            @click.native="uploadClicked(props.row)"
                                            class="btn-link"
                                            type="info"
                                            size="lg"
                                            icon
                                            v-if="!props.row.visaFile"
                                        >
                                            <i class="tim-icons el-icon-upload2"></i>
                                        </base-button>
                                        <i v-if="props.row.visaFile" class="tim-icons icon-check-2"></i>
                                    </div>
                                </el-table-column>
                                <el-table-column
                                    :min-width="140"
                                    sortable="custom"
                                    align="center"
                                    label="Travel date"
                                    @sort-change="sortBy('travel_date_to_iran')"
                                >
                                    <div slot-scope="props">
                                        <base-button
                                            @click.native="sortBy('travel_date_to_iran')"
                                            class="btn-link"
                                            type="info"
                                            size="lg"
                                            icon
                                            style="width: 100%;text-align: center;"
                                        >

                                            <small>{{props.row.travel_date_to_iran}}</small>

                                        </base-button>
                                    </div>
                                </el-table-column>

                                <el-table-column
                                    :min-width="150"
                                    sortable="custom"
                                    align="center"
                                    label="Payment"
                                    @sort-change="sortBy('payment_status')"
                                >
                                    <div slot-scope="props">
                                        <base-button
                                            @click.native="sortBy('payment_status')"
                                            class="btn-link"
                                            type="info"
                                            size="lg"
                                            icon
                                            style="width: 100%;text-align: center;"
                                        >

                                            <small>{{props.row.payment_status}}</small>

                                        </base-button>
                                    </div>
                                </el-table-column>
                                <el-table-column
                                    :min-width="150"
                                    align="center"
                                    label="Supplements"
                                >
                                    <div slot-scope="props">
                                        <div v-html="props.row.supplements"></div>
                                    </div>
                                </el-table-column>



                                <el-table-column :min-width="150" align="center" label="Send form">
                                    <div slot-scope="props">
                                        <base-button
                                            @click.native="askConfirm(props.row, 'There will be Emails sending out to a lot of people!', 'Yes, send the mail!', 'emailForm' )"
                                            class="btn-link"
                                            type="info"
                                            size="sm"
                                            icon
                                        >
                                            <i class="tim-icons icon-send"></i>
                                        </base-button>
                                    </div>
                                </el-table-column>



                                <el-table-column :min-width="150" align="center" label="Reject">
                                    <div slot-scope="props">
                                        <base-button
                                            @click.native="askConfirm(props.row, 'A Rejection notice will be sent to the customer', 'Yes, send the rejection!', 'rejectVisa')"
                                            class="btn-link"
                                            type="info"
                                            size="sm"
                                            icon
                                        >
                                            <i class="tim-icons icon-scissors"></i>
                                        </base-button>
                                    </div>
                                </el-table-column>


                            </el-table>

                        </div>
                    </div>


                    <div
                        slot="footer"
                        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                        <div class="">
                            <p class="card-category">
                                Showing {{ from }} to {{ to }} of {{ total }} entries
                            </p>
                        </div>
                        <base-pagination
                            class="pagination-no-border"
                            v-model="activePage"
                            :per-page="parseInt(paginationPages.per_page)"
                            :total="parseInt(total)"
                        >
                        </base-pagination>
                    </div>

                </card>
            </div>


            <div>
                <modal name="visa-upload" class="visa-upload-modal">

                    <div class="row">
                        <div class="col-12 text-center p-3">
                            <any-upload accept="application/pdf" @change="onImageChange" select-text="Select PDF"/>
                        </div>
                        <div class="col-12 text-center p-3" style="margin-top: -42px">
                            <button class="btn btn-behance m-auto" @click="uploadVisa" :disabled="this.uploadActive">
                                Upload
                            </button>
                        </div>
                    </div>

                </modal>

            </div>

        </div>
    </div>
</template>
    <script>

        /**
         *
         * Don't hate this code it needs refactoring
         *
         * @fixme everything can be better
         * */

        import Vue from 'vue';
        import axios from 'axios';


        import {Table, TableColumn, Select, Option} from 'element-ui';
        import {BasePagination} from 'src/components';
        import vmodal from 'vue-js-modal'

        // js import
        import swal from 'sweetalert2'

        // style import
        import 'sweetalert2/dist/sweetalert2.css'

        Vue.use(vmodal);

        import {AnyUpload} from 'src/components/index';
        import Fuse from 'fuse.js';


        export default {
            components: {
                BasePagination,
                [Select.name]: Select,
                [Option.name]: Option,
                [Table.name]: Table,
                [TableColumn.name]: TableColumn,
                AnyUpload
            },
            data() {
                return {
                    searchQuery: '',
                    pagination: {
                        current_page: 1,
                        first_page_url: '',
                        from: '',
                        last_page: '',
                        last_page_url: '',
                        next_page_url: '',
                        per_page: '',
                        to: '',
                        total: ''
                    },
                    searchedData: [],
                    fuseSearch: null,
                    uploadActive: true,
                    tableColumns: [

                        {
                            prop: 'gender',
                            label: 'Title',
                            minWidth: 65
                        },
                        {
                            prop: 'name',
                            label: 'Name',
                            minWidth: 250
                        },
                        {
                            prop: 'email_address',
                            label: 'Email',
                            minWidth: 250
                        },


                    ],
                    tableData: [],
                    visa: '',
                    ax: '',
                    uploadVisaToken: '',
                    sortType: 'DESC',
                    sortyByglobal: 'id',
                    loadingSpinner: false,
                    activePage: 1
                };
            },
            methods: {
                uploadClicked(file) {
                    this.uploadVisaToken = file.token
                    this.$modal.show('visa-upload');
                },
                onImageChange(file) {
                    if (file) {
                        this.uploadActive = false
                    } else {
                        this.uploadActive = true
                    }
                    this.visa = file;
                },
                showSwal(title) {
                    swal({
                        title: title,
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill'
                    })
                },
                uploadVisa() {

                    this.uploadActive = true

                    let formData = new FormData();
                    formData.append('file', this.visa);
                    formData.append('token', this.uploadVisaToken);


                    axios.post(Vue.prototype.websiteURL + 'admin/visa/upload/visa',
                        formData,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                            },
                        }
                    ).then(function (data) {

                        this.closeModal('visa-upload');
                        this.showSwal('Visa Uploaded');
                        this.uploadActive = false
                        /**
                         *@fixme
                         * it must be reloaded after the operator hits OK which is not now
                         */
                        window.location.reload()

                    }.bind(this)).catch((data) => {

                        this.uploadActive = false

                    });

                },
                closeModal(modal) {
                    this.$modal.hide(modal);
                },
                takeCareOfPagination(response) {
                    this.pagination.current_page = response.current_page
                    this.pagination.first_page_url = response.first_page_url
                    this.pagination.from = response.from
                    this.pagination.last_page = response.last_page
                    this.pagination.last_page_url = response.last_page_url
                    this.pagination.next_page_url = response.next_page_url
                    this.pagination.per_page = response.per_page
                    this.pagination.to = response.to
                    this.pagination.total = response.total
                },
                getSubmissions(params) {
                    let data = {
                        'page': 1,
                        'sort': '',
                        'type': '',
                        'key': ''
                    };
                    if (params) {
                        data = params
                    }
                    let thepage = ''
                    if(data.page > 1){
                        thepage = '?page='+data.page
                    }
                    this.loadingSpinner = true

                    axios.post(Vue.prototype.websiteURL + 'admin/visa/get/submissions'+thepage, data, User.prototype.getHeader())
                        .then((response) => {
                            this.tableData = response.data.data
                            this.takeCareOfPagination(response.data)
                            this.loadingSpinner = false

                        })
                },

                askConfirm(value, message, btntxt, which){
                    swal({
                        title: 'Are you sure?',
                        text: message,
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonClass: 'btn btn-success btn-fill',
                        cancelButtonClass: 'btn btn-danger btn-fill',
                        confirmButtonText: btntxt,
                        buttonsStyling: false
                    }).then(result => {
                        if (result.value) {
                            if(which == 'emailForm'){
                            this.sendForm(value)
                            }
                            if(which == 'rejectVisa'){
                                this.rejectVisa(value)
                            }

                        }
                    });
                },

                rejectVisa(row){


                    document.getElementById('preloader').style.display = 'block';


                    axios({
                        url: Vue.prototype.websiteURL + 'admin/visa/reject/visa',
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                        data: row,
                    }).then((response) => {

                        if(response.data == 'success'){
                            document.getElementById('preloader').style.display = 'none';
                            this.showSwal('Rejection Email has been sent');
                            return
                        }
                        swal({
                            title: 'Something went wrong please inform IT guys!',
                            buttonsStyling: false,
                            confirmButtonClass: 'btn btn-error btn-fill'
                        })

                    }).catch((error)=>{

                        swal({
                            title: 'Something went wrong please inform IT guys!',
                            buttonsStyling: false,
                            confirmButtonClass: 'btn btn-error btn-fill'
                        })

                    });

                    return;

                },

                sendForm(row){


                    document.getElementById('preloader').style.display = 'block';


                    axios({
                        url: Vue.prototype.websiteURL + 'admin/visa/send/form',
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                        data: row,
                    }).then((response) => {

                        if(response.data == 'success'){
                        document.getElementById('preloader').style.display = 'none';
                        this.showSwal('Form data has been sent');
                        return
                        }
                        swal({
                            title: 'Something went wrong please inform IT guys!',
                            buttonsStyling: false,
                            confirmButtonClass: 'btn btn-error btn-fill'
                        })

                    }).catch((error)=>{

                        swal({
                            title: 'Something went wrong please inform IT guys!',
                            buttonsStyling: false,
                            confirmButtonClass: 'btn btn-error btn-fill'
                        })

                    });

                    return;

                },
                getFiles(file, type) {
                    let data = {
                        attachmentType: type,
                        file: file.token
                    }
                    axios({
                        url: Vue.prototype.websiteURL + 'admin/visa/get/' + 'attachments',
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                        data: data,
                        responseType: 'blob',
                    }).then((response) => {
                        let goh = response.data.type
                        goh = goh.split('/')
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', type + file.id + '.' + goh[goh.length - 1]);
                        document.body.appendChild(link);
                        link.click();
                    });

                    return;
                },
                sortBy(val) {
                    if (this.sortType == 'DESC') {
                        this.sortType = 'ASC'
                    } else {
                        this.sortType = 'DESC'
                    }
                    let data
                    if (this.searchQuery) {
                        data = {
                            'sort': val,
                            'type': this.sortType,
                            'key': this.searchQuery
                        }
                    } else {
                        data = {
                            'sort': val,
                            'type': this.sortType
                        }
                    }
                    this.sortyByglobal = val
                    this.loadingSpinner = true

                    let thepage = '?page=1'


                    axios({
                        url: Vue.prototype.websiteURL + 'admin/visa/sort'+thepage,
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                        data: data,
                    }).then((response) => {
                        this.tableData = response.data.data
                        this.activePage = 1
                        this.takeCareOfPagination(response.data)

                    });

                    return;

                },
                changePage() {
                    let params
                    if (this.searchQuery) {
                         params = {
                            'page': this.activePage,
                            'sort': this.sortyByglobal,
                            'type': this.sortType,
                            'key': this.searchQuery
                        }
                    } else {
                         params = {
                            'page': this.activePage,
                            'sort': this.sortyByglobal,
                            'type': this.sortType
                        }
                    }
                    this.getSubmissions(params)
                }
            },
            mounted() {

            },
            beforeMount() {
                this.getSubmissions();
            },
            watch: {
                searchQuery(value) {
                    if (value !== '') {
                        let params = {
                            'page': this.activePage,
                            'sort': this.sortyByglobal,
                            'type': this.sortType,
                            'key': value
                        }

                        this.getSubmissions(params);
                    }else{
                        this.getSubmissions();
                    }
                },
                activePage() {
                    this.changePage()
                }
            },
            computed: {
                paginationPages() {
                    return this.pagination
                },
                queriedData() {
                    return this.tableData;
                },
                to() {
                    return this.pagination.to;
                },
                from() {
                    return this.pagination.from;
                },
                total() {
                    return this.pagination.total;
                }
            },
        };
    </script>
<style>

    .visa-upload-modal .v--modal {
        height: 350px !important;
    }

    .pagination-select,
    .search-input {
        width: 200px;
    }
</style>


<style>
    #visaSubmissionAdmin #preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background: rgba(0,0,0,0.2);
        display: none;
    }
    #visaSubmissionAdmin .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: rgba(205,33,34,1);
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    #visaSubmissionAdmin .loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #2f4f4f;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }
    #visaSubmissionAdmin .loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #ffbe00;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    @-webkit-keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
