<template>
    <div class="container" id="hotelRequest">
        <div id="preloader">
            <div class="loader">
                <div class="loader"></div>
            </div>
        </div>
        <div class="htlrqs-form">
            <div class="form-row" style="margin-bottom:45px">
                <div class="col-md-5 mb-3">
                    <div class="logo-container">
                        <img :src="this.userLogo" align="left">
                    </div>
                </div>
                <div class="col-md-5 offset-md-2 mb-3">
                    <div class="form-row">
                        <div class="col-md-12 mb-3">
                            <label for="Password">Password<span style="color: red">*</span></label>
                            <input autocapitalize="off" type="password" v-model="Password" autocomplete="new-password" id="Password" name="Password" class="form-control" required>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label for="RequestNumber">Request Number: <span style="color: red">*</span></label>
                            <input v-if="!this.selectRequestNumber" type="text" v-model="RequestNumber" ref="RequestNumber" id="RequestNumber" autocomplete="off" name="RequestNumber" class="form-control" required :disabled=btnAndMail>
                            <model-select v-if="this.selectRequestNumber"
                                          :options="cahngeReqOptions"
                                          v-model="changedReqNo"
                                          id="reqNo"
                                          :isDisabled=btnAndMail
                                          placeholder="Select Request">
                            </model-select>

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <label>Request Type<span style="color: red">*</span></label>
            </div>
            <div class="form-row">
                <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="requestType" value="NewRequest" type="radio" ref="requestType" id="NewRequest" name="NewRequest" class="custom-control-input" :disabled=btnDis>
                    <label class="custom-control-label" for="NewRequest">New Request</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline" style="margin-left:100px">
                    <input v-model="requestType" value="Change" type="radio" id="Change" name="Change" class="custom-control-input" :disabled=btnDis>
                    <label class="custom-control-label" for="Change">Change</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline" style="margin-left:100px">
                    <input v-model="requestType" value="Cancellation" type="radio" id="Cancellation" name="Cancellation" class="custom-control-input" :disabled=btnDis>
                    <label class="custom-control-label" for="Cancellation">Cancellation</label>
                </div>
            </div>
            <div class="form-row" style="margin-top: 40px">
                <div class="col-md-5 mb-3">
                    <label>Hotel Name<span style="color: red">*</span></label>

                    <v-select :options="options"
                              v-model="hotelName"
                              id="hotelName"
                              :isDisabled=btnDis
                              placeholder="Select Hotel"
                              :disabled="btnDis"
                    ></v-select>
                </div>
                <div class="col-md-7 mb-3">
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <label for="Rooms">#Rooms: <span style="color: red">*</span></label>
                            <input type="number" v-model="Rooms" min="1" id="Rooms" ref="Rooms" name="Rooms" class="form-control" required :disabled=btnDis>
                        </div>
                        <div class="col-md-8 mb-3">
                            <label for="Rooms">Type: <span style="color: red">*</span></label>

                            <v-select :options="romeTypes"
                                      v-model="roomType"
                                      id="roomType"
                                      :isDisabled=btnDis
                                      placeholder="Select Type"
                                      :disabled="btnDis"
                            ></v-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-5 mb-3">
                    <label>Check-in Date<span style="color: red">*</span></label>
                    <VueCtkDateTimePicker
                        v-model="checkinDate"
                        formatted="ll"
                        input-size="lg"
                        :min-date=this.myMinDate
                        :only-date=true
                        :disabled=btnDis
                        :auto-close=true
                        label =""
                    />
                </div>






                <div class="col-md-7 mb-3">
                    <div class="form-row">


                        <div class="col-md-4 mb-3">
                            <label for="Nights">Nights<span style="color: red">*</span></label>
                            <input type="number" v-model="Nights" id="nights" min="1" ref="Nights" name="Nights" class="form-control" required :disabled=btnDis>
                        </div>

                        <div class="col-md-8 mb-3">
                            <label for="Rooms">Check-out Date:<span style="color: red">*</span></label>
                            <VueCtkDateTimePicker
                                v-model="checkOut"
                                formatted="ll"
                                input-size="lg"
                                :min-date=this.checkinDate
                                :only-date=true
                                :disabled=btnDis
                                :auto-close=true
                                label =""

                            />
                        </div>
                    </div>
                </div>







            </div>
            <div class="form-row" v-for="(customer, index) in Customers ">
                <div class="col-md-10 mb-3">
                    <label for="Customer" v-if="index <= 0">Customer<span style="color: red">*</span></label>
                    <label for="Customer" v-if="index > 0">Customer {{ index + 1 }}<span
                        style="color: red">*</span></label>
                    <input v-model="Customers[index].name" class="form-control" type="text" required :disabled=btnDis>
                </div>

                <div class="col-md-1 mb-3" style="padding-top: 30px" v-if="parseInt(Customers.length) > 1 && index == parseInt(Customers.length) - 1">
                    <span @click="RemovePassenger" class="pmbtn mines">-</span>
                </div>

                <div class="col-md-1 mb-3" style="padding-top: 30px"
                     v-if="index == parseInt(Customers.length) - 1">
                    <span @click="addPassenger" class="pmbtn plus">+</span>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12 mb-3">
                    <label for="ExtraNotes">Price:</label>
                    <input type="text" class="form-control" v-model="agreedPrice" id="agreedPrice" :disabled=btnDis>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12 mb-3" style="direction: rtl;text-align: right;">
                    هزینه،
                    <div class="form-check d-inline-block">
                        <label for="otagh" class="form-check-label">اتاق</label>
                        <input type="checkbox" id="otagh" value="otagh" class="form-check-input" v-model="hazine" :disabled=btnDis checked>
                    </div>

                    <div class="form-check d-inline-block">
                        <label for="sobane" class="form-check-label">صبحانه</label>
                        <input type="checkbox" id="sobane" value="sobane"  class="form-check-input"  v-model="hazine" :disabled=btnDis checked>
                    </div>
                    <div class="form-check d-inline-block">
                        <label for="nahar" class="form-check-label">نهار</label>
                        <input type="checkbox" id="nahar" class="form-check-input"  value="nahar" v-model="hazine" :disabled=btnDis>
                    </div>
                    <div class="form-check d-inline-block">
                        <label for="sham" class="form-check-label">شام</label>
                        <input type="checkbox" id="sham" class="form-check-input"  value="sham" v-model="hazine" :disabled=btnDis>
                    </div>
                    به عهده آژانس میباشد.
                    <br>


                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12 mb-3">
                    <label for="ExtraNotes">Extra Notes:</label>
                    <textarea class="form-control" v-model="ExtraNotes" id="ExtraNotes" :disabled=btnAndMail></textarea>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 offset-md-4 mb-3">
                    <label for="RequestByEmail">Email (BCC):</label>
                    <input class="form-control" v-model="RequestByEmail" type="email" id="RequestByEmail" required :disabled=btnAndMail>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12 mb-3" style="text-align:center">
                    <button v-on:click="submitMyForm" class="btn btn-secondary" :disabled=btnAndMail>SEND REQUEST</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Vue from 'vue';
    import axios from 'axios';
    import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
    import 'vue-select/dist/vue-select.css';
    import 'vue-search-select/dist/VueSearchSelect.css';
    import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
    import vSelect from 'vue-select';
    import { ModelSelect } from 'vue-search-select'

    import VueSweetalert2 from 'vue-sweetalert2';

    Vue.use(VueSweetalert2);


    Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

    export default {
        name: 'htlrqsform',
        data() {
            return {
                btnDis:true,
                options: [
                    { value: '1', text: '' },
                ],
                cahngeReqOptions: [],
                selectRequestNumber:false,
                yourValue:'',
                myMinDate:'',
                myTodayDate:'',
                RequestNumber:'',
                requestType:'NewRequest',
                hotelName: null,
                changedReqNo: {
                    value: '',
                    text: ''
                },
                Rooms:'',
                roomType:null,
                checkinDate:'',
                Nights:'',
                RequestByEmail:'',
                Password:'',
                ExtraNotes:'',
                Customers:[{'name':''}],
                agreedPrice:'As per agreement - IRR/Night',
                userLogo:'',
                hazine:['otagh', 'sobane'],
                checkOut:'',
                hasGotRqs:false,
                selectedRequestData:'',
                btnAndMail:true,
                romeTypes:[]
            }
        },
        mounted() {
            this.getHotels();
            this.getMinDate();
            this.getHotelTypes();
        },
        components: {
            vSelect,ModelSelect
        },
        watch:{
            Password: (function () {
                let data = {
                    pass:this.Password
                };
                axios.post(this.websiteURL+'admin/hotelrequest/get/myuser', data,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                    ).then(({ data }) => {
                    if(data.user === true){
                        this.btnDis = false;
                        this.btnAndMail = false;
                        this.userLogo = data.userLogo
                    }else{
                        this.btnDis = true;
                        this.userLogo = ''
                    }
                });
            }),
            requestType: function () {


                this.btnAndMail = this.btnDis



                if(this.requestType == 'Change' || this.requestType == 'Cancellation'){
                    this.selectRequestNumber = true;
                    if(this.hasGotRqs == false){
                        this.getRequestsNo();
                        this.hasGotRqs = true;
                    }

                }else{
                    this.selectRequestNumber = false;

                    this.hotelName = null;
                    this.Rooms = '';
                    this.roomType = null;
                    this.checkinDate = '';
                    this.checkOut = '';
                    this.Nights = '';
                    this.Customers = '';
                    this.hazine = ['otagh', 'sobane'];
                    this.ExtraNotes = '';
                    this.changedReqNo = {value: '', text: ''};
                    this.RequestByEmail = '';
                    this.agreedPrice = 'As per agreement - IRR/Night';
                    this.Customers= [{'name':''}]
                }


                if(this.requestType == 'Cancellation'){

                    this.btnAndMail = false;
                    this.btnDis = true;


                }



            },
            changedReqNo: function () {



                if(this.changedReqNo.value){

                    let selectedRequestNo ={
                        'id':this.changedReqNo.value
                    }
                    axios.post(this.websiteURL+'admin/hotelrequest/get/selected/request', selectedRequestNo,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                            },
                        }
                    ).then(({ data }) => {

                        this.selectedRequestData = data;



                        this.hotelName = this.selectedRequestData.hotel_name;
                        this.Rooms = this.selectedRequestData.rooms;
                        this.roomType = this.selectedRequestData.room_type;
                        this.checkinDate = this.selectedRequestData.check_in_date;
                        this.checkOut = this.selectedRequestData.check_out_date;
                        this.Nights = this.selectedRequestData.nights;
                        this.Customers = JSON.parse(this.selectedRequestData.customers);
                        this.hazine = JSON.parse(this.selectedRequestData.hazine);
                        this.ExtraNotes = this.selectedRequestData.extra_note;
                        this.RequestByEmail = this.selectedRequestData.bcc_email;
                        this.agreedPrice = this.selectedRequestData.price;


                    });



                }
            }
        },
        methods: {
            RemovePassenger(){
                if(this.btnDis == true){
                    return;
                }
                this.Customers.splice(-1,1)
            },
            addPassenger(){
                if(this.btnDis == true){
                    return;
                }
                this.Customers.push({'name':''})
            },
            getMinDate(){
                axios.post(this.websiteURL+'admin/hotelrequest/get/mindate','',
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({ data }) => {
                    this.myMinDate = data.mindate;
                    this.myTodayDate = data.today
                });
            },
            getHotels() {
                let hotels ={
                    'showmehotels':true
                }
                axios.post(this.websiteURL+'admin/hotelrequest/get/hotels', hotels,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                    },
                }
                ).then(({ data }) => {
                    this.options = data;
                });
            },
            getHotelTypes() {
                let hotels ={
                    'showmehotels':true
                };
                axios.post(this.websiteURL+'admin/hotelrequest/get/hotel-type', hotels,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({ data }) => {
                    this.romeTypes = data;
                });
            },
            getRequestsNo() {

                document.getElementById('preloader').style.display = 'block';

                let RequesNo ={
                    'showmeRequests':true
                }
                axios.post(this.websiteURL+'admin/hotelrequest/get/request_no', RequesNo,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },

                    }
                ).then(({ data }) => {
                    this.cahngeReqOptions = data;
                    document.getElementById('preloader').style.display = 'none';

                });
            },
            submitMyForm(){

                if(this.RequestNumber == ''){
                    if(this.requestType != 'Change' && this.requestType != 'Cancellation'){
                        this.notifyVue('top','right','warning','Request Number is required');

                        this.$refs.RequestNumber.focus()
                        return
                    }
                }

                if(this.requestType == 'Change' || this.requestType == 'Cancellation'){
                    if(this.changedReqNo.value == ''){
                        this.notifyVue('top','right','warning','Request Number is required');

                        this.$refs.RequestNumber.focus()
                        return
                    }
                }




                if(this.requestType == ''){
                    this.notifyVue('top','right','warning','Request Type is required');

                    this.$refs.requestType.focus()
                    return
                }
                if(this.hotelName == null){
                    this.notifyVue('top','right','warning','Hotel Name is required');


                    document.getElementById("hotelName").focus();
                    return
                }
                if(this.Rooms == ''){
                    this.notifyVue('top','right','warning','#Rooms is required');

                    this.$refs.Rooms.focus()
                    return
                }
                if(this.roomType == null){
                    this.notifyVue('top','right','warning','Room Type is required');

                    this.$refs.roomType.focus()
                    return
                }
                if(this.checkinDate == ''){
                    this.notifyVue('top','right','warning','Check in Date is required');

                    $("#DateTimePicker input").focus();
                    return
                }
                if(this.checkOut == ''){
                    this.notifyVue('top','right','warning','Check out Date is required');

                    return
                }
                if(this.Nights == ''){
                    this.notifyVue('top','right','warning','#Nights is required');

                    this.$refs.Nights.focus()
                    return
                }
                if(this.Customers[0].name == ''){
                    this.notifyVue('top','right','warning','Customer is required');

                    $("Customer1").focus();
                    return
                }

                document.getElementById('preloader').style.display = 'block';
                let rqstData = {
                    'RequestNumber' : this.RequestNumber,
                    'requestType' : this.requestType,
                    'hotelName' : this.hotelName,
                    'Rooms' : this.Rooms,
                    'checkinDate' : this.checkinDate,
                    'checkOut' : this.checkOut,
                    'Nights' : this.Nights,
                    'Customers' : this.Customers,
                    'Password' : this.Password,
                    'bcc_email' : this.RequestByEmail,
                    'ExtraNotes' : this.ExtraNotes,
                    'roomType' : this.roomType,
                    'agreedPrice' : this.agreedPrice,
                    'hazine' : this.hazine,
                    'changedReqNo' : this.changedReqNo.value
                };
                axios.post(this.websiteURL+'admin/hotelrequest/send/htlrqst', rqstData,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({ data }) => {
                    document.getElementById('preloader').style.display = 'none';

                    this.$swal({
                        title: 'Form Submitted <br> Successfully',
                        text: "Emails to the hotelier are being sent",
                        type: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok!',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            location.reload();
                        }
                    });

                }).catch(e => {
                    if(e.response.data.message.Nights){
                        this.notifyVue('top','right','warning','Nights field required');
                        document.getElementById('preloader').style.display = 'none';

                        return

                    }
                    if(e.response.data.message.Rooms){
                        this.notifyVue('top','right','warning','Rooms field required');
                        document.getElementById('preloader').style.display = 'none';

                        return

                    }
                    if(e.response.data.message.Password){
                        this.notifyVue('top','right','warning','Password field required');
                        document.getElementById('preloader').style.display = 'none';

                        return

                    }
                    if(e.response.data.message.RequestNumber){
                        this.notifyVue('top','right','warning','The Request Number field is required.');
                        document.getElementById('preloader').style.display = 'none';

                        return

                    }
                    if(e.response.data.message.checkinDate){
                        this.notifyVue('top','right','warning','The Check-in Date field is required.');
                        document.getElementById('preloader').style.display = 'none';

                        return

                    }
                    if(e.response.data.message.hotelName){
                        this.notifyVue('top','right','warning','The Hotel Name field is required.');
                        document.getElementById('preloader').style.display = 'none';

                        return

                    }
                    if(e.response.data.message.requestType){
                        this.notifyVue('top','right','warning','The Request Type field is required.');
                        document.getElementById('preloader').style.display = 'none';

                        return

                    }
                    if(e.response.data.message.roomType){
                        this.notifyVue('top','right','warning','The Room Type field is required.');
                        document.getElementById('preloader').style.display = 'none';

                        return

                    }
                    if(e.response.data.message === 'Please Check Nights and Check out date carefully!'){
                        this.notifyVue('top','right','warning','Please Check Nights and Check out date carefully!');
                        document.getElementById('preloader').style.display = 'none';

                        return
                    }

                    this.notifyVue('top','right','warning','Something went wrong please inform IT guys!', 100000);


                });


                return;
            },
            notifyVue(verticalAlign , horizontalAlign , status , message, timeout=30000) {
                this.$notify({
                    message: message,
                    timeout: timeout,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: horizontalAlign,
                    verticalAlign: verticalAlign,
                    type: status
                });
            },
        }
    }
</script>

<style>
    #hotelRequest #DateTimePicker .field-label{
        display: none!important;
    }
    #hotelRequest input#DateTimePicker{
        padding-top:0px!important;
        height: 38px!important;
        min-height: 32px!important;
        margin-top: 1px!important;
    }
    #hotelRequest .pmbtn{
        background: red;
        padding: 0px 12px 4px 10px;
        color: #fff;
        font-size: 25px;
        border-radius: 5px;
        cursor: pointer;
    }
    #hotelRequest .pmbtn.plus{
        background: green;
    }
    #hotelRequest .htlrqs-form{
        max-width: 850px;
        margin: 0 auto;
        background: #ffffff;
        box-shadow: 1px 1px 9px 2px #ccc;
        padding: 15px 40px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    #hotelRequest .logo-container img{
        max-width:150px;
    }
    #hotelRequest #roomType{
        direction: ltr;
    }
    #hotelRequest input,#hotelRequest select{
        min-height: 30px !important;
    }
    #hotelRequest .vs__selected-options input.vs__search{
        min-height: 30px !important;
    }
    #hotelRequest input[type='checkbox'] {
        min-height: 22px !important;
    }
    #hotelRequest .form-check {
        padding-left: 2.25rem;
    }
    #hotelRequest #preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background: rgba(0,0,0,0.2);
        display: none;
    }
    #hotelRequest .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: rgba(205,33,34,1);
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    #hotelRequest .loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #2f4f4f;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }
    #hotelRequest .loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #ffbe00;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    @-webkit-keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    #hotelRequest .vs__search::placeholder{
        font-weight: 200 !important;
        color: #757575 !important;

    }
    #hotelRequest .vs__dropdown-menu{
        width: 100% !important;
        margin: auto;
    }
    #hotelRequest #Rooms {
        width: 100%;
        border-radius: 5px;
        border: solid 1px silver;
    }
    #hotelRequest #Nights {
        width: 100%;
        padding: 19px !important;
        border-radius: 5px;
        border: solid 1px silver;
    }
    #hotelRequest .field.lg .field-input{
        height: 40px !important;
    }
    #hotelRequest .field.lg.has-value:not(.no-label) .field-input{
        padding-top: 0px !important;
    }
    #hotelRequest .form-check input[type="checkbox"], #hotelRequest .radio input[type="radio"] {
        opacity: 1;
        position: absolute;
        visibility: visible;
    }
    #hotelRequest input[type='checkbox'] {
        min-height: 14px !important;
    }
</style>
