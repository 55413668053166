<template>
  <div class="row">
    <div class="col-md-12">
      <el-table :data="queriedData">

        <!-- UniID -->
        <el-table-column min-width="150"
                         prop="uni_id"
                         label="Unique ID"
        >
        </el-table-column>
        <!-- /UniID -->

        <!-- Source -->
        <el-table-column min-width="200"
                         prop="utm_source"
                         label="Source"
        >
        </el-table-column>
        <!-- /Source -->

        <!-- Campaign -->
        <el-table-column min-width="200"
                         prop="utm_campaign"
                         label="Campaign"
        >
        </el-table-column>
        <!-- /Campaign -->

        <!-- Medium -->
        <el-table-column min-width="200"
                         prop="utm_medium"
                         label="Medium"
        >
        </el-table-column>
        <!-- /Medium -->

        <!-- Content -->
        <el-table-column min-width="200"
                         prop="utm_content"
                         label="Content"
        >
        </el-table-column>
        <!-- /Content -->

        <!-- Finish -->
        <el-table-column min-width="200"
                         label="Finish"
        >
          <span slot-scope="{ row }">
              <span style="color: green;" v-if="row.finish == 'BUY'">
                  Buy
              </span>
              <span style="color: orange;" v-if="row.finish == 'RESERVE'">
                  Reserve
              </span>
              <span v-if="!['BUY', 'RESERVE'].includes(row.finish)">
                  -
              </span>
          </span>
        </el-table-column>
        <!-- /Finish -->

        <!-- Time -->
        <el-table-column min-width="200"
                         prop="created_at"
                         label="Time"
        >
        </el-table-column>
        <!-- /Time -->

      </el-table>

      <!-- Pagination -->
      <base-pagination v-model="pagination.currentPage"
                       :per-page="pagination.perPage"
                       :total="total"
      >
      </base-pagination>
      <!-- /Pagination -->

    </div>
  </div>
</template>


<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import { BasePagination } from 'src/components'
  import axios from 'axios'
  import Vue from 'vue'
  import store from 'src/store'
  import moment from 'moment';

  export default {
      name: 'UTMTable',


      components: {
        BasePagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
      },


      data(){
          return{
              utmData: [],
              pageNum: 1,
              pagination: {
                perPage: 20,
                currentPage: 1,
                total: 0
              },
              searchQuery: '',
          }
      },


      mounted() {
          this.getData();
          // console.log('New Date: ', moment('2020-02-13 13:26:17').isAfter(moment('Mon Feb 10 2020 00:00:00 GMT+0330 (Iran Standard Time)')));
      },

      methods:{
          getData(){
              axios.post( Vue.prototype.websiteURL + "admin/flight/" + "utm")
                  .then(res => {
                      // Dispatch UTM Items
                      store.dispatch('setUTMItemsAction', res.data.utms);
                      store.dispatch('setOrgUTMItemsAction', res.data.utms);
                      // Dispatch UTM Filter Items
                      store.dispatch('setUTMFilterItemsAction', [res.data.sources, res.data.campaigns, res.data.mediums, res.data.contents])
                      // Dispatch UTM Sources Counter
                      store.dispatch('setUTMSourcesCounterAction', res.data.utmSourcesOverview);
                  })
                  .catch(e => {
                      //
                  })
          }
      },


      computed: {
          queriedData() {
              let result = store.getters.getUTMItems;
              if (store.getters.getUTMItems.length > 0) {
                  result = store.getters.getUTMItems;
              }
              return result.slice(this.from, this.to);
          },

          to() {
              let highBound = this.from + this.pagination.perPage;
              if (this.total < highBound) {
                  highBound = this.total;
              }
              return highBound;
          },
          from() {
              return this.pagination.perPage * (this.pagination.currentPage - 1);
          },
          total() {
              return store.getters.getUTMItems.length > 0
                    ? store.getters.getUTMItems.length
                    : store.getters.getUTMItems.length;
          }
      },
  }
</script>
