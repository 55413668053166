<template>
  <div class="row mytrip_agency_create">
    <div class="col-12" v-has-permission="`mytrip_agency_create`">
      <div class="card">
        <div class="card-body">
          <!-- AGENCY NAME AND OPERATOR -->
          <div class="row agencyInput p-t-30">
            <div class="col-6">
              <base-input
                type="text"
                placeholder="Agency Name"
                v-model="data.name"
              >
              </base-input>
            </div>
            <div class="col-6">
              <el-select
                class="select-primary"
                size="large"
                placeholder="Select operator"
                v-model="data.operator_id"
              >
                <el-option
                  v-for="option in operators.countries"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- END AGENCY NAME AND OPERATOR -->

          <!-- AGENCY EMAIL AND PHONE -->
          <div class="row agencyInput p-t-30">
            <div class="col-6">
              <base-input
                type="text"
                placeholder="Agency Email"
                v-model="data.email"
              >
              </base-input>
            </div>
            <div class="col-6">
              <base-input
                type="text"
                placeholder="Agency Phone"
                v-model="data.phone"
              >
              </base-input>
            </div>
          </div>
          <!-- END AGENCY EMAIL AND PHONE -->

          <!-- AGENCY WEBSITE URL -->
          <div class="row agencyInput p-t-30">
            <div class="col-12">
              <base-input
                type="text"
                placeholder="Agency URL"
                v-model="data.url"
              >
              </base-input>
            </div>
          </div>
          <!-- END AGENCY WEBSITE URL -->

          <!-- IMAGES FOR AGENCY -->
          <div class="row">
            <div class="col-6 text-center p-3">
              <image-upload @change="imageLogo" select-text="Email Logo" />
            </div>
            <div class="col-6 text-center p-3">
              <image-upload @change="websiteLogo" select-text="Website Logo" />
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-center p-3">
              <image-upload @change="imageBackground" select-text="Background Email Image" />
            </div>
            <div class="col-6 text-center p-3">
              <image-upload @change="websiteBackground" select-text="Background Website Image" />
            </div>
          </div>
          <!-- END IMAGES FOR AGENCY -->

          <!--AGENCY CREATE BUTTON-->
          <div class="row">
            <button class="btn btn-behance m-auto" @click="create">Create New Agency</button>
          </div>
          <!-- END AGENCY CREATE BUTTON-->

        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import axios from 'axios';
    import Vue from 'vue';
    import { Modal, BaseAlert } from 'src/components';
    import { Select, Option } from 'element-ui';
    import { ImageUpload } from 'src/components/index';
    export default {
        components: {
            Modal,
            BaseAlert,
            ImageUpload,
            [Option.name]: Option,
            [Select.name]: Select,
        },
        data() {
            return {
                data :{
                    'image-logo'         : null,
                    'website-logo'       : null,
                    'image-background'   : null,
                    'website-background' : null,
                    'phone'              : '',
                    'name'               : '',
                    'email'              : '',
                    'url'                : '',
                    'operator_id'        : '',
                },
                operators: {
                    simple: '',
                    countries: [
                        { value: 'please wait', label: 'please wait' },
                    ],
                },
            };
        },
        computed: {

        },
        methods: {
            /**
             * GET OPERATORS DATA
            * */
            getOperators(){
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/agencyData/operators/get-all-operators', null , User.prototype.getHeader())
                    .then((response)=>{
                        let data = [] ;
                        for (var i=0;i<response.data.data.length;i++){
                            data.push({
                                label: response.data.data[i].name,
                                value : response.data.data[i].id,
                            });
                        }

                        this.operators.countries = data;
                    })
            },
            /**
             * CALL getData METHOD
             * SET HEADERS
             * POST DATA FOR CREATE A NEW AGENCY
             * THEN RETURN TO AGENCY PAGE
            **/
            create(){
                let formData = this.getData();
                let headers  = {
                    headers : {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        'Content-Type' : "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
                    }
                };
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/agencyData/store/store-new-agency' , formData , headers)
                    .then(response => {
                        this.notifyVue('top','right','success','The operation you requested was successful');
                        this.clear();
                        this.$router.push({ name: "Agency"});
                    })
                    .catch(response =>{
                        this.notifyVue('top','right','warning','The operation you requested did not succeed');
                    });
            },
            /**
             * notifyVue
            * */
            notifyVue(verticalAlign , horizontalAlign , status , message) {
                this.$notify({
                    message: message,
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: horizontalAlign,
                    verticalAlign: verticalAlign,
                    type: status
                });
            },
            /**
             * APPEND DATA TO FormData METHOD
            * */
            getData(){
                let formData = new FormData();
                formData.append('image-logo'            , this.data["image-logo"]);
                formData.append('website-logo'          , this.data["website-logo"]);
                formData.append('image-background'      , this.data["image-background"]);
                formData.append('website-background'    , this.data["website-background"]);
                formData.append('name'                  , this.data.name);
                formData.append('email'                 , this.data.email);
                formData.append('url'                   , this.data.url);
                formData.append('operator_id'           , this.data.operator_id);
                formData.append('phone'                 , this.data.phone);
                return formData;
            },
            imageLogo(file){
                this.data["image-logo"] = file;
            },
            websiteLogo(file){
                this.data["website-logo"] = file;
            },
            imageBackground(file){
                this.data["image-background"] = file;
            },
            websiteBackground(file){
                this.data["website-background"] = file;
            },
            clear(){
                this.data = {
                    'image-logo'         : null,
                    'website-logo'       : null,
                    'image-background'   : null,
                    'website-background' : null,
                    'name'               : '',
                    'email'              : '',
                    'phone'              : '',
                    'url'                : '',
                    'operator_id'        : '',
                }
            }
        },
        mounted() {
            this.getOperators();
        }
    };
</script>

<style>
  .agencyInput{
    padding: 0 50px;
  }
  .agencyInput div.col-4{
    margin: 10px auto !important;
  }
  .agencyInput div.col-4 div{
    width: 100%;
  }
  .agencyInput div.col-8{
    margin: 10px auto !important;
  }
  .agencyInput div.col-8 div{
    width: 100%;
  }
  .agencyInput .el-select.select-primary.el-select--large{
    width: 100%;
  }
  .agencyInput .form-control{
    border-radius: 3px;
    margin-top: 1px;
  }
  .mytrip_agency_create input{
    font-size: 13px;
  }
  .p-t-30{
    padding-top: 30px;
  }
</style>
