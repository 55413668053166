<template>
    <modal :show.sync="modal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">
            <template>
                <div class="text-center text-muted mb-4">
                    <small>Please complete form</small>
                </div>
                <form role="form">
                    <base-input label="Code"
                                alternative
                                class="mb-3"
                                placeholder="Code"
                                addon-left-icon="ni ni-email-83"
                                v-model="discountCode.code">
                    </base-input>
                    <base-input label="Description">
                        <textarea class="form-control" id="desc" rows="3" v-model="discountCode.desc"></textarea>
                    </base-input>
                    <base-input label="Type">
                        <select class="form-control" id="exampleFormControlSelect1" v-model="discountCode.type">
                            <option value="1">%</option>
                            <option value="2">Exact</option>
                            <option value="3">Free</option>
                        </select>
                    </base-input>
                    <base-input label="Amount"
                                alternative
                                placeholder="Amount"
                                type="number"
                                addon-left-icon="ni ni-email-83"
                                v-model="discountCode.amount">
                    </base-input>
                    <base-input label="Usage Limit"
                                alternative
                                placeholder="Usage Limit"
                                type="number"
                                addon-left-icon="ni ni-email-83"
                                v-model="discountCode.usageLimit">
                    </base-input>
                    <base-input label="Expiration At">
                        <el-date-picker v-model="discountCode.expirationAt"
                                        type="date"
                                        placeholder="Expiration At">
                        </el-date-picker>
                    </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="submitDiscount()">Add Discount Code</base-button>
                    </div>
                </form>
            </template>
        </card>
    </modal>
</template>


<script>
    import {BaseButton, Modal} from 'src/components'
    import {DatePicker, TimeSelect} from 'element-ui'
    import axios from 'axios';
    import Vue from 'vue';

    export default {
        name: 'CreateDiscountModal',


        components: {
            BaseButton,
            Modal,
            [DatePicker.name]: DatePicker,
            [TimeSelect.name]: TimeSelect,
        },


        props:[
            'modalStatus'
        ],


        data(){
            return{
                discountCode: {
                    code: null,
                    desc: null,
                    type: null,
                    amount: null,
                    usageLimit: null,
                    expirationAt: null,
                },
            }
        },


        methods:{
            submitDiscount(){
                axios.post( Vue.prototype.websiteURL + "admin/flight/" + "discount/create", this.discountCode )
                    .then(res => {
                        this.$emit('closemodal');
                        this.$notify({type: 'success', message: 'Discount created successfully!'})
                    })
                    .catch(e => {
                        this.$notify({type: 'danger', message: 'The given data is invalid!'})
                    })
            },
        },


        computed: {
            modal:{
                get(){
                    return this.modalStatus
                },

                set(value){
                    this.$emit('closemodal')
                }
            }
        }

    }
</script>