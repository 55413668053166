<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <card class="card-lock card-white text-center">

        <h4 class="card-title">{{info.name}}</h4>
        <base-input
          type="password"
          placeholder="Password"
          addon-left-icon="tim-icons icon-key-25"
          v-model="user.password"
          @keypress.enter="unlock"
        >
        </base-input>
        <h5 v-show="error" style="color: red">Incorrect password</h5>

        <base-button slot="footer" type="primary" size="lg" round @click.prevent="unlock">
          Unlock
        </base-button>

      </card>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return{
            info : {
                created_at:"",
                email:"",
                email_verified_at:null,
                id:null,
                name:"",
                updated_at:""
            },
            user: new User({
                email : '',
                password :''
            }),
            error : false
        }
    },
    mounted() {
        this.values();
    },
    methods:{
        values (){
            let user        = JSON.parse(window.localStorage.getItem("Apochi_UserInformation"));
            this.info       = user.data;
            this.user.email = this.info.email;
        },
        async unlock(){
            if (this.user.password !== "") {
                this.user.login(true).then(()=>{
                    this.$router.push({ name: 'Dashboard'});
                })
                .catch(()=>{
                    this.error = true;
                    this.user.password = "";
                });
            }
        },
    }
};
</script>
<style></style>
