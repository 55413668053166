<template>
    <div class="row">
        <div class="col-md-12">
            <el-table :data="discountCodes">

                <!-- Index -->
                <el-table-column min-width="50"
                                 type="index"
                                 label="#"
                ></el-table-column>
                <!-- /Index -->

                <!-- Discount Code -->
                <el-table-column min-width="200"
                                 prop="code"
                                 label="Code"
                >
                </el-table-column>
                <!-- /Discount Code -->

                <!-- Discount Code Description -->
                <el-table-column min-width="400"
                                 prop="desc"
                                 label="Description"
                >
                </el-table-column>
                <!-- /Discount Code Description -->

                <!-- Type of Discount -->
                <el-table-column min-width="100"
                                 prop="type"
                                 label="Type"
                >
          <span slot-scope="{ row }">
            <span v-if="row.type==1">
              %
            </span>
            <span v-if="row.type==2">
              <i class="tim-icons icon-money-coins"></i>
            </span>
          </span>
                </el-table-column>
                <!-- /Type of Discount -->

                <!-- Amount -->
                <el-table-column min-width="100"
                                 prop="amount"
                                 label="Amount"
                >
                </el-table-column>
                <!-- /Amount -->

                <!-- Usage Limit -->
                <el-table-column min-width="150"
                                 prop="usageLimit"
                                 label="Usage Limit"
                >
                </el-table-column>
                <!-- /Usage Limit -->

                <!-- Used Number -->
                <el-table-column min-width="150"
                                 prop="usedNum"
                                 label="Used Num"
                >
                </el-table-column>
                <!-- /Used Number -->

                <!-- Expiration -->
                <el-table-column min-width="200"
                                 prop="expiration"
                                 label="Expiration"
                >
                </el-table-column>
                <!-- /Expiration -->

            </el-table>
        </div>
    </div>
</template>


<script>
    import {Table, TableColumn} from 'element-ui'
    import axios from 'axios'
    import Vue from 'vue'

    export default {
        name: 'DiscountsList',


        components: {
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },


        data(){
            return{
                discountCodes: [],
            }
        },


        mounted() {
            this.getData();
        },

        methods:{
            getData(){
                axios.get( Vue.prototype.websiteURL + "admin/flight/" + "discounts" )
                    .then(res => {
                        res.data.discounts.forEach(function (item) {
                            this.discountCodes.push({
                                'code'      : item.code,
                                'desc'      : item.description,
                                'type'      : item.type,
                                'usageLimit': item.max_use,
                                'usedNum'   : item.used_no,
                                'amount'    : item.amount,
                                'expiration': item.expire,
                            })
                        }.bind(this))
                    })
                    .catch(e => {
                        //
                    })
            },

            updateDiscount(){
                //
            },
        },
    }
</script>
