var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('el-table',{attrs:{"data":_vm.queriedData}},[_c('el-table-column',{attrs:{"min-width":"150","prop":"uniID","label":"Unique ID"}}),_c('el-table-column',{attrs:{"min-width":"300","prop":"from","label":"From"}}),_c('el-table-column',{attrs:{"min-width":"300","prop":"to","label":"To"}}),_c('el-table-column',{attrs:{"min-width":"200","prop":"way","label":"Way"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{},[(row.way == 'dep' || row.way == 'departure')?_c('span',[_vm._v(" Departure ")]):_vm._e(),(row.way == 'arr' || row.way == 'arrival')?_c('span',[_vm._v(" Arrival ")]):_vm._e()])}}])}),_c('el-table-column',{attrs:{"min-width":"300","prop":"departureDate","label":"Departure Date"}}),_c('el-table-column',{attrs:{"min-width":"300","prop":"arrivalDate","label":"Arrival Date"}}),_c('el-table-column',{attrs:{"min-width":"200","prop":"flightNumber","label":"Flight Number"}}),_c('el-table-column',{attrs:{"min-width":"250","prop":"reserveStatus","label":"Reserve Status"}}),_c('el-table-column',{attrs:{"min-width":"350","prop":"email","label":"Email"}}),_c('el-table-column',{attrs:{"min-width":"150","prop":"rialAmount","label":"Rial Amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{domProps:{"textContent":_vm._s(row.rialAmount + ' Rial')}})}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"paidAmount","label":"Paid Amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{domProps:{"textContent":_vm._s(Math.max(row.totalEuro, row.realEuro) + ' €')}})}}])}),_c('el-table-column',{attrs:{"min-width":"300","prop":"operatingAirline","label":"Operation Airline"}}),_c('el-table-column',{attrs:{"min-width":"170","prop":"paymentStatus","label":"Payment Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{},[(row.paymentStatus=='SUCCESS')?_c('span',[_c('i',{staticClass:"tim-icons icon-check-2 icon--green"})]):_c('span',[_c('i',{staticClass:"tim-icons icon-simple-remove icon--red"})])])}}])}),_c('el-table-column',{attrs:{"min-width":"170","prop":"travelersInfo","label":"Travelers Info."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('a',{attrs:{"href":row.travelersInfo,"target":"_blank"}},[_vm._v("Show")])])}}])}),_c('el-table-column',{attrs:{"min-width":"220","prop":"company","label":"Company"}}),_c('el-table-column',{attrs:{"min-width":"200","prop":"api","label":"API"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{},[(row.api == 'ch724')?_c('span',[_vm._v(" Charter724 ")]):_vm._e(),(row.api != 'ch724')?_c('span',[_vm._v(" Alibaba ")]):_vm._e()])}}])}),_c('el-table-column',{attrs:{"min-width":"170","prop":"time","label":"Time"}})],1),_c('base-pagination',{attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }