<template>
    <div class="row">
        <div class="col-md-12">

            <el-table :data="queriedData">
                <!-- Unique ID -->
                <el-table-column min-width="150"
                                 prop="uniID"
                                 label="Unique ID"
                >
                </el-table-column>
                <!-- /Unique ID -->

                <!-- Email -->
                <el-table-column min-width="350"
                                 prop="email"
                                 label="Email"
                >
                </el-table-column>
                <!-- /Email -->

                <!-- Rial Amount -->
                <el-table-column min-width="150"
                                 prop="rialAmount"
                                 label="Rial Amount"
                >
                    <span slot-scope="{ row }" v-text="row.rialAmount + ' Rial'">

                    </span>
                </el-table-column>
                <!-- /Rial Amount -->

                <!-- Euro Amount -->
                <el-table-column min-width="150"
                                 prop="euroAmount"
                                 label="Euro Amount"
                >
                    <!--<span slot-scope="{ row }" v-text="Math.max(row.totalEuro, row.realEuro) + ' €'">-->

                    <!--</span>-->
                </el-table-column>
                <!-- /Euro Amount -->

                <!-- Travelers Information Page Link -->
                <el-table-column min-width="150"
                                 prop="travelersInfo"
                                 label="Travelers Info."
                >
                    <span slot-scope="{ row }">
                        <a :href="row.travelersInfo" target="_blank">Show</a>
                    </span>
                </el-table-column>
                <!-- /Travelers Information Page Link -->

                <!-- Company -->
                <el-table-column min-width="220"
                                 prop="company"
                                 label="Company"
                >
                </el-table-column>
                <!-- /Company -->

                <!-- Flight API -->
                <el-table-column min-width="200"
                                 prop="api"
                                 label="API"
                >
                    <span slot-scope="{ row }">
                        <span v-if="row.externalBookId">
                            Alibaba
                        </span>
                        <span v-if="!row.externalBookId">
                            Charter724
                        </span>
                    </span>
                </el-table-column>
                <!-- /Flight API -->

                <!-- Creation Time -->
                <el-table-column min-width="170"
                                 prop="time"
                                 label="Time"
                >
                </el-table-column>
                <!-- /Creation Time -->
            </el-table>

            <!-- Pagination -->
            <base-pagination v-model="pagination.currentPage"
                             :per-page="pagination.perPage"
                             :total="total"
            >
            </base-pagination>
            <!-- /Pagination -->

        </div>
    </div>
</template>


<script>
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import { BasePagination } from 'src/components'
    import axios from 'axios'
    import Vue from 'vue'

    export default {
        name: 'BookedFlightTable',


        components: {
            BasePagination,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
        },


        data() {
            return {
                bookedFlightsData: [],
                companies: [],
                pageNum: 1,
                pagination: {
                    perPage: 20,
                    currentPage: 1,
                    total: 0
                },
                searchQuery: '',
            }
        },


        mounted() {
            this.getData();

            this.$root.$on('filter-results', function (value) {
                this.bookedFlightsData = [];
                value.flights.forEach(function (item) {
                    this.bookedFlightsData.push({
                        'uniID'          : item.uniId,
                        'paymentStatus'  : item.payment_status,
                        'bookingStatus'  : item.booking_status,
                        'email'          : item.email,
                        'rialAmount'     : item.alibaba_amount,
                        'totalEuro'      : item.total_euro_amount,
                        'realEuro'       : item.real_euro_amount,
                        'euroAmount'     : item.euroAmount,
                        'travelersInfo'  : '/flight/travelers-info/' + item.uniId,
                        'company'        : item.company,
                        'externalBookId' : item.ExternalBookIds,
                        'api'            : '',
                        'time'           : item.created_at,
                    })
                }.bind(this))
            }.bind(this));
        },

        methods: {
            getData() {
                axios.get(Vue.prototype.websiteURL + "admin/flight/" + "reports?mode=book")
                    .then(res => {
                        res.data.bookingFlights.forEach(function (item) {
                            this.bookedFlightsData.push({
                                'uniID'          : item.uniId,
                                'paymentStatus'  : item.payment_status,
                                'bookingStatus'  : item.booking_status,
                                'email'          : item.email,
                                'rialAmount'     : item.alibaba_amount,
                                'totalEuro'      : item.total_euro_amount,
                                'realEuro'       : item.real_euro_amount,
                                'euroAmount'     : item.euroAmount,
                                'travelersInfo'  : '/flight/travelers-info/' + item.uniId,
                                'company'        : item.company,
                                'externalBookId' : item.ExternalBookIds,
                                'api'            : '',
                                'time'           : item.created_at,
                            })
                        }.bind(this))

                        res.data.companies.forEach(function (item) {
                            this.companies.push({
                                'id'  : item.id,
                                'name': item.name,
                            })
                        }.bind(this))

                        this.$root.$emit('get-companies', this.companies);
                    })
                    .catch(e => {
                        //
                    })
            },
        },



        computed: {
            queriedData() {
                let result = this.bookedFlightsData;
                if (this.bookedFlightsData.length > 0) {
                    result = this.bookedFlightsData;
                }
                return result.slice(this.from, this.to);
            },
            to() {
                let highBound = this.from + this.pagination.perPage;
                if (this.total < highBound) {
                    highBound = this.total;
                }
                return highBound;
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1);
            },
            total() {
                return this.bookedFlightsData.length > 0
                    ? this.bookedFlightsData.length
                    : this.bookedFlightsData.length;
            }
        },

    }
</script>
