<template>
    <div class="row mytrip_things_update">
        <div class="col-12" v-has-permission="`Hotel`">
            <div class="card">
                <div class="card-body">
                    <div class="row thingInputs p-t-50">
                        <div class="col-6">
                            <base-input
                                type="text"
                                placeholder="Enter Name"
                                v-model="data.name"
                            >
                            </base-input>
                        </div>
                        <div class="col-6">
                            <base-input
                                type="text"
                                placeholder="Enter Second Name"
                                v-model="data.second_name"
                            >
                            </base-input>
                        </div>
                        <div class="col-6">
                            <base-input
                                type="text"
                                placeholder="Enter Website Link"
                                v-model="data.link"
                            >
                            </base-input>
                        </div>
                        <div class="col-6">
                            <base-input
                                type="text"
                                placeholder="Enter Token - uniq ID"
                                v-model="data.token"
                            >
                            </base-input>
                        </div>
                        <div class="col-12">
                            <base-input
                                type="text"
                                placeholder="Enter Email"
                                v-model="data.email"
                            >
                            </base-input>
                        </div>
                        <div class="col-4">
                            <el-select
                                class="select-primary"
                                size="large"
                                placeholder="Select City"
                                v-model="data.city_code"
                            >
                                <el-option
                                    v-for="option in cities"
                                    class="select-primary"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-4">
                            <el-select
                                class="select-primary"
                                size="large"
                                placeholder="Select Category"
                                multiple
                                collapse-tags
                                v-model="data.categories"
                            >
                                <el-option
                                    v-for="option in categories"
                                    class="select-primary"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-4">
                            <el-select
                                class="select-primary"
                                size="large"
                                placeholder="Select star"
                                v-model="data.star"
                            >
                                <el-option
                                    v-for="option in stars"
                                    class="select-primary"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.label"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-12 pt-5">
                            <base-input label="Hotel Description">
                                <textarea
                                    class="form-control"
                                    v-model="data.description"
                                    rows="3"
                                ></textarea>
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <button class="btn btn-behance m-auto" @click="update">
                            update Hotel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import { Modal, BaseAlert } from "src/components";
import { Select, Option } from "element-ui";
import { ImageUpload } from "src/components/index";
export default {
    components: {
        Modal,
        BaseAlert,
        [Option.name]: Option,
        [Select.name]: Select,
        ImageUpload
    },
    data() {
        return {
            hotel: {},
            cities: [{ value: "", label: "" }],
            stars: [
                { value: "0", label: "0" },
                { value: "1", label: "1" },
                { value: "2", label: "2" },
                { value: "3", label: "3" },
                { value: "4", label: "4" },
                { value: "5", label: "5" }
            ],
            categories: [{ value: "", label: "" }],
            data: {
                name: "",
                star: "",
                link: "",
                token: "",
                city_code: "",
                email: "",
                second_name: "",
                description: "",
                categories: []
            }
        };
    },
    computed: {},
    mounted() {
        this.getCities();
        this.getCategories();
        this.replaceData();
        this.getHotel();
    },
    methods: {
        update() {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            axios
                .post(
                    Vue.prototype.websiteURL +
                        "admin/hotel/hotels/" +
                        this.hotel.id,
                    this.data,
                    headers
                )
                .then(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                    this.$router.push({ name: "Hotel Dashboard" });
                })
                .catch(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "warning",
                        "The operation you requested did not succeed"
                    );
                });
        },
        notifyVue(verticalAlign, horizontalAlign, status, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: status
            });
        },
        onImageChange(file) {
            this.data.image = file;
        },
        getCities() {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            axios
                .get(Vue.prototype.websiteURL + "admin/city", null, headers)
                .then(response => {
                    let cities = [];
                    response.data.data.forEach(element => {
                        cities.push({
                            // element name
                            label: element.name,
                            // element code
                            value: element.code
                        });
                    });
                    this.cities = cities;
                })
                .catch(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "warning",
                        "The operation you requested did not succeed"
                    );
                });
        },
        getCategories() {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            axios
                .get(Vue.prototype.websiteURL + "admin/category", null, headers)
                .then(response => {
                    let categories = [];
                    response.data.data.forEach(element => {
                        categories.push({
                            // element name
                            label: element.name,
                            // element id
                            value: element.id
                        });
                    });
                    this.categories = categories;
                })
                .catch(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "warning",
                        "The operation you requested did not succeed"
                    );
                });
        },
        replaceData() {
            this.hotel = this.$route.params.data;
            this.data["city_code"] = this.hotel.city_code;
            this.data["name"] = this.hotel.name;
            this.data["second_name"] = this.hotel.second_name;
            this.data["star"] = this.hotel.star;
            this.data["link"] = this.hotel.link;
            this.data["token"] = this.hotel.token;
            this.data["email"] = this.hotel.email;
            this.data["description"] = this.hotel.description;
        },
        getHotel() {
            let token = {
                data: this.hotel.token
            };
            axios
                .post(Vue.prototype.websiteURL + "hotel/get/token", token)
                .then(response => {
                    response.data.data.categories.forEach(element => {
                        this.data.categories.push(element.id);
                    });
                })
                .catch(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "warning",
                        "The operation you requested did not succeed"
                    );
                });
        }
    }
};
</script>

<style>
.thingInputs {
    padding: 0 50px;
}
.thingInputs div.col-4 {
    margin: 10px auto !important;
}
.thingInputs div.col-4 div {
    width: 100%;
}
.thingInputs .form-control {
    border-radius: 3px;
    margin-top: 1px;
}
.mytrip_things_update input {
    font-size: 13px;
}
.p-t-50 {
    padding-top: 50px;
}
</style>
