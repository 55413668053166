<template>
    <div id="review" v-has-permission="`review`">
        <div id="preloader">
            <div class="loader">
                <div class="loader"></div>
            </div>
        </div>
        <div class="col-12">
            <card card-body-classes="table-full-width">
                <div>
                    <div class="custom-file-input-wrapper">
                        <input
                            type="file"
                            @change="onChange"
                            class="custom-file-input"
                            accept=".csv, .ods"
                        />
                    </div>
                    <xlsx-read :file="file">
                        <xlsx-sheets>
                            <template #default="{sheets}">
                                <el-select
                                    class="select-info"
                                    placeholder="Single Select"
                                    v-model="selectedSheet"
                                >
                                    <el-option
                                        v-for="sheet in sheets"
                                        class="select-info"
                                        :value="sheet"
                                        :label="sheet"
                                        :key="sheet"
                                    >
                                    </el-option>
                                </el-select>
                            </template>
                        </xlsx-sheets>
                        <xlsx-json :sheet="selectedSheet" @parsed="addSheet" />
                    </xlsx-read>
                </div>
                <div class="pt-5">
                    Origin List:
                    <div>
                        <badge
                            type="primary"
                            class="ma-8"
                            v-for="origin in originList"
                            :key="origin"
                            >{{ origin }}</badge
                        >
                    </div>
                </div>
                <div class="pt-5" v-if="sheets.data.length !== 0">
                    <fileListTable :arrayData="sheets" />
                </div>
                <div v-if="sheets.data.length !== 0">
                    <base-button
                        block
                        type="info"
                        @click.native="handleSubmit()"
                        >Submit Reviews</base-button
                    >
                </div>
            </card>
        </div>
    </div>
</template>

<script>
import {
    XlsxRead,
    XlsxSheets,
    XlsxJson,
    XlsxSheet
} from "vue-xlsx/dist/vue-xlsx.es.js";
import { Select, Option } from "element-ui";
import fileListTable from "src/components/Review/ImportFileTable.vue";
import { BaseButton } from "src/components";
import swal from "sweetalert2";
import { Badge } from "src/components";
import axios from "axios";
import Vue from "vue";

export default {
    components: {
        XlsxRead,
        XlsxSheets,
        XlsxJson,
        XlsxSheet,
        [Select.name]: Select,
        [Option.name]: Option,
        fileListTable,
        BaseButton,
        Badge
    },
    data() {
        return {
            file: null,
            selectedSheet: null,
            sheetName: null,
            sheets: {
                data: [],
                name: ""
            },
            finalData: [],
            originList: [
                "Apochi BV",
                "Arian Tour",
                "Google HPA",
                "HostelWorld",
                "Object",
                "PERSAGAL",
                "Phobs generic",
                "Trivago",
                "Voice"
            ]
        };
    },
    methods: {
        onChange(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        addSheet(collection) {
            this.sheets = {
                name: this.selectedSheet,
                data: [...collection]
            };
            this.sheetName = null;
            this.convertData();
        },
        /**
         * handleSubmit for remove things by call deleteRow
         **/
        handleSubmit() {
            swal({
                title: "Are you sure?",
                text: `You won't be able to revert this!`,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, submit!",
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    let headers = {
                        headers: {
                            Accept: "application/json",
                            Authorization:
                                "Bearer " +
                                window.localStorage.getItem(
                                    "Apochi_accessToken"
                                )
                        }
                    };
                    document.getElementById("preloader").style.display =
                        "block";
                    axios
                        .post(
                            Vue.prototype.websiteURL + "admin/review",
                            this.finalData,
                            headers
                        )
                        .then(response => {
                            document.getElementById("preloader").style.display =
                                "none";
                            this.notifyVue(
                                "top",
                                "right",
                                "success",
                                "The operation you requested was successful"
                            );
                            this.$router.push({ name: "Review Form" });
                        })
                        .catch(response => {
                            document.getElementById("preloader").style.display =
                                "none";
                            this.notifyVue(
                                "top",
                                "right",
                                "warning",
                                "The operation you requested did not succeed"
                            );
                        });
                }
            });
        },
        convertData() {
            this.sheets.data.forEach(element => {
                if (this.checkData(element)) {
                    this.finalData.push({
                        origin: element["Origin"],
                        object: element["Object"],
                        arrival: element["Arrival"],
                        departure: element["Departure"],
                        nights: element["No. nights"],
                        email: element["E-mail"],
                        city: element["City"] ? element["City"] : null,
                        phone: element["Phone"],
                        country: element["Country"],
                        accommodation: element["Accommodation"],
                        adults: element["Adults"],
                        children: element["Children"],
                        reservation_holder: element["Reservation holder"]
                    });
                }
            });
        },
        checkData(element) {
            var Origin = element["Origin"] != undefined ? true : false;
            var Objects = element["Object"] != undefined ? true : false;
            var Arrival = element["Arrival"] != undefined ? true : false;
            var Departure = element["Departure"] != undefined ? true : false;
            var nights = element["No. nights"] != undefined ? true : false;
            var Email = element["E-mail"] != undefined ? true : false;
            var Phone = element["Phone"] != undefined ? true : false;
            var Country = element["Country"] != undefined ? true : false;
            var Accommodation =
                element["Accommodation"] != undefined ? true : false;
            var Adults = element["Adults"] != undefined ? true : false;
            var Children = element["Children"] != undefined ? true : false;
            var Reservation =
                element["Reservation holder"] != undefined ? true : false;

            if (
                Origin &&
                Objects &&
                Arrival &&
                Departure &&
                nights &&
                Email &&
                Phone &&
                Country &&
                Accommodation &&
                Adults &&
                Children &&
                Reservation &&
                element["Status"] == "ok" &&
                this.originList.includes(element["Origin"])
            ) {
                return true;
            }
            return false;
        },
        notifyVue(verticalAlign, horizontalAlign, status, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: status
            });
        }
    }
};
</script>

<style scoped>
.custom-file-input {
    color: transparent;
    opacity: 1;
    height: auto;
}
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
.custom-file-input::before {
    content: "Select file";
    color: #ffffff;
    background-color: #0772db;
    border-color: #066ccf;
    display: inline-block;
    border: 1px solid #066ccf;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    padding: 10px 18px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
}
.custom-file-input:hover::before {
    border-color: black;
}
.custom-file-input:active {
    outline: 0;
}
.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.custom-file-input-wrapper {
    width: auto;
    float: right;
    height: 100px;
}
.ma-8 {
    margin: 8px;
}
#review #preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.2);
    display: none;
}

#review .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: rgba(205, 33, 34, 1);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

#review .loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #2f4f4f;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

#review .loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ffbe00;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
