<template>
  <div class="row mytrip_things_create" v-has-permission="`mytrip_operator_create`">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row thingInputs p-t-30">
            <div class="col-4">
              <base-input
                type="text"
                placeholder="Operator Name"
                v-model="data.name"
              >
              </base-input>
            </div>
            <div class="col-4">
              <base-input
                type="text"
                placeholder="Operator Information"
                v-model="data.information"
              >
              </base-input>
            </div>
            <div class="col-4">
              <base-input
                type="text"
                placeholder="operator Email"
                v-model="data.email"
              >
              </base-input>
            </div>
          </div>
          <div class="row thingInputs p-t-30">
            <div class="col-4">
              <base-input
                type="text"
                placeholder="Phone"
                v-model="data.phone1"
              >
              </base-input>
            </div>
            <div class="col-4">
              <base-input
                type="text"
                placeholder="Phone"
                v-model="data.phone2"
              >
              </base-input>
            </div>
            <div class="col-4">
              <base-input
                type="text"
                placeholder="Url"
                v-model="data.url"
              >
              </base-input>
            </div>
          </div>
          <div class="row thingInputs p-t-30">
            <div class="col-4">
              <base-input
                type="text"
                placeholder="facebook"
                v-model="data.facebook"
              >
              </base-input>
            </div>
            <div class="col-4">
              <base-input
                type="text"
                placeholder="twitter"
                v-model="data.twitter"
              >
              </base-input>
            </div>
            <div class="col-4">
              <base-input
                type="text"
                placeholder="linkedin"
                v-model="data.linkedin"
              >
              </base-input>
            </div>
          </div>
          <div class="row thingInputs p-t-30">
            <div class="col-8">
              <base-input
                type="text"
                placeholder="Address"
                v-model="data.address"
              >
              </base-input>
            </div>
            <div class="col-4">
              <base-input
                type="text"
                placeholder="instagram"
                v-model="data.instagram"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center p-3">
              <image-upload @change="onImageChange" select-text="Select Image" />
            </div>
          </div>
          <div class="row">
            <button class="btn btn-behance m-auto" @click="create">Create New Operator</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import axios from 'axios';
    import Vue from 'vue';
    import { Modal, BaseAlert } from 'src/components';
    import { ImageUpload } from 'src/components/index';
    export default {
        components: {
            Modal,
            BaseAlert,
            ImageUpload,
        },
        data() {
            return {
                data :{
                    'image'        : '',
                    'name'         : '',
                    'email'        : '',
                    'url'          : '',
                    'phone1'       : '',
                    'phone2'       : '',
                    'address'      : '',
                    'information'  : '',
                    'facebook'     : '',
                    'twitter'      : '',
                    'linkedin'     : '',
                    'instagram'    : '',
                }
            };
        },
        computed: {

        },
        methods: {
            create(){
                let formData = this.getData();
                let headers  = {
                    headers : {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        'Content-Type' : "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
                    }
                };
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/operatorData/store/store-new-operator' , formData , headers)
                    .then(response => {
                        this.notifyVue('top','right','success','The operation you requested was successful');
                        this.clear();
                        this.$router.push({ name: "Operator"});
                    })
                    .catch(response =>{
                        this.notifyVue('top','right','warning','The operation you requested did not succeed');
                    });
            },
            notifyVue(verticalAlign , horizontalAlign , status , message) {
                this.$notify({
                    message: message,
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: horizontalAlign,
                    verticalAlign: verticalAlign,
                    type: status
                });
            },
            getData(){
                let formData = new FormData();
                formData.append('image'       , this.data.image);
                formData.append('name'        , this.data.name);
                formData.append('email'       , this.data.email);
                formData.append('url'         , this.data.url);
                formData.append('phone1'      , this.data.phone1);
                formData.append('phone2'      , this.data.phone2);
                formData.append('address'     , this.data.address);
                formData.append('information' , this.data.information);
                formData.append('facebook'    , this.data.facebook);
                formData.append('linkedin'    , this.data.linkedin);
                formData.append('twitter'     , this.data.twitter);
                formData.append('instagram'   , this.data.instagram);
                return formData;
            },
            onImageChange(file) {
                this.data.image = file;
            },
            clear(){
                this.data = {
                        'image'        : '',
                        'name'         : '',
                        'email'        : '',
                        'url'          : '',
                        'phone1'       : '',
                        'phone2'       : '',
                        'address'      : '',
                        'information'  : '',
                        'facebook'     : '',
                        'twitter'      : '',
                        'linkedin'     : '',
                        'instagram'    : '',
                }
            }
        },
        mounted() {

        }
    };
</script>

<style>
  .thingInputs{
    padding: 0 50px;
  }
  .thingInputs div.col-4{
    margin: 10px auto !important;
  }
  .thingInputs div.col-4 div{
    width: 100%;
  }
  .thingInputs div.col-8{
    margin: 10px auto !important;
  }
  .thingInputs div.col-8 div{
    width: 100%;
  }
  .thingInputs .form-control{
    border-radius: 3px;
    margin-top: 1px;
  }
  .mytrip_things_create input{
    font-size: 13px;
  }
  .p-t-30{
    padding-top: 30px;
  }
</style>
