<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="login">
        <card class="card-login card-white">
          <template slot="header">
            <img src="/admin-bundle/img/card-primary.png" alt="" />
            <h1 class="card-title">Log in</h1>
          </template>

          <div>
            <base-input
              v-validate="'required|email'"
              name="email"
              :error="getError('email')"
              v-model="user.email"
              placeholder="Email"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>

            <base-input
              v-validate="'required|min:5'"
              name="password"
              :error="getError('password')"
              v-model="user.password"
              type="password"
              placeholder="Password"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>
          </div>

          <base-checkbox class="mb-2 ml-2" v-model="rememberMe">
            Remember me
          </base-checkbox>
          <div slot="footer">
            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
              Login
            </base-button>
          </div>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rememberMe : false,
      model: {
        email: '',
        password: '',
        subscribe: true
      },
      user: new User({
          email : '',
          password :''
      })
    };
  },
  mounted(){
    if (window.localStorage.getItem("Apochi_rememberUser")){
        let user = JSON.parse(window.localStorage.getItem("Apochi_rememberUser"));
        this.user.email    = user.email;
        this.user.password = user.password;
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async login() {
      let isValidForm = await this.$validator.validateAll();
        if (this.rememberMe){
            let user = {
              'email'     : this.user.email,
              'password'  : this.user.password,
            };
            window.localStorage.setItem("Apochi_rememberUser",JSON.stringify(user));
        }else{
            window.localStorage.removeItem("Apochi_rememberUser");
        }
      if (isValidForm) {
        this.user.login().then(()=>{
            this.$router.push({ name: 'Dashboard'});
        });
      }
    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
