<template>
    <div>
        <h1>Traveler Info.</h1>
        <travelers-info-single></travelers-info-single>
    </div>
</template>


<script>
    import TravelersInfoSingle from 'src/components/Flight/Traverlers_Info/TravelersInfoSingle';

    export default {
        name: 'TravelersInfoIndex',


        components: {TravelersInfoSingle},
    }
</script>