<template>
    <div class="content" v-has-permission = "`Form`">
      <!-- EMAIL LIST  -->
      <div class="row">
        <div class="col-12">
          <card card-body-classes="table-full-width">

            <!-- TABLE -->
            <div>
              <el-table :data="arrayData">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>

                <!-- READ BUTTON -->
                <el-table-column :min-width="135" align="right" label="Actions">
                  <div slot-scope="props">
                    <base-button
                      @click.native="handleRead(props.$index, props.row)"
                      class="add btn-link"
                      style="color: #ec250d;font-size: 2em"
                      type="danger"
                      icon
                      v-has-permission = "`Form`"
                    >
                      <i class="tim-icons icon-email-85"></i>
                    </base-button>

                  </div>
                </el-table-column>
                <!-- END READ BUTTON -->

              </el-table>
            </div>
            <!-- END TABLE -->

            <!-- PAGINATION -->
            <div class="pagination_number">
              <pagination :data="tableData" @pagination-change-page="getResults" :limit="5"></pagination>
            </div>
            <!-- END PAGINATION -->

          </card>
        </div>
      </div>
      <!-- END LIST  -->

      <!-- options MODAL -->
      <modal name="options" v-has-permission = "`Form`" :dynamic="true" :resizable="true" :scrollable="true" :width="900" :height="800">
        <div class="row" style="height: 100%">
          <div class="col-12">
            <div class="card" style="height: 100%;border-radius: 0px">
              <h4 class="card-header title">options:</h4>
              <div class="card-body">
                <div class="modal-list-item">
                <h5 class="card-header title">Name:</h5>
                <p class="card-body m-auto" v-html="selected.options.name"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">City:</h5>
                <p class="card-body m-auto" v-html="selected.options.city"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Number Rooms:</h5>
                <p class="card-body m-auto" v-html="selected.options.number_rooms"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Star:</h5>
                <p class="card-body m-auto" v-html="selected.options.star"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Type Accommodation:</h5>
                <p class="card-body m-auto" v-html="selected.options.type_accommodation"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Contact Person:</h5>
                <p class="card-body m-auto" v-html="selected.options.contact_person"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Accommodation Address:</h5>
                <p class="card-body m-auto" v-html="selected.options.accommodation_address"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Email:</h5>
                <p class="card-body m-auto" v-html="selected.options.email"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">owner name:</h5>
                <p class="card-body m-auto" v-html="selected.options.contact_person"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Phone Number:</h5>
                <p class="card-body m-auto" v-html="selected.options.phone_number"></p>
                </div>
                <div class="modal-list-block">
                <h5 class="card-header title">Description:</h5>
                <p class="card-body m-auto" v-html="selected.options.description"></p>
                </div>
                <div class="modal-list-block p-10">
                <h5 class="card-header title">Images:</h5>
                <a class="card-body m-auto" v-for="image in selected.options.image" :key="image" :href="image" target="_blank"> image </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <!-- END options MODAL -->

    </div>
</template>
<script>

    import Vue from 'vue';
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import { BasePagination } from 'src/components';
    import laravel_vue_pagination from 'laravel-vue-pagination';
    import axios from 'axios';
    import vmodal from 'vue-js-modal'
    Vue.use(vmodal);
    Vue.component('pagination', laravel_vue_pagination);
    export default {
        components: {
            BasePagination,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        data() {
            return {
                tableData :{},
                tableColumns: [
                    {
                        prop: 'options.name',
                        label: 'name',
                        minWidth: 150
                    },
                    {
                        prop: 'options.city',
                        label: 'city',
                        minWidth: 50
                    },
                    {
                        prop: 'options.contact_person',
                        label: 'contact person',
                        minWidth: 120
                    },
                    {
                        prop: 'options.email',
                        label: 'email',
                        minWidth: 180
                    },
                    {
                        prop: 'order_number',
                        label: 'resnumber',
                        minWidth: 50
                    },
                ],
                arrayData :[],
                selected :{
                    "id": 5,
                    "options": {
                        "type": "",
                        "name": "",
                        "city": "",
                        "number_rooms": "",
                        "star": "",
                        "type_accommodation": "",
                        "contact_person": "",
                        "accommodation_address": "",
                        "email": "",
                        "owner_name": "",
                        "phone_number": "",
                        "description": "",
                        "image": [
                            "",
                            ""
                        ],
                        "options": null
                    },
                    "token": "oj4xWxJZB7SuvOI",
                    "order_number": "209837461",
                    "type": "HotelRegistration",
                    "created_at": "2020-03-12 13:20:12",
                    "updated_at": "2020-03-12 13:20:12"
                },
                searchQuery : ""
            };
        },
        mounted() {
            this.getResults();
        },
        methods: {
            getResults(page = 1 ){
                axios.get(Vue.prototype.websiteURL + 'admin/form/hotel-registration?page=' + page, User.prototype.getHeader())
                    .then((response)=>{
                        this.tableData = response.data.data;
                        this.arrayData = this.tableData.data;
                    })
            },
            handleRead(index,row){
                this.selected = row;
                this.$modal.show('options');
            }
        },
    };
</script>

<style>
  .mytrip_operators td,.mytrip_operators th{
    text-align: center !important;
  }
  .modal-list-item {
    display: inline-block;
    min-width: 250px;
    border: solid 1px silver;
    margin: 15px;
  }
  .modal-list-block{
    border: solid 1px silver;
    margin: 15px;
    display: block;
  }
  .p-10{
      padding: 10px;
  }
</style>
