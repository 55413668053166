<template>
    <div>
        <h1>Users Geo</h1>
        <geo-table></geo-table>
    </div>
</template>


<script>
    import GeoTable from "src/components/Flight/Geo/GeoTable";

    export default {
        name: 'GeoIndex',
        components: {
            GeoTable
        },
    }
</script>

