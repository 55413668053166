<template>
    <modal :show.sync="modal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">
            <template>
                <div class="text-center text-muted mb-4">
                    <h3>Sources Counter</h3>
                </div>
                <div>
                    <div v-for="source in sourcesCounter" style="font-weight: bold;">
                        <span>{{ source.utm_source }}: </span>
                        <span>{{ source.counter }}</span>
                    </div>
                </div>
            </template>
        </card>
    </modal>
</template>


<script>
    import {BaseButton, Modal} from 'src/components'
    import store from 'src/store';
    import moment from 'moment';

    export default {
        name: 'SourcesUTMModal',


        components: {
            BaseButton,
            Modal,
        },


        data(){
            return {

            }
        },


        mounted(){
            // this.$root.$on('get-companies', function (value) {
            //     this.companies = value;
            // }.bind(this));
        },


        methods:{

        },


        props: [
            'sourcesModalStatus',
        ],


        computed: {
            modal:{
                get(){
                    return this.sourcesModalStatus
                },

                set(value){
                    this.$emit('closemodal')
                }
            },

            sourcesCounter(){
                return store.getters.getSourcesCounter;
            },
        },

    }
</script>