<template>
    <div class="row mytrip_operator_update">
        <div class="col-12" v-has-permission="`mytrip_operator_update`">
            <div class="card">
                <div class="card-body">
                    <div class="row operatorInput p-t-30">
                        <div class="col-4">
                            <base-input
                                v-has-permission="`mytrip_operator_update_name`"
                                type="text"
                                label="Operator name"
                                placeholder="Operator Name"
                                v-model="data.name"
                            >
                            </base-input>
                        </div>
                        <div class="col-4">
                            <base-input
                                v-has-permission="
                                    `mytrip_operator_update_information`
                                "
                                type="text"
                                label="Operator Information"
                                placeholder="Operator Information"
                                v-model="data.information"
                            >
                            </base-input>
                        </div>
                        <div class="col-4">
                            <base-input
                                v-has-permission="
                                    `mytrip_operator_update_email`
                                "
                                type="text"
                                label="Operator Email"
                                placeholder="operator Email"
                                v-model="data.email"
                            >
                            </base-input>
                        </div>
                    </div>
                    <div class="row operatorInput p-t-30">
                        <div class="col-4">
                            <base-input
                                v-has-permission="
                                    `mytrip_operator_update_Phone`
                                "
                                type="text"
                                label="Phone"
                                placeholder="Phone"
                                v-model="data.phone1"
                            >
                            </base-input>
                        </div>
                        <div class="col-4">
                            <base-input
                                v-has-permission="
                                    `mytrip_operator_update_Phone2`
                                "
                                type="text"
                                label="Phone"
                                placeholder="Phone"
                                v-model="data.phone2"
                            >
                            </base-input>
                        </div>
                        <div class="col-4">
                            <base-input
                                type="text"
                                v-has-permission="`mytrip_operator_update_url`"
                                label="URL"
                                placeholder="Enter Url"
                                v-model="data.url"
                            >
                            </base-input>
                        </div>
                    </div>
                    <div class="row operatorInput p-t-30">
                        <div class="col-4">
                            <base-input
                                type="text"
                                v-has-permission="
                                    `mytrip_operator_update_socialLinks`
                                "
                                label="Facebook"
                                placeholder="facebook"
                                v-model="data.facebook"
                            >
                            </base-input>
                        </div>
                        <div class="col-4">
                            <base-input
                                type="text"
                                v-has-permission="
                                    `mytrip_operator_update_socialLinks`
                                "
                                label="Twitter"
                                placeholder="twitter"
                                v-model="data.twitter"
                            >
                            </base-input>
                        </div>
                        <div class="col-4">
                            <base-input
                                type="text"
                                v-has-permission="
                                    `mytrip_operator_update_socialLinks`
                                "
                                label="Linkedin"
                                placeholder="linkedin"
                                v-model="data.linkedin"
                            >
                            </base-input>
                        </div>
                    </div>
                    <div class="row operatorInput p-t-30">
                        <div class="col-8">
                            <base-input
                                type="text"
                                v-has-permission="
                                    `mytrip_operator_update_address`
                                "
                                label="Address"
                                placeholder="Address"
                                v-model="data.address"
                            >
                            </base-input>
                        </div>
                        <div class="col-4">
                            <base-input
                                type="text"
                                v-has-permission="
                                    `mytrip_operator_update_socialLinks`
                                "
                                label="Instagram"
                                placeholder="instagram"
                                v-model="data.instagram"
                            >
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center p-3">
                            <image-upload
                                @change="onImageChange"
                                select-text="Select Image"
                                v-has-permission="
                                    `mytrip_operator_update_image`
                                "
                            />
                        </div>
                    </div>
                    <div class="row">
                        <button class="btn btn-behance m-auto" @click="update">
                            Update Operator
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import { Modal, BaseAlert } from "src/components";
import { ImageUpload } from "src/components/index";
export default {
    components: {
        Modal,
        BaseAlert,
        ImageUpload
    },
    data() {
        return {
            data: {
                image: "",
                name: "",
                email: "",
                url: "",
                phone1: "",
                phone2: "",
                address: "",
                information: "",
                facebook: "",
                twitter: "",
                linkedin: "",
                instagram: ""
            }
        };
    },
    computed: {},
    methods: {
        update() {
            let formData = this.getData();
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken"),
                    "Content-Type":
                        "multipart/form-data; charset=utf-8; boundary=" +
                        Math.random()
                            .toString()
                            .substr(2)
                }
            };
            axios
                .post(
                    Vue.prototype.websiteURL +
                        "admin/mytrip/panel/operatorData/update/update-operator",
                    formData,
                    headers
                )
                .then(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                    this.$router.push({ name: "Operator" });
                })
                .catch(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "warning",
                        "The operation you requested did not succeed"
                    );
                });
        },
        notifyVue(verticalAlign, horizontalAlign, status, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: status
            });
        },
        getData() {
            let formData = new FormData();
            formData.append("id", this.data.id);
            formData.append("image", this.data.image);
            formData.append("name", this.data.name);
            formData.append("email", this.data.email);
            formData.append("url", this.data.url);
            formData.append("phone1", this.data.phone1);
            formData.append("phone2", this.data.phone2);
            formData.append("address", this.data.address);
            formData.append("information", this.data.information);
            formData.append("facebook", this.data.facebook);
            formData.append("linkedin", this.data.linkedin);
            formData.append("twitter", this.data.twitter);
            formData.append("instagram", this.data.instagram);
            return formData;
        },
        onImageChange(file) {
            this.data.image = file;
        },
        replaceData() {
            this.data = this.$route.params.data;
            this.data.image = null;
        }
    },
    mounted() {
        this.replaceData();
    }
};
</script>

<style>
.operatorInput {
    padding: 0 50px;
}
.operatorInput div.col-4 {
    margin: 10px auto !important;
}
.operatorInput div.col-4 div {
    width: 100%;
}
.operatorInput div.col-8 {
    margin: 10px auto !important;
}
.operatorInput div.col-8 div {
    width: 100%;
}
.operatorInput .form-control {
    border-radius: 3px;
    margin-top: 1px;
}
.mytrip_operator_update input {
    font-size: 13px;
}
.p-t-30 {
    padding-top: 30px;
}
</style>
