<template>
    <div class="hotelRequest_dashboard" id="hotelRequestDash">

        <div id="preloader">
            <div class="loader">
                <div class="loader"></div>
            </div>
        </div>

        <div class="container">


            <div class="container"
                 style="background: rgb(255, 255, 255);border-radius: 10px;padding-bottom: 1px;padding-top: 5px;">


                <div class="row">
                    <div class="col-5 searchboxc">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="searchBox" v-on:keyup.delete="CehckIfEmpty"
                                   id="searchBox" placeholder="Search">
                        </div>
                    </div>
                    <div class="col-7">
                        <div v-if="!this.loadingSpinner" class="toppgn">

                            <nav v-if="this.pagination.last_page > 1" aria-label="Page navigation">
                                <ul class="pagination">

                                    <li class="page-item" v-if="this.pagination.current_page > 1">
                                        <a class="page-link" href="#"
                                           v-on:click="goTopage(paginationPages[0].first_page_url)">First
                                            page</a>
                                    </li>

                                    <li class="page-item" v-if="this.pagination.current_page > 1">
                                        <a class="page-link" href="#"
                                           v-on:click="goTopage(paginationPages[0].prev_page_url)"
                                           tabindex="-1">Previous</a>
                                    </li>


                                    <li v-for="page in paginationPages"
                                        :class="page.curren_page == page.page_url ? 'page-item active' : 'page-item'">
                                        <a class="page-link" href="#" v-on:click="goTopage(page.page_url)">{{
                                            Number(page.page_no) }}</a>
                                    </li>


                                    <li class="page-item">
                                        <a class="page-link" href="#"
                                           v-if="paginationPages[0].curren_page !== this.pagination.last_page_url"
                                           v-on:click="goTopage(paginationPages[0].next_page_url)">Next</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link" href="#"
                                           v-if="paginationPages[0].curren_page !== this.pagination.last_page_url"
                                           v-on:click="goTopage(paginationPages[0].last_page_url)">Last page</a>
                                    </li>


                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <base-button class="btn"
                                     type="info"
                                     size="sm"
                                     @click.native="addNewRoom">Add New Room
                        </base-button>
                    </div>
                </div>

                <div class="comtainer" v-if="this.loadingSpinner">
                    <div class="text-center" v-if="this.loadingSpinner">
                        <div class="spinner-grow" style="width: 10rem; height: 10rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>


                <div v-if="!this.loadingSpinner" class="table-responsive text-nowrap">


                    <table class="table table-striped" style="font-size: 11px!important;">
                        <thead>
                        <tr style="background: rgb(233, 195, 243);color: #fff;">
                            <th>Hotel Slug</th>
                            <th>Room Type</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>


                        <tr v-for="reqst in reqsts">

                            <td>{{ reqst.hotel_slug }}</td>
                            <td>{{ reqst.type }}</td>
                            <td>

                                <button @click="editRoom(reqst)">Edit</button>
                                <base-button class="btn-link"
                                             type="info"
                                             size="sm"
                                             icon
                                             @click.native="askConfirm(reqst, 'This action can not be reverted', 'I know, Delete it!', 'removeRoom' )">
                                    <i class="tim-icons icon-trash-simple"></i>
                                </base-button>
                            </td>

                        </tr>
                        </tbody>
                    </table>


                </div>


                <div v-if="!this.loadingSpinner" class="btmpgn">

                    <nav v-if="this.pagination.last_page > 1" aria-label="Page navigation">
                        <ul class="pagination">

                            <li class="page-item" v-if="this.pagination.current_page > 1">
                                <a class="page-link" href="#" v-on:click="goTopage(paginationPages[0].first_page_url)">First
                                    page</a>
                            </li>

                            <li class="page-item" v-if="this.pagination.current_page > 1">
                                <a class="page-link" href="#" v-on:click="goTopage(paginationPages[0].prev_page_url)"
                                   tabindex="-1">Previous</a>
                            </li>


                            <li v-for="page in paginationPages"
                                :class="page.curren_page == page.page_url ? 'page-item active' : 'page-item'">
                                <a class="page-link" href="#" v-on:click="goTopage(page.page_url)">{{
                                    Number(page.page_no)
                                    }}</a>
                            </li>


                            <li class="page-item">
                                <a class="page-link" href="#"
                                   v-if="paginationPages[0].curren_page !== this.pagination.last_page_url"
                                   v-on:click="goTopage(paginationPages[0].next_page_url)">Next</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#"
                                   v-if="paginationPages[0].curren_page !== this.pagination.last_page_url"
                                   v-on:click="goTopage(paginationPages[0].last_page_url)">Last page</a>
                            </li>


                        </ul>
                    </nav>

                </div>


            </div>


        </div>




        <modal height="auto" :scrollable="true" name="edit-room">

            <div class="modal_main_content">



                <form>
                    <div class="form-group">
                        <label for="hotelSlug">Hotel Slug</label>
                        <input type="text" class="form-control" id="hotelSlug" aria-describedby="faqTypeHelp" v-model="roomEdit.hotel_slug" placeholder="Enter Hotel Slug">
                        <small id="faqTypeHelp" class="form-text text-muted">Please make sure the Slug Exists and is being used!</small>
                    </div>
                    <div class="form-group">
                        <label for="roomAvail">Availability title</label>
                        <input type="text" class="form-control" id="roomAvail" aria-describedby="faqTypeHelp" v-model="roomEdit.availability_title" placeholder="Enter room availability">
                    </div>
                    <div class="form-group">
                        <label for="roomType">Room Type</label>
                        <input type="text" class="form-control" id="roomType" v-model="roomEdit.type" placeholder="Room Type">
                    </div>

                    <div class="form-group">
                        <label for="maxCapacity">Max Capacity</label>
                        <input type="text" class="form-control" id="maxCapacity" v-model="roomEdit.max_capacity" placeholder="FAQ question">
                    </div>


                    <div class="form-group">
                        <label>Room Facility</label><br>
                        <input type="checkbox" id="wifi" value="wifi" v-model="facilities">
                        <label for="wifi"> Wifi</label>
                        <input type="checkbox" id="breakfast" value="breakfast" v-model="facilities">
                        <label for="breakfast"> Breakfast</label>
                        <input type="checkbox" id="city-view" value="city-view" v-model="facilities">
                        <label for="city-view"> City view</label>
                        <input type="checkbox" id="heating" value="heating" v-model="facilities">
                        <label for="heating"> Heating</label>
                        <input type="checkbox" id="bathroom" value="bathroom" v-model="facilities">
                        <label for="bathroom"> Bathroom</label>
                        <input type="checkbox" id="alarm-clock" value="alarm-clock" v-model="facilities">
                        <label for="alarm-clock"> Alarm clock</label>
                        <br>
                    </div>


                    <div class="form-group">
                        <label for="roomBadge">Room Badge Title</label>
                        <input type="text" class="form-control" id="roomBadge" v-model="roomEdit.badge" placeholder="Badge title">
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <div class="col-4">
                                <label for="couponTitle">Coupon title</label>
                                <input type="text" class="form-control" id="couponTitle"
                                       v-model="roomEdit.coupon_title" placeholder="Coupon title">
                            </div>
                            <div class="col-4">
                                <label for="couponCode">Coupon code</label>
                                <input type="text" class="form-control" id="couponCode"
                                       v-model="roomEdit.coupon_code" placeholder="Coupon code">
                            </div>
                            <div class="col-4">
                                <label for="couponAmount">Coupon amount</label>
                                <input type="text" class="form-control" id="couponAmount"
                                       v-model="roomEdit.coupon_amount" placeholder="Coupon amount">
                            </div>
                        </div>

                    </div>

                </form>


                <button v-if="!this.isNew" class="btn btn-primary" @click="saveRoom" :disabled="this.btnDsi">Save Changes</button>
                <button v-if="this.isNew" class="btn btn-primary" @click="saveRoom" :disabled="this.btnDsi">Add New</button>

            </div>

            <div class="modal_close_bottom">
                <base-button class="btn-link"
                             type="info"
                             size="sm"
                             @click.native="hideModal('edit-room')">Close
                </base-button>
            </div>
        </modal>

    </div>
</template>
<style>
    .modal_main_content{
        margin: 15px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .modal_close_bottom{
        background: #eaeaea;
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 20px;
    }
    .modal_close_top{
        background: #eaeaea;
        display: inline-block;
        width: 100%;
        position: absolute;
        top: 0;
    }
    .modal_close_bottom button{

        display: block;
        position: absolute;
        bottom: 0;
        right: 0;

    }
    .truncatedfield{
        display: table-cell;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .sortable {
        cursor: pointer;
    }

    .sortable::before {
        right: 1em;
        content: "\2191";
    }

    .sortable::after {
        right: 0.5em;
        content: "\2193";
    }

    .btmpgn {
        margin-top: 30px;
    }

    .toppgn {
        margin-bottom: 30px;
        margin-top: 10px;
        float: right;
    }

    .searchboxc {
        padding-top: 10px;
    }

    .page-item.active .page-link {
        background-color: #637d8c !important;
    }

    .page-link {
        color: #657d8d !important;
    }

    .page-item.active .page-link {
        color: #ffffff !important;
    }

    .change_icon {
        background: url(/images/icons/icons.png);
        width: 20px;
        height: 20px;
        background-position: -50px -4px;
        display: block;
        background-size: 198px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    .new_request_icon {
        background: url(/images/icons/icons.png);
        width: 20px;
        height: 20px;
        background-position: -110px -8px;
        display: block;
        background-size: 230px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    .cancelation_icon {
        background: url(/images/icons/icons.png);
        width: 20px;
        height: 20px;
        background-position: -7px -8px;
        display: block;
        background-size: 230px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    .sent_email_icon {
        background: url(/images/icons/icons.png);
        width: 30px;
        height: 23px;
        background-position: -216px -8px;
        display: block;
        background-size: 250px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    .ready_to_send_email_icon {
        background: url(/images/icons/icons.png);
        width: 30px;
        height: 23px;
        background-position: -166px -8px;
        display: block;
        background-size: 250px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    #hotelRequestDash #preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background: rgba(0, 0, 0, 0.2);
        display: none;
    }

    #hotelRequestDash .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: rgba(205, 33, 34, 1);
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    #hotelRequestDash .loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #2f4f4f;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }

    #hotelRequestDash .loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #ffbe00;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>


<script>
    import Vue from 'vue';

    window.Vue = require('vue');

    window._ = require('lodash');
    import axios from 'axios';

    // js import
    import swal from 'sweetalert2'

    // style import
    import 'sweetalert2/dist/sweetalert2.css'


    import vmodal from 'vue-js-modal'
    Vue.use(vmodal);

    import { VueEditor } from "vue2-editor";

    export default {
        name: 'WordpressRoom',
        data() {
            return {

                btnDsi:false,
                isNew:false,

                facilities: [],

                roomEdit:{
                    'hotel_slug':'',
                    'type':'',
                    'max_capacity':'',
                    'availability_title':'',
                    'badge': '',
                    'coupon_title': '',
                    'coupon_code': '',
                    'coupon_amount':''
                },

                loadingSpinner: true,
                reqsts: [],
                pagination: {
                    'current_page': '',
                    'first_page_url': '',
                    'from': '',
                    'last_page': '',
                    'last_page_url': '',
                    'next_page_url': '',
                    'path': '',
                    'per_page': '',
                    'prev_page_url': '',
                    'to': '',
                    'total': '',
                },
                searchBox: '',
                sortType: 'DESC',
                sortyByglobal: '',


            }
        },
        mounted() {
            this.getRequests()
        },
        components: {
            VueEditor
        },
        computed: {
            paginationPages() {


                var loopend = this.pagination.current_page;
                var loopestart = 0;


                if (loopend >= 6) {
                    loopestart = this.pagination.current_page - 4;
                    loopend = this.pagination.current_page + 3;
                }


                if (loopend < 6) {
                    loopend = 6;
                }

                if (loopend >= this.pagination.last_page) {
                    loopend = this.pagination.last_page
                }

                var pages = [];

                var i;
                for (i = loopestart; i < loopend; i++) {
                    var pag = {
                        'page_url': this.pagination.path + '?page=' + Number(i + 1),
                        'page_no': Number(i + 1),
                        'curren_page': this.pagination.path + '?page=' + this.pagination.current_page,
                        'next_page_url': this.pagination.next_page_url,
                        'first_page_url': this.pagination.first_page_url,
                        'last_page_url': this.pagination.last_page_url,
                        'prev_page_url': this.pagination.prev_page_url,
                    }

                    pages.push(pag)
                }


                return pages;
            },
            backAddress() {
                return this.websiteURLWithOutApi;
            }
        },
        watch: {
            searchBox: _.debounce(function () {
                if (this.searchBox) {

                    let data = {
                        key: this.searchBox
                    }
                    this.loadingSpinner = true
                    document.getElementById('preloader').style.display = 'block';

                    axios.post(this.websiteURL + 'admin/wordpress/hotel/room/get/search', data,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                            },
                        }
                    ).then(({data}) => {


                        this.reqsts = data.data

                        this.pagination.current_page = data.current_page
                        this.pagination.first_page_url = data.first_page_url
                        this.pagination.from = data.from
                        this.pagination.last_page = data.last_page
                        this.pagination.last_page_url = data.last_page_url
                        this.pagination.next_page_url = data.next_page_url
                        this.pagination.path = data.path
                        this.pagination.per_page = data.per_page
                        this.pagination.prev_page_url = data.prev_page_url
                        this.pagination.to = data.to
                        this.pagination.total = data.total
                        this.loadingSpinner = false
                        document.getElementById('preloader').style.display = 'none';


                    }).catch(e => {
                        this.loadingSpinner = false
                        document.getElementById('preloader').style.display = 'none';



                        this.$swal({
                            title: 'Something went wrong!',
                            text: "please try again",
                            type: 'error',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok, Whatever!',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                                location.reload();
                            }
                        });


                    })
                }
            }, 300),
        },
        methods: {

            CehckIfEmpty() {
                if (this.searchBox == '') {
                    this.getRequests()
                }

            },
            removeRoom(value){


                let deleteData = { id: value.id }

                axios.post(this.websiteURL + 'admin/wordpress/hotel/delete/rooms', deleteData,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({data}) => {


                    this.getRequests()
                    this.hideModal('edit-room')


                    swal({
                        title: 'Success',
                        text: 'Item deleted',
                        type: 'success',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        confirmButtonText: 'OK',
                        buttonsStyling: false
                    })
                }).catch((error)=>{

                    swal({
                        title: 'Error!',
                        text: 'something went wrong!',
                        type: 'error',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        confirmButtonText: 'OK',
                        buttonsStyling: false
                    })


                });

                return;

            },
            askConfirm(value, message, btntxt, which){
                swal({
                    title: 'Are you sure?',
                    text: message,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: btntxt,
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        if(which == 'removeRoom'){
                            this.removeRoom(value)
                        }
                    }
                });
            },
            showModal (value) {
                this.$modal.show(value);
            },
            hideModal (value) {
                this.$modal.hide(value);

                this.roomEdit = {
                    'hotel_slug':'',
                    'availability_title':'',
                    'type':'',
                    'max_capacity':'',
                }
                this.facilities =[]
            },
            addNewRoom(){
                this.isNew = true;
                this.roomEdit = {
                    'hotel_slug':'',
                    'availability_title':'',
                    'type':'',
                    'max_capacity':'',
                }
                this.facilities =[]
                this.showModal('edit-room');
            },
            editRoom(value){
                this.isNew = false;
                this.roomEdit = {
                    'id':value.id,
                    'hotel_slug': value.hotel_slug,
                    'availability_title': JSON.parse(value.room_details).availability_title,
                    'type': value.type,
                    'max_capacity': value.max_capacity,
                    'badge':  JSON.parse(value.room_details).badge,
                    'coupon_title':  JSON.parse(value.room_details).coupon.title,
                    'coupon_code':  JSON.parse(value.room_details).coupon.code,
                    'coupon_amount':  JSON.parse(value.room_details).coupon.amount,
                }
                this.facilities = JSON.parse(value.room_details).facilities


                console.log('myValue is', this.facilities)
                this.showModal('edit-room');
            },
            saveRoom(){

                this.btnDsi = true
                let saveData = this.roomEdit

                saveData.facilities = this.facilities

                axios.post(this.websiteURL + 'admin/wordpress/hotel/save/rooms', saveData,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({data}) => {


                    this.getRequests()
                    this.hideModal('edit-room')


                    swal({
                        title: 'Success',
                        text: 'Item saved',
                        type: 'success',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        confirmButtonText: 'OK',
                        buttonsStyling: false
                    })

                    this.btnDsi = false
                }).catch((error)=>{

                    swal({
                        title: 'Error!',
                        text: 'something went wrong!',
                        type: 'error',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        confirmButtonText: 'OK',
                        buttonsStyling: false
                    })

                    this.btnDsi = false


                });

                return;
            },
            goTopage(page) {

                this.loadingSpinner = true
                document.getElementById('preloader').style.display = 'block';


                if (this.sortyByglobal !== '') {
                    var data = {
                        'admin': true,
                        'key': this.searchBox,
                        'sort': this.sortyByglobal,
                        'type': this.sortType
                    }
                } else {
                    var data = {
                        'admin': true,
                        'key': this.searchBox
                    }
                }


                axios.post(page, data,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({data}) => {


                    this.reqsts = data.data

                    this.pagination.current_page = data.current_page
                    this.pagination.first_page_url = data.first_page_url
                    this.pagination.from = data.from
                    this.pagination.last_page = data.last_page
                    this.pagination.last_page_url = data.last_page_url
                    this.pagination.next_page_url = data.next_page_url
                    this.pagination.path = data.path
                    this.pagination.per_page = data.per_page
                    this.pagination.prev_page_url = data.prev_page_url
                    this.pagination.to = data.to
                    this.pagination.total = data.total
                    this.loadingSpinner = false
                    document.getElementById('preloader').style.display = 'none';


                })
                return;
            },
            getRequests() {
                axios.post(this.websiteURL + 'admin/wordpress/hotel/get/rooms', '',
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({data}) => {


                    this.reqsts = data.data


                    this.pagination.current_page = data.current_page
                    this.pagination.first_page_url = data.first_page_url
                    this.pagination.from = data.from
                    this.pagination.last_page = data.last_page
                    this.pagination.last_page_url = data.last_page_url
                    this.pagination.next_page_url = data.next_page_url
                    this.pagination.path = data.path
                    this.pagination.per_page = data.per_page
                    this.pagination.prev_page_url = data.prev_page_url
                    this.pagination.to = data.to
                    this.pagination.total = data.total
                    this.loadingSpinner = false
                    document.getElementById('preloader').style.display = 'none';



                });

                return;
            },
        }
    }
</script>
