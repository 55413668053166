<template>
    <div class="hotelRequest_dashboard" id="hotelRequestDash">

        <div id="preloader">
            <div class="loader">
                <div class="loader"></div>
            </div>
        </div>

        <div class="container">


            <div class="container"
                 style="background: rgb(255, 255, 255);border-radius: 10px;padding-bottom: 1px;padding-top: 5px;">


                <div class="row">
                    <div class="col-5 searchboxc">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="searchBox" v-on:keyup.delete="CehckIfEmpty"
                                   id="searchBox" placeholder="Search">
                        </div>
                    </div>
                    <div class="col-7">
                        <div v-if="!this.loadingSpinner" class="toppgn">

                            <nav v-if="this.pagination.last_page > 1" aria-label="Page navigation">
                                <ul class="pagination">

                                    <li class="page-item" v-if="this.pagination.current_page > 1">
                                        <a class="page-link" href="#"
                                           v-on:click="goTopage(paginationPages[0].first_page_url)">First
                                            page</a>
                                    </li>

                                    <li class="page-item" v-if="this.pagination.current_page > 1">
                                        <a class="page-link" href="#"
                                           v-on:click="goTopage(paginationPages[0].prev_page_url)"
                                           tabindex="-1">Previous</a>
                                    </li>


                                    <li v-for="page in paginationPages"
                                        :class="page.curren_page == page.page_url ? 'page-item active' : 'page-item'">
                                        <a class="page-link" href="#" v-on:click="goTopage(page.page_url)">{{
                                            Number(page.page_no) }}</a>
                                    </li>


                                    <li class="page-item">
                                        <a class="page-link" href="#"
                                           v-if="paginationPages[0].curren_page !== this.pagination.last_page_url"
                                           v-on:click="goTopage(paginationPages[0].next_page_url)">Next</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link" href="#"
                                           v-if="paginationPages[0].curren_page !== this.pagination.last_page_url"
                                           v-on:click="goTopage(paginationPages[0].last_page_url)">Last page</a>
                                    </li>


                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>


                <div class="comtainer" v-if="this.loadingSpinner">
                    <div class="text-center" v-if="this.loadingSpinner">
                        <div class="spinner-grow" style="width: 10rem; height: 10rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>


                <div v-if="!this.loadingSpinner" class="table-responsive text-nowrap">


                    <table class="table table-striped" style="font-size: 11px!important;">
                        <thead>
                        <tr style="background: #607D8B;color: #fff;">
                            <th class="sortable" @click="sortBy('id')">#</th>
                            <th>Status</th>
                            <th>PDF</th>
                            <th>Request No.</th>
                            <th style="max-width: 75px;" class="sortable" @click="sortBy('created_at')">Submitted</th>
                            <th>Type</th>
                            <th>Hotel Name</th>
                            <th>Qtt.</th>
                            <th>Type</th>
                            <th class="sortable" @click="sortBy('check_in_date')">Check in</th>
                            <th>Nights</th>
                            <th>Customers</th>
                            <th class="sortable" @click="sortBy('user')">By</th>
                            <th>Bcc</th>
                        </tr>
                        </thead>
                        <tbody>


                        <tr v-for="reqst in reqsts">
                            <td>{{ reqst.id }}</td>
                            <td style="text-align: center"><span style="font-size: 18px"
                                                                 :class="reqst.email_sending_status == 'SENT' ? 'tim-icons icon-email-85' : 'tim-icons icon-time-alarm' "></span>
                            </td>
                            <td>
                                <!--<a v-if="reqst.email_sending_status == 'SENT'"-->
                                <!--:href="backAddress+'/pdfs/htlrqs/'+reqst.request_number+'-'+reqst.request_type+reqst.created_at+'.pdf'"-->
                                <!--target="_blank"-->
                                <!--&gt;-->
                                <!--<button>PDF</button>-->
                                <!--</a>-->

                                <button @click="getReqPdf(reqst.id)">PDF</button>

                            </td>
                            <td>{{ reqst.request_number }}</td>
                            <td style="max-width: 100px;overflow: hidden">{{ reqst.created_at }}</td>
                            <td style="text-align: center"><span
                                :class="reqst.request_type == 'New Request' ? 'tim-icons icon-single-copy-04' : reqst.request_type == 'Change' ? 'tim-icons icon-refresh-02' : reqst.request_type == 'Cancellation' ? 'tim-icons icon-simple-remove' : ''"></span>
                            </td>
                            <td style="max-width: 40px;overflow: hidden">{{ reqst.hotel_name }}</td>
                            <td>{{ reqst.rooms }}</td>
                            <td style="max-width: 50px;overflow: hidden">{{ reqst.room_type }}</td>
                            <td>{{ reqst.check_in_date }}</td>
                            <td>{{ reqst.nights }}</td>
                            <td style="max-width: 132px;overflow: hidden;">
                                <span v-for="customer in JSON.parse(reqst.customers)">{{ customer.name }}, </span>
                            </td>
                            <td>{{ reqst.user }}</td>

                            <td>{{ reqst.bcc_email }}</td>

                        </tr>
                        </tbody>
                    </table>


                </div>


                <div v-if="!this.loadingSpinner" class="btmpgn">

                    <nav v-if="this.pagination.last_page > 1" aria-label="Page navigation">
                        <ul class="pagination">

                            <li class="page-item" v-if="this.pagination.current_page > 1">
                                <a class="page-link" href="#" v-on:click="goTopage(paginationPages[0].first_page_url)">First
                                    page</a>
                            </li>

                            <li class="page-item" v-if="this.pagination.current_page > 1">
                                <a class="page-link" href="#" v-on:click="goTopage(paginationPages[0].prev_page_url)"
                                   tabindex="-1">Previous</a>
                            </li>


                            <li v-for="page in paginationPages"
                                :class="page.curren_page == page.page_url ? 'page-item active' : 'page-item'">
                                <a class="page-link" href="#" v-on:click="goTopage(page.page_url)">{{
                                    Number(page.page_no)
                                    }}</a>
                            </li>


                            <li class="page-item">
                                <a class="page-link" href="#"
                                   v-if="paginationPages[0].curren_page !== this.pagination.last_page_url"
                                   v-on:click="goTopage(paginationPages[0].next_page_url)">Next</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#"
                                   v-if="paginationPages[0].curren_page !== this.pagination.last_page_url"
                                   v-on:click="goTopage(paginationPages[0].last_page_url)">Last page</a>
                            </li>


                        </ul>
                    </nav>

                </div>


            </div>


        </div>
    </div>
</template>
<style>
    .sortable {
        cursor: pointer;
    }

    .sortable::before {
        right: 1em;
        content: "\2191";
    }

    .sortable::after {
        right: 0.5em;
        content: "\2193";
    }

    .btmpgn {
        margin-top: 30px;
    }

    .toppgn {
        margin-bottom: 30px;
        margin-top: 10px;
        float: right;
    }

    .searchboxc {
        padding-top: 10px;
    }

    .page-item.active .page-link {
        background-color: #637d8c !important;
    }

    .page-link {
        color: #657d8d !important;
    }

    .page-item.active .page-link {
        color: #ffffff !important;
    }

    .change_icon {
        background: url(/images/icons/icons.png);
        width: 20px;
        height: 20px;
        background-position: -50px -4px;
        display: block;
        background-size: 198px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    .new_request_icon {
        background: url(/images/icons/icons.png);
        width: 20px;
        height: 20px;
        background-position: -110px -8px;
        display: block;
        background-size: 230px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    .cancelation_icon {
        background: url(/images/icons/icons.png);
        width: 20px;
        height: 20px;
        background-position: -7px -8px;
        display: block;
        background-size: 230px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    .sent_email_icon {
        background: url(/images/icons/icons.png);
        width: 30px;
        height: 23px;
        background-position: -216px -8px;
        display: block;
        background-size: 250px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    .ready_to_send_email_icon {
        background: url(/images/icons/icons.png);
        width: 30px;
        height: 23px;
        background-position: -166px -8px;
        display: block;
        background-size: 250px;
        background-repeat: no-repeat;
        margin: 0 auto;
    }

    #hotelRequestDash #preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background: rgba(0, 0, 0, 0.2);
        display: none;
    }

    #hotelRequestDash .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: rgba(205, 33, 34, 1);
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    #hotelRequestDash .loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #2f4f4f;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }

    #hotelRequestDash .loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #ffbe00;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>


<script>
    import Vue from 'vue';

    window.Vue = require('vue');

    window._ = require('lodash');
    import axios from 'axios';

    import VueSweetalert2 from 'vue-sweetalert2';

    Vue.use(VueSweetalert2);
    export default {
        name: 'htlrqsadmin',
        data() {
            return {


                loadingSpinner: true,
                reqsts: [],
                pagination: {
                    'current_page': '',
                    'first_page_url': '',
                    'from': '',
                    'last_page': '',
                    'last_page_url': '',
                    'next_page_url': '',
                    'path': '',
                    'per_page': '',
                    'prev_page_url': '',
                    'to': '',
                    'total': '',
                },
                searchBox: '',
                sortType: 'DESC',
                sortyByglobal: '',


            }
        },
        mounted() {
            this.getRequests()
        },
        computed: {
            paginationPages() {


                var loopend = this.pagination.current_page;
                var loopestart = 0;


                if (loopend >= 6) {
                    loopestart = this.pagination.current_page - 4;
                    loopend = this.pagination.current_page + 3;
                }


                if (loopend < 6) {
                    loopend = 6;
                }

                if (loopend >= this.pagination.last_page) {
                    loopend = this.pagination.last_page
                }

                var pages = [];

                var i;
                for (i = loopestart; i < loopend; i++) {
                    var pag = {
                        'page_url': this.pagination.path + '?page=' + Number(i + 1),
                        'page_no': Number(i + 1),
                        'curren_page': this.pagination.path + '?page=' + this.pagination.current_page,
                        'next_page_url': this.pagination.next_page_url,
                        'first_page_url': this.pagination.first_page_url,
                        'last_page_url': this.pagination.last_page_url,
                        'prev_page_url': this.pagination.prev_page_url,
                    }

                    pages.push(pag)
                }


                return pages;
            },
            backAddress() {
                return this.websiteURLWithOutApi;
            }
        },
        watch: {
            searchBox: _.debounce(function () {
                if (this.searchBox) {

                    let data = {
                        key: this.searchBox
                    }
                    this.loadingSpinner = true
                    document.getElementById('preloader').style.display = 'block';

                    axios.post(this.websiteURL + 'admin/hotelrequest/get/search', data,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                            },
                        }
                    ).then(({data}) => {


                        this.reqsts = data.data

                        this.pagination.current_page = data.current_page
                        this.pagination.first_page_url = data.first_page_url
                        this.pagination.from = data.from
                        this.pagination.last_page = data.last_page
                        this.pagination.last_page_url = data.last_page_url
                        this.pagination.next_page_url = data.next_page_url
                        this.pagination.path = data.path
                        this.pagination.per_page = data.per_page
                        this.pagination.prev_page_url = data.prev_page_url
                        this.pagination.to = data.to
                        this.pagination.total = data.total
                        this.loadingSpinner = false
                        document.getElementById('preloader').style.display = 'none';


                    }).catch(e => {
                        this.loadingSpinner = false
                        document.getElementById('preloader').style.display = 'none';



                        this.$swal({
                            title: 'Something went wrong!',
                            text: "please try again",
                            type: 'error',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok, Whatever!',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                                location.reload();
                            }
                        });


                    })
                }
            }, 300),
        },
        methods: {
            getReqPdf(val) {
                document.getElementById('preloader').style.display = 'block';
                let data = {
                    id: val
                }
                axios.post(this.websiteURL + 'admin/hotelrequest/get/htlrqst-pdf', data,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({data}) => {
                    window.open(data, '_blank');
                    document.getElementById('preloader').style.display = 'none';
                });
            },
            sortBy(val) {


                if (this.sortType == 'DESC') {
                    this.sortType = 'ASC'
                } else {
                    this.sortType = 'DESC'
                }


                if (this.searchBox) {

                    var data = {
                        'sort': val,
                        'type': this.sortType,
                        'key': this.searchBox
                    }

                } else {

                    var data = {
                        'sort': val,
                        'type': this.sortType
                    }

                }


                this.sortyByglobal = val

                this.loadingSpinner = true
                document.getElementById('preloader').style.display = 'block';



                axios.post(this.websiteURL + 'admin/hotelrequest/get/sort', data,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({data}) => {


                    this.reqsts = data.data

                    this.pagination.current_page = data.current_page
                    this.pagination.first_page_url = data.first_page_url
                    this.pagination.from = data.from
                    this.pagination.last_page = data.last_page
                    this.pagination.last_page_url = data.last_page_url
                    this.pagination.next_page_url = data.next_page_url
                    this.pagination.path = data.path
                    this.pagination.per_page = data.per_page
                    this.pagination.prev_page_url = data.prev_page_url
                    this.pagination.to = data.to
                    this.pagination.total = data.total
                    this.loadingSpinner = false
                    document.getElementById('preloader').style.display = 'none';



                });


                return;


            },
            CehckIfEmpty() {
                if (this.searchBox == '') {
                    this.getRequests()
                }

            },
            goTopage(page) {

                this.loadingSpinner = true
                document.getElementById('preloader').style.display = 'block';


                if (this.sortyByglobal !== '') {
                    var data = {
                        'admin': true,
                        'key': this.searchBox,
                        'sort': this.sortyByglobal,
                        'type': this.sortType
                    }
                } else {
                    var data = {
                        'admin': true,
                        'key': this.searchBox
                    }
                }


                axios.post(page, data,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({data}) => {


                    this.reqsts = data.data

                    this.pagination.current_page = data.current_page
                    this.pagination.first_page_url = data.first_page_url
                    this.pagination.from = data.from
                    this.pagination.last_page = data.last_page
                    this.pagination.last_page_url = data.last_page_url
                    this.pagination.next_page_url = data.next_page_url
                    this.pagination.path = data.path
                    this.pagination.per_page = data.per_page
                    this.pagination.prev_page_url = data.prev_page_url
                    this.pagination.to = data.to
                    this.pagination.total = data.total
                    this.loadingSpinner = false
                    document.getElementById('preloader').style.display = 'none';


                });

                return;
            },
            getRequests() {
                var data = {
                    'admin': true
                }
                axios.post(this.websiteURL + 'admin/hotelrequest/get/adrqst', data,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        },
                    }
                ).then(({data}) => {


                    this.reqsts = data.data

                    this.pagination.current_page = data.current_page
                    this.pagination.first_page_url = data.first_page_url
                    this.pagination.from = data.from
                    this.pagination.last_page = data.last_page
                    this.pagination.last_page_url = data.last_page_url
                    this.pagination.next_page_url = data.next_page_url
                    this.pagination.path = data.path
                    this.pagination.per_page = data.per_page
                    this.pagination.prev_page_url = data.prev_page_url
                    this.pagination.to = data.to
                    this.pagination.total = data.total
                    this.loadingSpinner = false
                    document.getElementById('preloader').style.display = 'none';



                });

                return;
            },
        }
    }
</script>
