<template>
    <div class="content" v-has-permission = "`users_table`">
      <div class="row mt-5">
        <div class="col-12">
          <card card-body-classes="table-full-width">
            <div>
              <el-table :data="arrayData">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
              </el-table>
            </div>
            <div class="pagination_number">
              <pagination :data="tableData" @pagination-change-page="getResults" :limit="5"></pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
</template>
<script>

    import Vue from 'vue';
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import { BasePagination } from 'src/components';
    import laravel_vue_pagination from 'laravel-vue-pagination';
    import axios from 'axios';
    Vue.component('pagination', laravel_vue_pagination);
    export default {
        components: {
            BasePagination,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        data() {
            return {
                tableData :{},
                tableColumns: [
                    {
                        prop: 'name',
                        label: 'Name',
                        minWidth: 150
                    },
                    {
                        prop: 'roles[0].name',
                        label: 'Role',
                        minWidth: 100
                    },
                    {
                        prop: 'created_at',
                        label: 'Created At',
                        minWidth: 180
                    }
                ],
                arrayData :[],
                searchQuery : ""
            };
        },
        mounted() {
            this.getResults();
        },
        methods: {
            getResults(page = 1 ){
                let data = {
                    'page'  : page
                };
                axios.post(Vue.prototype.websiteURL + 'admin/users/get-all-users', data, User.prototype.getHeader())
                    .then((response)=>{
                         this.tableData = response.data.data;
                         this.arrayData = this.tableData.data;
                    })
            }
        },
    };
</script>

<style>
  .mytrip_things td,.mytrip_things th{
    text-align: center !important;
  }
</style>
