<template>
  <div class="row mytrip_things">
    <things-table></things-table>
  </div>
</template>
<script>
  import thingsTable from 'src/components/Mytrip/ThingsTable.vue';
export default {
  components: {
      thingsTable
  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
  mounted() {

  },
  beforeDestroy() {

  }
};
</script>

<style></style>
