<template>
    <div class="row">
        <div class="col-md-12">

            <!-- Date Picker -->
            <base-input class="stats__datepicker__wrapper">
                <el-date-picker v-model="fromDate"
                                type="date"
                                placeholder="Pick From Date">
                </el-date-picker>
                <el-date-picker v-model="toDate"
                                type="date"
                                placeholder="Pick To Date">
                </el-date-picker>
                <base-button type="primary" @click="getData(fromDate, toDate)">Filter</base-button>
            </base-input>
            <!-- Date Picker -->

            <!-- Chart Stats -->
            <h3 class="stat__h3">Search, Book, Reserve and Sale Chart</h3>
            <canvas id="searchChart" width="400" height="200"></canvas>
            <!-- /Chart Stats -->

            <!-- Geo Pie Stats -->
            <h3 class="stat__h3 chart__child__parent">Geo Chart</h3>
            <canvas id="pieChart" width="400" height="200"></canvas>
            <!-- /Geo Pie Stats -->


            <!-- Sale & Tickets Pie Charts -->
            <div class="stats__tickets-sale--pie-chart">
                <!-- Sale Pie Stats -->
                <div class="stats__tickets-sale--pie-chart__child">
                    <h3 class="stat__h3 chart__child__parent">Sale Chart</h3>
                    <canvas id="salePieChart" width="400" height="200" v-show="salePieChartCountComputed > 0"></canvas>
                    <div v-show="salePieChartCountComputed == 0">Not exist any sale in this days</div>
                </div>
                <!-- /Sale Pie Stats -->



                <!-- Tickets Pie Stats -->
                <div class="stats__tickets-sale--pie-chart__child">
                    <h3 class="stat__h3 chart__child__parent">Tickets Chart</h3>
                    <canvas id="ticketsPieChart" width="400" height="200" v-show="ticketsPieChartCountComputed > 0"></canvas>
                    <div v-show="ticketsPieChartCountComputed == 0">Not exist any ticket in this days</div>
                </div>
                <!-- /Tickets Pie Stats -->
            </div>
            <!-- /Sale & Tickets Pie Charts -->


            <!-- Popular Departure & Destination Airports Pie Charts -->
            <div class="stats__tickets-sale--pie-chart">
                <!-- Popular Departure Airports -->
                <div class="stats__tickets-sale--pie-chart__child">
                    <h3 class="stat__h3 chart__child__parent">Popular Departure Airports</h3>
                    <canvas id="departurePieChart" width="400" height="200" v-show="departurePieChartCountComputed > 0"></canvas>
                    <div v-show="departurePieChartCountComputed == 0">Not exist any departure airports in this days</div>
                </div>
                <!-- /Popular Departure Airports -->



                <!-- Popular Destination Airports -->
                <div class="stats__tickets-sale--pie-chart__child">
                    <h3 class="stat__h3 chart__child__parent">Popular Destination Airports</h3>
                    <canvas id="destinationPieChart" width="400" height="200" v-show="destinationPieChartCountComputed > 0"></canvas>
                    <div v-show="destinationPieChartCountComputed == 0">Not exist any destination airports in this days</div>
                </div>
                <!-- /Popular Destination Airports -->
            </div>
            <!-- /Popular Departure & Destination Airports Pie Charts -->


            <!-- Table -->
            <h3 class="stat__h3 chart__child__parent">Numerical Data</h3>
            <el-table :data="tableData">
                <el-table-column min-width="150" prop="title"
                                 label="Title">
                </el-table-column>
                <el-table-column min-width="200" prop="value"
                                 label="Value">
                </el-table-column>
            </el-table>
            <!-- /Table -->

        </div>
    </div>
</template>


<script>
    import axios from 'axios'
    import Vue from 'vue'
    import {Table, TableColumn, DatePicker, TimeSelect} from 'element-ui'
    import Chart from 'chart.js'
    import moment from 'moment'
    import {BaseButton} from 'src/components'

    export default {
        name: 'StatsCharts',


        components: {
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            [DatePicker.name]: DatePicker,
            [TimeSelect.name]: TimeSelect,
            BaseButton,
        },


        data() {
            return {
                dailyStatsChartData: {
                    type: 'line',
                    data: {
                        labels: [],
                        datasets: [
                            {
                                label: 'Search',
                                data: [],
                                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                borderColor: 'rgba(255, 99, 132, 1)',
                                borderWidth: 1
                            },
                            {
                                label: 'Book',
                                data: [],
                                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                borderColor: 'rgba(54, 162, 235, 1)',
                                borderWidth: 1
                            },
                            {
                                label: 'Reserve',
                                data: [],
                                backgroundColor: 'rgba(201, 174, 255, 0.2)',
                                borderColor: 'rgba(201, 174, 255, 1)',
                                borderWidth: 1
                            },
                            {
                                label: 'Sale',
                                data: [],
                                backgroundColor: 'rgba(255, 196, 139, 0.2)',
                                borderColor: 'rgba(255, 196, 139, 1)',
                                borderWidth: 1
                            },
                        ],
                    },
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                },
                geoPieChartData: {
                    type: 'pie',
                    data: {
                        datasets: [{
                            data: [],
                            backgroundColor: ['#FFCCBC', '#FFE0B2', '#FFF9C4', '#F0F4C3', '#DCEDC8', '#C8E6C9', '#B2DFDB', '#B2EBF2', '#B3E5FC', '#BBDEFB', '#C5CAE9', '#D1C4E9', '#E1BEE7', '#F8BBD0', '#ffcdd2'],
                            borderColor: ['#FF8A65', '#FFB74D', '#FFF176', '#DCE775', '#AED581', '#81C784', '#4DB6AC', '#4DD0E1', '#4FC3F7', '#64B5F6', '#7986CB', '#9575CD', '#BA68C8', '#F06292', '#e57373'],
                            borderWidth: 1
                        }],

                        labels: []
                    },
                    options: {
                        //
                    }
                },
                salePieChartData: {
                    type: 'pie',
                    data: {
                        datasets: [{
                            data: [],
                            backgroundColor: ['#FFCCBC', '#FFE0B2', '#FFF9C4', '#F0F4C3', '#DCEDC8', '#C8E6C9', '#B2DFDB', '#B2EBF2', '#B3E5FC', '#BBDEFB', '#C5CAE9', '#D1C4E9', '#E1BEE7', '#F8BBD0', '#ffcdd2'],
                            borderColor: ['#FF8A65', '#FFB74D', '#FFF176', '#DCE775', '#AED581', '#81C784', '#4DB6AC', '#4DD0E1', '#4FC3F7', '#64B5F6', '#7986CB', '#9575CD', '#BA68C8', '#F06292', '#e57373'],
                            borderWidth: 1
                        }],

                        labels: []
                    },
                    options: {
                        //
                    }
                },
                ticketsPieChartData: {
                    type: 'pie',
                    data: {
                        datasets: [{
                            data: [],
                            backgroundColor: ['#FFCCBC', '#FFE0B2', '#FFF9C4', '#F0F4C3', '#DCEDC8', '#C8E6C9', '#B2DFDB', '#B2EBF2', '#B3E5FC', '#BBDEFB', '#C5CAE9', '#D1C4E9', '#E1BEE7', '#F8BBD0', '#ffcdd2'],
                            borderColor: ['#FF8A65', '#FFB74D', '#FFF176', '#DCE775', '#AED581', '#81C784', '#4DB6AC', '#4DD0E1', '#4FC3F7', '#64B5F6', '#7986CB', '#9575CD', '#BA68C8', '#F06292', '#e57373'],
                            borderWidth: 1
                        }],

                        labels: []
                    },
                    options: {
                        //
                    }
                },
                departureAirportsPieChartData: {
                    type: 'pie',
                    data: {
                        datasets: [{
                            data: [],
                            backgroundColor: ['#FFCCBC', '#FFE0B2', '#FFF9C4', '#F0F4C3', '#DCEDC8', '#C8E6C9', '#B2DFDB', '#B2EBF2', '#B3E5FC', '#BBDEFB', '#C5CAE9', '#D1C4E9', '#E1BEE7', '#F8BBD0', '#ffcdd2'],
                            borderColor: ['#FF8A65', '#FFB74D', '#FFF176', '#DCE775', '#AED581', '#81C784', '#4DB6AC', '#4DD0E1', '#4FC3F7', '#64B5F6', '#7986CB', '#9575CD', '#BA68C8', '#F06292', '#e57373'],
                            borderWidth: 1
                        }],

                        labels: []
                    },
                    options: {
                        //
                    }
                },
                destinationAirportsPieChartData: {
                    type: 'pie',
                    data: {
                        datasets: [{
                            data: [],
                            backgroundColor: ['#FFCCBC', '#FFE0B2', '#FFF9C4', '#F0F4C3', '#DCEDC8', '#C8E6C9', '#B2DFDB', '#B2EBF2', '#B3E5FC', '#BBDEFB', '#C5CAE9', '#D1C4E9', '#E1BEE7', '#F8BBD0', '#ffcdd2'],
                            borderColor: ['#FF8A65', '#FFB74D', '#FFF176', '#DCE775', '#AED581', '#81C784', '#4DB6AC', '#4DD0E1', '#4FC3F7', '#64B5F6', '#7986CB', '#9575CD', '#BA68C8', '#F06292', '#e57373'],
                            borderWidth: 1
                        }],

                        labels: []
                    },
                    options: {
                        //
                    }
                },
                tableData: [],
                fromDate: '',
                toDate: '',
                changed: false,
                pieChart: {},
                counterChart: {},
                saleChart: {},
                ticketsChart: {},
                departureChart: {},
                destinationChart: {},
            }
        },


        beforeMount() {
            this.getData();
        },

        mounted(){
            //
        },

        methods: {
            getData(from = null, to = null) {
                let url;
                if(from == null)
                    url = Vue.prototype.websiteURL + "admin/flight/" + "chart/overview/30"
                else{
                    url = Vue.prototype.websiteURL + "admin/flight/" + "chart/overview/" + moment(this.fromDate).format('YYYY-MM-DD') + "/" + moment(this.toDate).format('YYYY-MM-DD');
                    this.changed = true;
                }


                axios.get( url )
                    .then(res => {
                        // Daily Stats Chart Data
                        this.fillDailyStatsChartData(res.data.data);

                        // Geo Pie Chart
                        this.fillPieChartData(res.data.data);

                        // Table
                        this.fillTable(res.data.data);

                        // Sale Pie Chart
                        this.fillSalePieChart(res.data.data);

                        // Tickets Pie Chart
                        this.fillTicketsPieChart(res.data.data);

                        // Departure Airports Pie Chart
                        this.fillDeparturePieChart(res.data.data);

                        // Departure Airports Pie Chart
                        this.fillDestinationPieChart(res.data.data);

                    })
                    .catch(e => {
                        //
                    })
            },

            // Fill Chart
            fillDailyStatsChartData(chart){
                // Reset Old Data
                this.dailyStatsChartData.data.datasets[0].data = [];
                this.dailyStatsChartData.data.datasets[1].data = [];
                this.dailyStatsChartData.data.datasets[2].data = [];
                this.dailyStatsChartData.data.datasets[3].data = [];
                this.dailyStatsChartData.data.labels = [];

                // Fill New Data
                this.dailyStatsChartData.data.datasets[0].data = Object.values(chart.searchStat);
                this.dailyStatsChartData.data.datasets[1].data = Object.values(chart.bookStat);
                this.dailyStatsChartData.data.datasets[2].data = Object.values(chart.reserveStat);
                this.dailyStatsChartData.data.datasets[3].data = Object.values(chart.saleStat);
                this.dailyStatsChartData.data.labels = Object.keys(chart.searchStat);
                let ctx = document.getElementById('searchChart')

                // Check Change Status for Update Chart
                if(this.changed)
                    this.counterChart.update();
                else
                    this.counterChart = new Chart(ctx, this.dailyStatsChartComputed)
            },


            // Fill Pie
            fillPieChartData(chart){
                // Reset Old Data
                this.geoPieChartData.data.datasets[0].data = [];
                this.geoPieChartData.data.labels = [];

                // Fill New Data
                let geo = chart.geoByLocation;
                geo.sort(function (a, b) {
                    return b.count - a.count;
                })
                for(let i = 0; i < geo.length; i++){
                    this.geoPieChartData.data.datasets[0].data.push(geo[i].count);
                    this.geoPieChartData.data.labels.push(geo[i].country);
                }

                if(this.changed){
                    this.pieChart.update();
                } else{
                    let ptx = document.getElementById('pieChart')
                    this.pieChart = new Chart(ptx, this.geoPieChartComputed)
                }
            },


            // Fill Table
            fillTable(chart){
                // Reset Old Data
                this.tableData = [];

                // Fill New Data
                try {
                    this.tableData.push(
                        {
                            title: 'Search Count',
                            value: chart.searchCount
                        },
                        {
                            title: 'Select Count',
                            value: chart.selectCount
                        },
                        {
                            title: 'Reserve Count',
                            value: chart.reserveCount
                        },
                        {
                            title: 'Book Count',
                            value: chart.bookCount
                        },
                        {
                            title: 'Book/Search',
                            value: chart.bookPerSearch
                        },
                        {
                            title: 'Reserve/Search',
                            value: chart.reservePerSearch
                        },
                        {
                            title: 'Select/Search',
                            value: chart.selectPerSearch
                        },
                        {
                            title: 'Referred by UTM',
                            value: chart.referredByUTMCount
                        },
                        {
                            title: 'Tickets Count',
                            value: chart.ticketCount[0].count != null ? chart.ticketCount[0].count : 0
                        },
                        {
                            title: 'Euro Sale Amount',
                            value: chart.euroSaleAmount
                        },
                        {
                            title: 'Rial Sale Amount',
                            value: chart.rialSaleAmount
                        },
                        {
                            title: 'Alibaba Sale',
                            value: chart.alibabaSale
                        },
                        {
                            title: 'Charter 724 Sale',
                            value: chart.ch724Sale
                        },
                    )
                } catch (e) {
                    //
                }

                //
            },


            // Fill Sale Pie Chart
            fillSalePieChart(chart){
                // Reset Old Data
                this.salePieChartData.data.datasets[0].data = [];
                this.salePieChartData.data.labels = [];

                // Fill New Data
                let sale = chart.bookByAgent;
                sale.sort(function (a, b) {
                    return b.count - a.count;
                });
                for(let i = 0; i < sale.length; i++){
                    this.salePieChartData.data.datasets[0].data.push(sale[i].count);
                    this.salePieChartData.data.labels.push(sale[i].company);
                }

                try {
                    if(this.changed){
                        if(Object.entries(this.saleChart).length === 0 && this.salePieChartCountComputed > 0){
                            let ptx = document.getElementById('salePieChart')
                            this.saleChart = new Chart(ptx, this.salePieChartComputed)
                        }
                        if(Object.entries(this.saleChart).length > 0){
                            this.saleChart.update();
                        }
                    } else{
                        if(this.salePieChartCountComputed > 0){
                            let ptx = document.getElementById('salePieChart')
                            this.saleChart = new Chart(ptx, this.salePieChartComputed)
                        }
                    }
                } catch (e) {
                    //
                }
            },



            // Fill Tickets Pie Chart
            fillTicketsPieChart(chart){
                // Reset Old Data
                this.ticketsPieChartData.data.datasets[0].data = [];
                this.ticketsPieChartData.data.labels = [];

                // Fill New Data
                let tickets = chart.ticketByAgent;
                tickets.sort(function (a, b) {
                    return b.count - a.count;
                });
                for(let i = 0; i < tickets.length; i++){
                    this.ticketsPieChartData.data.datasets[0].data.push(tickets[i].count);
                    this.ticketsPieChartData.data.labels.push(tickets[i].company);
                }

                try {
                    if(this.changed){
                        if(Object.entries(this.ticketsChart).length === 0 && this.ticketsPieChartCountComputed > 0){
                            let ptx = document.getElementById('ticketsPieChart')
                            this.ticketsChart = new Chart(ptx, this.ticketsPieChartComputed)
                        }
                        if(Object.entries(this.ticketsChart).length > 0){
                            this.ticketsChart.update();
                        }
                    } else{
                        if(this.ticketsPieChartCountComputed > 0){
                            let ptx = document.getElementById('ticketsPieChart')
                            this.ticketsChart = new Chart(ptx, this.ticketsPieChartComputed)
                        }
                    }
                } catch (e) {
                    //
                }
            },



            // Fill Departure Pie Chart
            fillDeparturePieChart(chart){
                // Reset Old Data
                this.departureAirportsPieChartData.data.datasets[0].data = [];
                this.departureAirportsPieChartData.data.labels = [];

                // Fill New Data
                let departureAirports = chart.popularDepartureAirports;
                departureAirports.sort(function (a, b) {
                    return b.count - a.count;
                });
                for(let i = 0; i < departureAirports.length; i++){
                    this.departureAirportsPieChartData.data.datasets[0].data.push(departureAirports[i].count);
                    this.departureAirportsPieChartData.data.labels.push(departureAirports[i].from);
                }

                try {
                    if(this.changed){
                        if(Object.entries(this.departureChart).length === 0 && this.departurePieChartCountComputed > 0){
                            let ptx = document.getElementById('departurePieChart')
                            this.departureChart = new Chart(ptx, this.departurePieChartComputed)
                        }
                        if(Object.entries(this.departureChart).length > 0){
                            this.departureChart.update();
                        }
                    } else{
                        if(this.departurePieChartCountComputed > 0){
                            let ptx = document.getElementById('departurePieChart')
                            this.departureChart = new Chart(ptx, this.departurePieChartComputed)
                        }
                    }
                } catch (e) {
                    //
                }
            },



            // Fill Departure Pie Chart
            fillDestinationPieChart(chart){
                // Reset Old Data
                this.destinationAirportsPieChartData.data.datasets[0].data = [];
                this.destinationAirportsPieChartData.data.labels = [];

                // Fill New Data
                let destinationAirports = chart.popularDestinationAirports;
                destinationAirports.sort(function (a, b) {
                    return b.count - a.count;
                });
                for(let i = 0; i < destinationAirports.length; i++){
                    this.destinationAirportsPieChartData.data.datasets[0].data.push(destinationAirports[i].count);
                    this.destinationAirportsPieChartData.data.labels.push(destinationAirports[i].to);
                }

                try {
                    if(this.changed){
                        if(Object.entries(this.destinationChart).length === 0 && this.destinationPieChartCountComputed > 0){
                            let ptx = document.getElementById('destinationPieChart')
                            this.destinationChart = new Chart(ptx, this.destinationPieChartComputed)
                        }
                        if(Object.entries(this.destinationChart).length > 0){
                            this.destinationChart.update();
                        }
                    } else{
                        if(this.destinationPieChartCountComputed > 0){
                            let ptx = document.getElementById('destinationPieChart')
                            this.destinationChart = new Chart(ptx, this.destinationPieChartComputed)
                        }
                    }
                } catch (e) {
                    //
                }
            },

        },


        computed:{
            geoPieChartComputed(){
                return this.geoPieChartData;
            },


            dailyStatsChartComputed(){
                return this.dailyStatsChartData;
            },


            tableComputed(){
                return this.tableData;
            },


            salePieChartComputed(){
                return this.salePieChartData;
            },


            salePieChartCountComputed(){
                return this.salePieChartData.data.datasets[0].data.length;
            },


            ticketsPieChartComputed(){
                return this.ticketsPieChartData;
            },


            departurePieChartComputed(){
                return this.departureAirportsPieChartData;
            },


            destinationPieChartComputed(){
                return this.destinationAirportsPieChartData;
            },


            ticketsPieChartCountComputed(){
                return this.ticketsPieChartData.data.datasets[0].data.length;
            },

            departurePieChartCountComputed(){
                return this.departureAirportsPieChartData.data.datasets[0].data.length;
            },

            destinationPieChartCountComputed(){
                return this.destinationAirportsPieChartData.data.datasets[0].data.length;
            },
        },



        watch:{
            //
        },
    }
</script>


<style>
    .stat__h3{
        margin-bottom: 10px;
    }

    .chart__child__parent{
        margin-top: 150px;
    }

    .stats__datepicker__wrapper div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .stats__datepicker__wrapper .el-input__inner{
        margin-right: 30px;
    }

    .stats__tickets-sale--pie-chart{
        display: flex;
        flex-direction: row;
    }

    .stats__tickets-sale--pie-chart__child{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
</style>
