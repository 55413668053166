<template>
  <div class="row">
    <div class="col-md-12">
      <el-table :data="geoData">

        <!-- Index -->
        <el-table-column min-width="50"
                         type="index"
                         label="#"
        ></el-table-column>
        <!-- /Index -->

        <!-- IP -->
        <el-table-column min-width="220"
                         prop="ip"
                         label="IP"
        >
        </el-table-column>
        <!-- /IP -->

        <!-- Searched Count -->
        <el-table-column min-width="130"
                         prop="search"
                         label="Search"
        >
        </el-table-column>
        <!-- /Searched Count -->

        <!-- Country -->
        <el-table-column min-width="200"
                         prop="country"
                         label="Country"
        >
        </el-table-column>
        <!-- /Country -->

        <!-- City -->
        <el-table-column min-width="200"
                         prop="city"
                         label="City"
        >
        </el-table-column>
        <!-- /City -->

        <!-- Timezone -->
        <el-table-column min-width="200"
                         prop="timezone"
                         label="Timezone"
        >
        </el-table-column>
        <!-- /Timezone -->

      </el-table>
    </div>
  </div>
</template>


<script>
  import {Table, TableColumn} from 'element-ui'
  import axios from 'axios'
  import Vue from 'vue'

  export default {
      name: 'GeoTable',


      components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
      },


      data(){
          return{
              geoData: []
          }
      },


      mounted() {
          this.getData();
      },

      methods:{
          getData(){
              axios.get( Vue.prototype.websiteURL + "admin/flight/" + "geo")
                  .then(res => {
                      res.data.geos.data.forEach(function (item) {
                          this.geoData.push({
                              'ip'      : item.ip,
                              'search'  : item.search,
                              'country' : item.country,
                              'city'    : item.city,
                              'lat'     : item.lat,
                              'lon'     : item.lon,
                              'timezone': item.timezone,
                          })
                      }.bind(this))
                  })
                  .catch(e => {
                      //
                  })
          }
      },
  }
</script>
