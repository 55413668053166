var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mytrip_operator_update"},[_c('div',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("mytrip_operator_update"),expression:"`mytrip_operator_update`"}],staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row operatorInput p-t-30"},[_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("mytrip_operator_update_name"),expression:"`mytrip_operator_update_name`"}],attrs:{"type":"text","label":"Operator name","placeholder":"Operator Name"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_information"
                            ),expression:"\n                                `mytrip_operator_update_information`\n                            "}],attrs:{"type":"text","label":"Operator Information","placeholder":"Operator Information"},model:{value:(_vm.data.information),callback:function ($$v) {_vm.$set(_vm.data, "information", $$v)},expression:"data.information"}})],1),_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_email"
                            ),expression:"\n                                `mytrip_operator_update_email`\n                            "}],attrs:{"type":"text","label":"Operator Email","placeholder":"operator Email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1)]),_c('div',{staticClass:"row operatorInput p-t-30"},[_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_Phone"
                            ),expression:"\n                                `mytrip_operator_update_Phone`\n                            "}],attrs:{"type":"text","label":"Phone","placeholder":"Phone"},model:{value:(_vm.data.phone1),callback:function ($$v) {_vm.$set(_vm.data, "phone1", $$v)},expression:"data.phone1"}})],1),_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_Phone2"
                            ),expression:"\n                                `mytrip_operator_update_Phone2`\n                            "}],attrs:{"type":"text","label":"Phone","placeholder":"Phone"},model:{value:(_vm.data.phone2),callback:function ($$v) {_vm.$set(_vm.data, "phone2", $$v)},expression:"data.phone2"}})],1),_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("mytrip_operator_update_url"),expression:"`mytrip_operator_update_url`"}],attrs:{"type":"text","label":"URL","placeholder":"Enter Url"},model:{value:(_vm.data.url),callback:function ($$v) {_vm.$set(_vm.data, "url", $$v)},expression:"data.url"}})],1)]),_c('div',{staticClass:"row operatorInput p-t-30"},[_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_socialLinks"
                            ),expression:"\n                                `mytrip_operator_update_socialLinks`\n                            "}],attrs:{"type":"text","label":"Facebook","placeholder":"facebook"},model:{value:(_vm.data.facebook),callback:function ($$v) {_vm.$set(_vm.data, "facebook", $$v)},expression:"data.facebook"}})],1),_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_socialLinks"
                            ),expression:"\n                                `mytrip_operator_update_socialLinks`\n                            "}],attrs:{"type":"text","label":"Twitter","placeholder":"twitter"},model:{value:(_vm.data.twitter),callback:function ($$v) {_vm.$set(_vm.data, "twitter", $$v)},expression:"data.twitter"}})],1),_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_socialLinks"
                            ),expression:"\n                                `mytrip_operator_update_socialLinks`\n                            "}],attrs:{"type":"text","label":"Linkedin","placeholder":"linkedin"},model:{value:(_vm.data.linkedin),callback:function ($$v) {_vm.$set(_vm.data, "linkedin", $$v)},expression:"data.linkedin"}})],1)]),_c('div',{staticClass:"row operatorInput p-t-30"},[_c('div',{staticClass:"col-8"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_address"
                            ),expression:"\n                                `mytrip_operator_update_address`\n                            "}],attrs:{"type":"text","label":"Address","placeholder":"Address"},model:{value:(_vm.data.address),callback:function ($$v) {_vm.$set(_vm.data, "address", $$v)},expression:"data.address"}})],1),_c('div',{staticClass:"col-4"},[_c('base-input',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_socialLinks"
                            ),expression:"\n                                `mytrip_operator_update_socialLinks`\n                            "}],attrs:{"type":"text","label":"Instagram","placeholder":"instagram"},model:{value:(_vm.data.instagram),callback:function ($$v) {_vm.$set(_vm.data, "instagram", $$v)},expression:"data.instagram"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center p-3"},[_c('image-upload',{directives:[{name:"has-permission",rawName:"v-has-permission",value:(
                                "mytrip_operator_update_image"
                            ),expression:"\n                                `mytrip_operator_update_image`\n                            "}],attrs:{"select-text":"Select Image"},on:{"change":_vm.onImageChange}})],1)]),_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn btn-behance m-auto",on:{"click":_vm.update}},[_vm._v(" Update Operator ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }