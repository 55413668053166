<template>
    <div>
        <h1>Reserved Flight</h1>
        <reserved-flight-table></reserved-flight-table>
    </div>
</template>


<script>
    import ReservedFlightTable from "src/components/Flight/Reserved/ReservedFlightTable";

    export default {
        name: 'ReservedFlightIndex',
        components: {
            ReservedFlightTable
        },
    }
</script>
