<template>
  <div class="row mytrip_operators">
    <operator-table></operator-table>
  </div>
</template>
<script>
    import operatorTable from 'src/components/Mytrip/OperatorsTable.vue';
    export default {
        components: {
            operatorTable
        },
        data() {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
        mounted() {

        },
        beforeDestroy() {

        }
    };
</script>

<style></style>
