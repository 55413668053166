<template>
    <div class="review_dashboard">
        <answer-table></answer-table>
    </div>
</template>
<script>
import answerTable from "src/components/Review/AnswerDashboardTable.vue";
export default {
    components: {
        answerTable
    },
    data() {
        return {};
    },
    methods: {}
};
</script>
