<template>
    <modal :show.sync="modal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">
            <template>
                <div class="text-center text-muted mb-4">
                    <small>Please complete form</small>
                </div>
                <!-- Company -->
                <base-input label="Company">
                    <select class="form-control" id="exampleFormControlSelect1" v-model="companyData.company">
                        <option :value="company.id" v-for="company in companies">{{ company.name }}</option>
                    </select>
                </base-input>
                <!-- /Company -->
                <!-- Rates -->
                <form role="form">
                    <base-input label="Domestic EUR" v-model="companyData.domestic">
                    </base-input>
                    <base-input label="International EUR" v-model="companyData.international">
                    </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="updateRate()">Update</base-button>
                    </div>
                </form>
                <!-- /Rates -->
            </template>
        </card>
    </modal>
</template>


<script>
    import {BaseButton, Modal} from 'src/components'
    import {DatePicker, TimeSelect} from 'element-ui'
    import axios from 'axios';
    import Vue from 'vue';

    export default {
        name: 'UpdateAgentsModal',


        components: {
            BaseButton,
            Modal,
        },


        props:[
            'modalStatus'
        ],


        data(){
            return{
                companyData: {
                    domestic: null,
                    international: null,
                    company: null,
                },
                companies: [],
            }
        },


        mounted(){
            this.$root.$on('get-companies', function (value) {
                this.companies = value;
            }.bind(this));
        },


        methods:{
        updateRate(){
                let data = {
                    'domestic'      : this.companyData.domestic,
                    'international' : this.companyData.international,
                    'company'       : this.companyData.company,
                }
                axios.post( Vue.prototype.websiteURL + "admin/flight/" + "update-rate", data )
                    .then(res => {
                        this.$emit('closemodal');
                        if(res.data.status)
                            this.$notify({type: 'success', message: 'Data updated successfully!'})
                        else
                            this.$notify({type: 'danger', message: 'Occurred some error!'})
                    })
                    .catch(e => {
                        this.$notify({type: 'danger', message: 'Occurred some error!'})
                    })
            },
        },


        computed: {
            modal:{
                get(){
                    return this.modalStatus
                },

                set(value){
                    this.$emit('closemodal')
                }
            }
        }

    }
</script>