<template>
    <div>
        <div v-has-permission="`review`">
            <div class="col-12">
                <card card-body-classes="table-full-width">
                    <div>
                        <!-- TABLE SHOW DETAILS -->
                        <el-table
                            style="width: 100%"
                            :data="arrayData"
                            class="center-header-table"
                        >
                            <el-table-column
                                v-for="column in tableColumns"
                                :key="column.label"
                                :min-width="column.minWidth"
                                :prop="column.prop"
                                :label="column.label"
                            >
                            </el-table-column>
                            <!-- BUTTON FOR READ MORE -->
                            <el-table-column min-width="135" label="Status">
                                <div slot-scope="props" class="text-center">
                                    <button
                                        v-has-permission="`review`"
                                        type="info"
                                        class="animation-on-hover btn btn-info"
                                        style="padding: 5px; font-size: 12px"
                                        @click="changeStatus(props.row.id)"
                                        v-if="props.row.status == 'deactive'"
                                    >
                                        Active Review
                                    </button>
                                    <p
                                        v-if="props.row.status == 'active'"
                                        class="btn btn-warning"
                                        disabled
                                        style="padding: 5px; font-size: 12px"
                                    >
                                        Active
                                    </p>
                                </div>
                            </el-table-column>
                            <!-- END BUTTON FOR READ MORE -->
                        </el-table>
                        <!-- END TABLE SHOW DETAILS -->
                    </div>
                    <div class="pagination_number">
                        <pagination
                            :data="tableData"
                            @pagination-change-page="getResults"
                            :limit="3"
                        ></pagination>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import laravel_vue_pagination from "laravel-vue-pagination";
import axios from "axios";
import LineChart from "@/components/Charts/LineChart";
import config from "@/config";
import * as chartConfigs from "@/components/Charts/config";
let monthChartData = [];
let monthChartDatasetOptions = {
    fill: true,
    borderColor: config.colors.primary,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: config.colors.primary,
    pointBorderColor: "rgba(255,255,255,0)",
    pointHoverBackgroundColor: config.colors.primary,
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 4
};
Vue.component("pagination", laravel_vue_pagination);

export default {
    components: {
        BasePagination,
        LineChart,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            tableData: {},
            tableColumns: [
                {
                    prop: "review.title",
                    label: "review title",
                    minWidth: 250
                },
                {
                    prop: "review.body",
                    label: "review body",
                    minWidth: 350
                },
                {
                    prop: "title",
                    label: "answer title",
                    minWidth: 250
                },
                {
                    prop: "body",
                    label: "answer body",
                    minWidth: 350
                }
            ],
            arrayData: [],
            searchQuery: ""
        };
    },
    mounted() {
        this.getResults();
    },
    methods: {
        /**
         * get values and data page by page
         **/
        getResults(page = 1) {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            axios
                .get(
                    Vue.prototype.websiteURL +
                        "admin/review/answer?page=" +
                        page,
                    headers
                )
                .then(response => {
                    this.tableData = response.data.data;
                    this.arrayData = this.tableData.data;
                });
        },
        changeStatus(id) {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            let data = {
                id: id
            };
            axios
                .post(
                    Vue.prototype.websiteURL +
                        "admin/review/update-answer-status",
                    data,
                    headers
                )
                .then(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                    this.getResults();
                });
        },
        notifyVue(verticalAlign, horizontalAlign, status, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: status
            });
        }
    }
};
</script>

<style>
.center-header-table td,
.center-header-table th {
    text-align: center !important;
}
</style>
