<template>
    <div class="content" v-has-permission = "`mytrip_operator_formList`">
      <div class="row">
        <div class="col-12">
          <card card-body-classes="table-full-width">
            <div>
              <!-- CREATE NEW OPERATOR BUTTON -->
              <div class="col-6 d-flex justify-content-center justify-content-sm-between flex-wrap float-right"
                   style="text-align: right;display: block;width: 100%;"
                   v-has-permission = "`mytrip_operator_create`"
              >
                <base-input style="width: 100%;">
                  <router-link
                    :to="{ name: 'Create Operator'}"
                    type="success"
                    class="animation-on-hover btn btn-behance"
                    style="padding: 12px; font-size: 14px"
                  >Create New Operator
                  </router-link>
                </base-input>
              </div>
              <!-- END CREATE NEW OPERATOR BUTTON -->

              <!-- OPERATOR LIST -->
              <el-table :data="arrayData">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column :min-width="135" align="right" label="Actions">
                  <div slot-scope="props">

                    <!-- OPERATOR STATUS STATE -->
                    <base-button
                      v-if="props.row.status !== 'YES'"
                      @click.native="handleStatus(props.$index, props.row)"
                      class="add btn-link"
                      style="color: #ec250d"
                      type="danger"
                      size="sm"
                      icon
                      v-has-permission = "`mytrip_status`"
                    >
                      <i class="tim-icons icon-alert-circle-exc"></i>
                    </base-button>
                    <!-- END OPERATOR STATUS STATE -->

                    <!-- EDIT OPERATOR -->
                    <router-link
                      v-if="props.row.status === 'YES'"
                      :to="{ name: 'Edit Operator' , params: { data: props.row } }"
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                      v-has-permission = "`mytrip_operator_update`"
                    >
                      <i class="tim-icons icon-pencil" style="color: green"></i>
                    </router-link>
                    <!-- END EDIT OPERATOR -->

                    <!-- REMOVE OPERATOR -->
                    <base-button
                      v-if="props.row.status === 'YES'"
                      @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                      v-has-permission = "`mytrip_operator_delete`"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                    <!-- END REMOVE OPERATOR -->

                  </div>
                </el-table-column>
              </el-table>
              <!-- END OPERATOR LIST -->

            </div>
            <div class="pagination_number">
              <pagination :data="tableData" @pagination-change-page="getResults" :limit="5"></pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
</template>
<script>

    import Vue from 'vue';
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import { BasePagination } from 'src/components';
    import swal from 'sweetalert2';
    import laravel_vue_pagination from 'laravel-vue-pagination';
    import axios from 'axios';
    Vue.component('pagination', laravel_vue_pagination);
    export default {
        components: {
            BasePagination,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        data() {
            return {
                tableData :{},
                tableColumns: [
                    {
                        prop: 'name',
                        label: 'Name',
                        minWidth: 150
                    },
                    {
                        prop: 'email',
                        label: 'Email',
                        minWidth: 250
                    },
                    {
                        prop: 'phone1',
                        label: 'Phone',
                        minWidth: 120
                    },
                    {
                        prop: 'user.name',
                        label: 'Update By',
                        minWidth: 100
                    },
                    {
                        prop: 'updated_at',
                        label: 'Updated At',
                        minWidth: 100
                    }
                ],
                arrayData :[],
                searchQuery : ""
            };
        },
        mounted() {
            this.getResults();
        },
        methods: {
            /**
             * get result page by page
            **/
            getResults(page = 1 ){
                let data = {
                    'page'  : page
                };
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/operatorData/get-operators', data, User.prototype.getHeader())
                    .then((response)=>{
                         this.tableData = response.data.data;
                         this.arrayData = this.tableData.data;
                    })
            },
            /**
             * delete a operator
             * for delete call deleteRow method
            **/
            handleDelete(index, row) {
                swal({
                    title: 'Are you sure?',
                    text: `You won't be able to revert this!`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: 'Yes, delete it!',
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        this.deleteRow(index,row);
                    }
                });
            },
            /**
             * axios.post for delete a operator
            **/
            deleteRow(index,row) {

                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/operatorData/delete/delete-operator', {'id' : row.id} , User.prototype.getHeader())
                    .then((response)=>{
                        let indexToDelete = this.tableData.data.findIndex(tableRow => tableRow.id === row.id);
                        if (indexToDelete >= 0) {this.tableData.data.splice(indexToDelete, 1);}
                        this.notifyVue('top','right','success','The operation you requested was successful');
                    })
                    .catch(response =>{
                        this.notifyVue('top','right','warning','The operation you requested did not succeed');
                    });

            },
            notifyVue(verticalAlign , horizontalAlign , status , message) {

                this.$notify({
                    message: message,
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: horizontalAlign,
                    verticalAlign: verticalAlign,
                    type: status
                });

            },
            /**
             * handle statuses
            *\*/
            handleStatus(index,row){
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/operatorData/update/update-operator-status', {'id' : row.id} , User.prototype.getHeader())
                    .then((response)=>{
                        let updateIndex = this.tableData.data.findIndex(tableRow => tableRow.id === row.id);
                        this.tableData.data[updateIndex].status = "YES";
                        this.notifyVue('top','right','success','The operation you requested was successful');
                    })
                    .catch(response =>{
                        this.notifyVue('top','right','warning','The operation you requested did not succeed');
                    });
            }
        },
    };
</script>

<style>
  .mytrip_operators td,.mytrip_operators th{
    text-align: center !important;
  }
</style>
