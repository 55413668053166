<template>
    <div class="row" v-has-permission="`review`">
        <div class="col-12">
            <div class="card">
                <div class="row">
                    <div v-has-permission="`review`" class=" col-12">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item card-header">
                                <h4 class="text-bold card-title">
                                    Answers:
                                </h4>
                                <div class="row">
                                    <div
                                        v-for="answer in data.answers"
                                        :key="answer.title"
                                        class="list-inline border-bottom-1 card-body answer-card col-sm-12 col-md-12 col-lg-12 col-md-12 col-xl-12 col-xs-12"
                                    >
                                        <div class="mb-3">
                                            <h4>Title:</h4>
                                            {{ answer.title }}
                                        </div>
                                        <div>
                                            <h4>Body:</h4>
                                            {{ answer.body }}
                                        </div>
                                        <div
                                            v-show="answer.status == 'deactive'"
                                        >
                                            <button
                                                class="btn btn-behance m-auto right-float"
                                                @click="changeStatus(answer.id)"
                                            >
                                                active answer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import { Modal, BaseAlert } from "src/components";

export default {
    components: {
        Modal,
        BaseAlert
    },
    data() {
        return {
            id: "",
            data: {
                id: null,
                name: null,
                email: null,
                evaluation: null,
                evaluation_type: null,
                title: null,
                body: null,
                group_type: null,
                recommended: null,
                pricing: null,
                answers: [],
                hashtags: [],
                options: {
                    origin: null,
                    object: null,
                    partner: null,
                    arrival: null,
                    departure: null,
                    nights: null,
                    city: null,
                    phone: null,
                    country: null,
                    accommodation: null,
                    adults: null,
                    children: null,
                    images: []
                },
                status: null,
                like_count: 0,
                dislike_count: 0
            }
        };
    },
    mounted() {
        this.values();
    },
    methods: {
        values() {
            this.id = this.$route.params.id;
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            let data = {
                id: this.id
            };
            axios
                .post(
                    Vue.prototype.websiteURL + "admin/review/show",
                    data,
                    headers
                )
                .then(response => {
                    this.data = response.data.data;
                });
        },
        changeStatus(id) {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            let data = {
                id: id
            };
            axios
                .post(
                    Vue.prototype.websiteURL +
                        "admin/review/update-answer-status",
                    data,
                    headers
                )
                .then(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                    this.$router.push({ name: "Review Form" });
                });
        },
        notifyVue(verticalAlign, horizontalAlign, status, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: status
            });
        }
    }
};
</script>

<style>
.Agency ul.list-group.list-group-flush,
.person-travel-data ul.list-group.list-group-flush,
.Extra_Services ul.list-group.list-group-flush,
.Message ul.list-group.list-group-flush,
.url ul.list-group.list-group-flush {
    background: rgba(130, 130, 130, 00.1);
}
.m-t-b-3 {
    margin: 30px auto !important;
}
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    padding: 14px;
}

.card .alert {
    position: relative !important;
    width: 100%;
}
.color-red {
    color: red !important;
}
.color-silver {
    color: silver !important;
}

.answer-card div button {
    margin-top: 25px !important;
}
.border-bottom-1 {
    border-bottom: solid 1px silver;
}
.right-float {
    float: right !important;
}
</style>
