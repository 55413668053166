<template>
    <div>
        <div v-has-permission="`review`">
            <div class="col-12">
                <card card-body-classes="table-full-width">
                    <div>
                        <!-- TABLE SHOW DETAILS -->
                        <el-table
                            style="width: 100%"
                            :data="arrayData"
                            class="center-header-table"
                        >
                            <el-table-column
                                v-for="column in tableColumns"
                                :key="column.label"
                                :min-width="column.minWidth"
                                :prop="column.prop"
                                :label="column.label"
                            >
                            </el-table-column>
                            <!-- BUTTON FOR Read Answers -->
                            <el-table-column min-width="135" label="Answers">
                                <div
                                    slot-scope="props"
                                    v-show="props.row.answers.length != 0"
                                >
                                    <router-link
                                        v-has-permission="`review`"
                                        :to="{
                                            path:
                                                `/reviews/answer/` +
                                                props.row.id,
                                            params: props.row
                                        }"
                                        type="info"
                                        class="animation-on-hover btn btn-info"
                                        style="padding: 5px; font-size: 12px"
                                        >Read Answers
                                    </router-link>
                                </div>
                            </el-table-column>
                            <!-- END BUTTON FOR Read Answers -->
                            <!-- BUTTON FOR READ MORE -->
                            <el-table-column min-width="135" label="Actions">
                                <div slot-scope="props">
                                    <router-link
                                        v-has-permission="`review`"
                                        v-show="
                                            props.row.reviewable_type ===
                                                'App\\Entities\\Hotel\\HotelDetails'
                                        "
                                        :to="{
                                            path:
                                                `/reviews/hotel/` +
                                                props.row.id,
                                            params: props.row
                                        }"
                                        type="success"
                                        class="animation-on-hover btn btn-behance"
                                        style="padding: 12px; font-size: 14px"
                                        >Read More
                                    </router-link>
                                </div>
                            </el-table-column>
                            <!-- END BUTTON FOR READ MORE -->
                        </el-table>
                        <!-- END TABLE SHOW DETAILS -->
                    </div>
                    <div class="pagination_number">
                        <pagination
                            :data="tableData"
                            @pagination-change-page="getResults"
                            :limit="3"
                        ></pagination>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import laravel_vue_pagination from "laravel-vue-pagination";
import axios from "axios";
import LineChart from "@/components/Charts/LineChart";
import config from "@/config";
import * as chartConfigs from "@/components/Charts/config";
let monthChartData = [];
let monthChartDatasetOptions = {
    fill: true,
    borderColor: config.colors.primary,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: config.colors.primary,
    pointBorderColor: "rgba(255,255,255,0)",
    pointHoverBackgroundColor: config.colors.primary,
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 4
};
Vue.component("pagination", laravel_vue_pagination);

export default {
    components: {
        BasePagination,
        LineChart,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            tableData: {},
            tableColumns: [
                {
                    prop: "name",
                    label: "name",
                    minWidth: 200
                },
                {
                    prop: "email",
                    label: "email",
                    minWidth: 250
                },
                {
                    prop: "evaluation",
                    label: "evaluation",
                    minWidth: 150
                },
                {
                    prop: "group_type",
                    label: "group_type",
                    minWidth: 150
                },
                {
                    prop: "recommended",
                    label: "recommended",
                    minWidth: 150
                },
                {
                    prop: "pricing",
                    label: "pricing",
                    minWidth: 150
                },
                {
                    prop: "status",
                    label: "status",
                    minWidth: 150
                },
                {
                    prop: "title",
                    label: "title",
                    minWidth: 250
                }
            ],
            arrayData: [],
            searchQuery: ""
        };
    },
    watch: {
        searchQuery() {
            if (!!this.searchQuery.length) {
                this.search(this.searchQuery);
            } else {
                this.getResults(1);
            }
        }
    },
    mounted() {
        this.getResults();
    },
    methods: {
        /**
         * get values and data page by page
         **/
        getResults(page = 1) {
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            axios
                .get(
                    Vue.prototype.websiteURL + "admin/review?page=" + page,
                    headers
                )
                .then(response => {
                    this.tableData = response.data.data;
                    this.arrayData = this.tableData.data;
                });
        }
    }
};
</script>

<style>
.center-header-table td,
.center-header-table th {
    text-align: center !important;
}
</style>
