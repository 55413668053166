<template>
    <div class="review_dashboard">
        <tag-table></tag-table>
    </div>
</template>
<script>
import tagTable from "src/components/Hashtag/DashboardTable.vue";
export default {
    components: {
        tagTable
    },
    data() {
        return {};
    },
    methods: {}
};
</script>
