<template>
    <div>
        <h1>Stats</h1>
        <stats-charts></stats-charts>
    </div>
</template>

<script>
    import StatsCharts from "src/components/Flight/Stats/StatsCharts";

    export default {
        name: 'StatsIndex',
        components: {
            StatsCharts,
        },
    }
</script>
