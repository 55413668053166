<template>
    <div
        class="row mytrip_things_create"
        v-has-permission="`mytrip_operator_create`"
    >
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row thingInputs p-t-30">
                        <div class="col-12">
                            <div>Tag Name:</div>
                            <base-input
                                type="text"
                                placeholder="Name"
                                v-model="name"
                            >
                            </base-input>
                        </div>
                        <div class="col-12">
                            <div>
                                <div>Service:</div>
                                <el-select
                                    class="select-primary w-100"
                                    collapse-tags
                                    v-model="service"
                                    placeholder="Multiple Select"
                                >
                                    <el-option
                                        v-for="option in services"
                                        class="select-primary"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.value"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <button class="btn btn-behance m-auto" @click="create">
                            Create New Tag
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import { BaseAlert } from "src/components";
import { Select, Option } from "element-ui";

export default {
    components: {
        BaseAlert,
        [Select.name]: Select,
        [Option.name]: Option
    },
    data() {
        return {
            name: "",
            service: "",
            services: [
                {
                    label: "Hotel",
                    value: "Hotel"
                },
                {
                    label: "Flight",
                    value: "Flight"
                },
                {
                    label: "Visa",
                    value: "Visa"
                },
                {
                    label: "Tour",
                    value: "Tour"
                },
                {
                    label: "Bus",
                    value: "Bus"
                },
                {
                    label: "Trasnfer",
                    value: "Trasnfer"
                },
                {
                    label: "Apochi",
                    value: "Apochi"
                }
            ]
        };
    },
    computed: {},
    methods: {
        create() {
            let formData = {
                name: this.name,
                service: this.service
            };
            let headers = {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " +
                        window.localStorage.getItem("Apochi_accessToken")
                }
            };
            axios
                .post(
                    Vue.prototype.websiteURL + "admin/hashtag",
                    formData,
                    headers
                )
                .then(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                    this.$router.push({ name: "Hashtag Form" });
                })
                .catch(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "warning",
                        "The operation you requested did not succeed"
                    );
                });
        },
        notifyVue(verticalAlign, horizontalAlign, status, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: status
            });
        }
    },
    mounted() {}
};
</script>

<style>
.thingInputs {
    padding: 0 50px;
}
.thingInputs div.col-4 {
    margin: 10px auto !important;
}
.thingInputs div.col-4 div {
    width: 100%;
}
.thingInputs div.col-8 {
    margin: 10px auto !important;
}
.thingInputs div.col-8 div {
    width: 100%;
}
.thingInputs .form-control {
    border-radius: 3px;
    margin-top: 1px;
}
.mytrip_things_create input {
    font-size: 13px;
}
.p-t-30 {
    padding-top: 30px;
}
</style>
