<template>
  <!-- AGENCY TABLE FOR SHOW AGENCIES -->
  <div class="mytrip_agencies">
    <agency-table></agency-table>
  </div>
  <!-- END AGENCY TABLE FOR SHOW AGENCIES -->

</template>
<script>
    import agencyTable from 'src/components/Mytrip/AgenciesTable.vue';
    export default {
        components: {
            agencyTable
        },
        data() {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
        mounted() {

        },
        beforeDestroy() {

        }
    };
</script>

<style></style>
