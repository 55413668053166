<template>
  <div class="row mytrip_operators">
    <emails-table></emails-table>
  </div>
</template>
<script>
    import emailsTable from 'src/components/Mytrip/EmailsTable.vue';
    export default {
        components: {
            emailsTable
        },
        data() {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
        mounted() {

        },
        beforeDestroy() {

        }
    };
</script>

<style></style>
