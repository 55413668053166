<template>
    <div>
        <div class="d-flex flex-row justify-content-between align-items-center mb-4">
            <!-- Page Title -->
            <h1 class="mb-0">UTMs</h1>
            <!-- /Page Title -->

            <!-- Filter Results -->
            <div>
                <base-button type="info" size="sm" @click="filterModalStatus=true">Filter</base-button>
                <base-button type="info" size="sm" @click="sourcesModalStatus=true">Sources</base-button>
            </div>
            <!-- /Filter Results -->

            <!-- Filter Booked Modal -->
            <filter-u-t-m-modal :filterModalStatus="filterModalStatus" v-on:closemodal="filterModalStatus=false"></filter-u-t-m-modal>
            <sources-u-t-m-modal :sourcesModalStatus="sourcesModalStatus" v-on:closemodal="sourcesModalStatus=false"></sources-u-t-m-modal>
            <!-- /Filter Booked Modal -->
        </div>

        <u-t-m-table></u-t-m-table>
    </div>
</template>


<script>
    import UTMTable from 'src/components/Flight/UTM/UTMTable';
    import FilterUTMModal from "../../../components/Flight/UTM/FilterUTMModal";
    import SourcesUTMModal from "../../../components/Flight/UTM/SourcesUTMModal";
    import store from 'src/store'

    export default {
        name: 'UTMIndex',

        data(){
            return{
                filterModalStatus: false,
                sourcesModalStatus: false,
            }
        },

        components:{
            SourcesUTMModal,
            FilterUTMModal,
            UTMTable
        },


        methods:{
            sourcesOverviewToggle(){
                console.log('Sources: ', store.getters.getSourcesCounter)
                console.log('UTMs: ', store.getters.getUTMItems)
            },
        },
    }
</script>