<template>
    <div class="row">
        <div class="col-12">
            <el-table :data="travelersInfo">
                <el-table-column min-width="50"
                                 type="index">
                </el-table-column>
                <el-table-column min-width="150"
                                 prop="firstName"
                                 label="First Name">
                </el-table-column>
                <el-table-column min-width="200"
                                 prop="lastName"
                                 label="Last Name">
                </el-table-column>
                <el-table-column min-width="150"
                                 prop="gender"
                                 label="Gender">
                </el-table-column>
                <el-table-column min-width="150"
                                 prop="birthday"
                                 label="Birthday">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>


<script>
    import {Table, TableColumn} from 'element-ui';
    import axios from 'axios';
    import Vue from 'vue';

    export default {
        name: 'TravelersInfoSingle',

        components: {
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },


        data(){
            return{
                uniID: null,
                travelersInfo: [],
            }
        },



        mounted(){
            this.getUniID();
            this.getData();
        },


        methods:{
            getUniID(){
                this.uniID = this.$route.params.id;
            },


            getData(){
                axios.get( Vue.prototype.websiteURL + "admin/flight/" + "traveler-info/" + this.uniID)
                    .then(res => {
                        if(res.data.status == 'success'){
                            res.data.traveler.forEach(function (item) {
                                this.travelersInfo.push({
                                    'firstName' : item.firstName,
                                    'lastName'  : item.lastName,
                                    'gender'    : item.gender,
                                    'birthday'  : item.birthday,
                                })
                            }.bind(this))
                        }
                    })
                    .catch(e => {
                        //
                    })
            },
        }
    }
</script>