<template>
  <div class="row mytrip_things_update" v-has-permission="`mytrip_things_update`">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row thingInputs p-t-50">
            <div class="col-4"  v-has-permission="`mytrip_things_update_title`">
              <base-input
                type="text"
                placeholder="Enter Title"
                v-model="data.title"
              >
              </base-input>
            </div>
            <div class="col-4"  v-has-permission="`mytrip_things_update_categiry`">
              <el-select
                class="select-primary"
                size="large"
                placeholder="Select Group Type"
                v-model="data.groupType"
              >
                <el-option
                  v-for="option in groupType.countries"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-4"  v-has-permission="`mytrip_things_update_power`">
              <el-select
                class="select-primary"
                size="large"
                placeholder="Select a Position"
                v-model="data.power"
              >
                <el-option
                  v-for="option in power.countries"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row"  v-has-permission="`mytrip_things_update_image`">
            <div class="col-12 text-center p-3">
              <image-upload @change="onImageChange" select-text="Select Image" />
            </div>
          </div>
          <div class="row">
            <button class="btn btn-behance m-auto" @click="update">Update Thing</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import axios from 'axios';
    import Vue from 'vue';
    import { Modal, BaseAlert } from 'src/components';
    import { Select, Option } from 'element-ui';
    import { ImageUpload } from 'src/components/index';
    export default {
        components: {
            Modal,
            BaseAlert,
            [Option.name]: Option,
            [Select.name]: Select,
            ImageUpload,
        },
        data() {
            return {
                groupType: {
                    simple: '',
                    countries: [
                        { value: 'Things', label: 'Things To Do' },
                        { value: 'Places', label: 'Places To Go' },
                        { value: 'Hotel', label: 'Hotel Preference' },
                    ],
                },
                power: {
                    simple: '',
                    countries: [
                        { value: '0', label: '0' },
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                        { value: '4', label: '4' },
                        { value: '5', label: '5' },
                        { value: '6', label: '6' },
                        { value: '7', label: '7' },
                        { value: '8', label: '8' },
                        { value: '9', label: '9' },
                        { value: '10', label: '10' },
                    ],
                },
                data :{
                    ArticleID : "0",
                    title : "",
                    power : "",
                    groupType : "",
                    image : null
                },
                fullInformation:{}
            };
        },
        computed: {

        },
        methods: {
            update(){
                let formData = this.getData();
                let headers ={
                    headers : {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
                        'Content-Type' : "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
                    }
                };
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/articles/update/update-article' , formData , headers)
                    .then(response => {
                        this.notifyVue('top','right','success','The operation you requested was successful');
                        this.$router.push({ name: "Things"});
                    })
                    .catch(response =>{
                        this.notifyVue('top','right','warning','The operation you requested did not succeed');
                    });
            },
            notifyVue(verticalAlign , horizontalAlign , status , message) {
                this.$notify({
                    message: message,
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: horizontalAlign,
                    verticalAlign: verticalAlign,
                    type: status
                });
            },
            getData(){
                let formData = new FormData();
                formData.append('image', this.data.image);
                formData.append('title', this.data.title);
                formData.append('groupType', this.data.groupType);
                formData.append('power', this.data.power);
                formData.append('id', this.data.ArticleID);
                return formData;
            },
            onImageChange(file) {
                this.data.image = file;
            },
            replaceData(){
                this.fullInformation = this.$route.params.data;
                this.data.power = this.fullInformation.power;
                this.data.groupType = this.fullInformation.groupType;
                this.data.title = this.fullInformation.title;
                this.data.image = this.fullInformation.image;
                this.data.ArticleID = this.fullInformation.id;
            }
        },
        mounted() {
            this.replaceData();
        }
    };
</script>

<style>
  .thingInputs{
    padding: 0 50px;
  }
  .thingInputs div.col-4{
    margin: 10px auto !important;
  }
  .thingInputs div.col-4 div{
    width: 100%;
  }
  .thingInputs .form-control{
    border-radius: 3px;
    margin-top: 1px;
  }
  .mytrip_things_update input{
    font-size: 13px;
  }
  .p-t-50{
    padding-top: 50px;
  }
</style>
