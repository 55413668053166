<template>
  <div class="mytrip_dashboard">
    <mytrip-table></mytrip-table>
  </div>
</template>
<script>
    import mytripTable from 'src/components/Mytrip/DashboardTable';
    export default {
        components: {
            mytripTable
        },
        data() {
            return {

            };
        },
        methods: {

        }
    };
</script>
