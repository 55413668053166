<template>
    <modal :show.sync="modal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">
            <template>
                <div class="text-center text-muted mb-4">
                    <small>Please complete form</small>
                </div>
                <form role="form">
                    <base-input label="Start Date">
                        <el-date-picker v-model="filterData.from"
                                        type="date"
                                        placeholder="Start Date">
                        </el-date-picker>
                    </base-input>
                    <base-input label="End Date">
                        <el-date-picker v-model="filterData.to"
                                        type="date"
                                        placeholder="End Date">
                        </el-date-picker>
                    </base-input>
                    <base-input label="Company">
                        <select class="form-control" id="exampleFormControlSelect1" v-model="filterData.company">
                            <option value="0" selected>All</option>
                            <option :value="company.id" v-for="company in companies">{{ company.name }}</option>
                        </select>
                    </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="submitDiscount(false)">Filter</base-button>
                    </div>
                </form>
            </template>
        </card>
    </modal>
</template>


<script>
    import {BaseButton, Modal} from 'src/components'
    import {DatePicker, TimeSelect} from 'element-ui'
    import axios from 'axios';
    import Vue from 'vue';

    export default {
        name: 'FilterBookedFlightModal',


        components: {
            BaseButton,
            Modal,
            [DatePicker.name]: DatePicker,
            [TimeSelect.name]: TimeSelect,
        },


        props:[
            'modalStatus'
        ],


        data(){
            return{
                filterData: {
                    from: null,
                    to: null,
                    company: null,
                },
                companies: [],
            }
        },


        mounted(){
            this.$root.$on('get-companies', function (value) {
                this.companies = value;
            }.bind(this));
        },


        methods:{
            submitDiscount(excel = false){
                let data = {
                    'mode'   : 'booked',
                    'from'   : this.filterData.from,
                    'to'     : this.filterData.to,
                    'company': this.filterData.company,
                    'excel'  : excel,
                }
                axios.post( Vue.prototype.websiteURL + "admin/flight/" + "filter-export-reports", data )
                    .then(res => {
                        this.$emit('closemodal');
                        this.$root.$emit('filter-results', res.data);
                        this.$notify({type: 'success', message: 'Data was refreshed!'})
                    })
                    .catch(e => {
                        this.$notify({type: 'danger', message: 'The given data is invalid!'})
                    })
            },
        },


        computed: {
            modal:{
                get(){
                    return this.modalStatus
                },

                set(value){
                    this.$emit('closemodal')
                }
            }
        }

    }
</script>