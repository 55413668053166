<template>
    <div class="content" v-has-permission = "`mytrip_email`">
      <!-- EMAIL LIST  -->
      <div class="row">
        <div class="col-12">
          <card card-body-classes="table-full-width">

            <!-- TABLE -->
            <div>
              <el-table :data="arrayData">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>

                <!-- READ EMAIL BUTTON -->
                <el-table-column :min-width="135" align="right" label="Actions">
                  <div slot-scope="props">
                    <base-button
                      @click.native="handleRead(props.$index, props.row)"
                      class="add btn-link"
                      style="color: #ec250d;font-size: 2em"
                      type="danger"
                      icon
                      v-has-permission = "`mytrip_email_read`"
                    >
                      <i class="tim-icons icon-email-85"></i>
                    </base-button>

                  </div>
                </el-table-column>
                <!-- END READ EMAIL BUTTON -->

              </el-table>
            </div>
            <!-- END TABLE -->

            <!-- PAGINATION -->
            <div class="pagination_number">
              <pagination :data="tableData" @pagination-change-page="getResults" :limit="5"></pagination>
            </div>
            <!-- END PAGINATION -->

          </card>
        </div>
      </div>
      <!-- END EMAIL LIST  -->

      <!-- EMAIL TABLE MODAL -->
      <modal name="read-email-mytrip" v-has-permission = "`mytrip_email_read`">
        <div class="row" style="height: 100%">
          <div class="col-12">
            <div class="card" style="height: 100%;border-radius: 0px">
              <h4 class="card-header title">Featured</h4>
              <div class="card-body m-auto">
                <div>
                  <h5 class="card-header title">Message:</h5>
                  <p  class="card-body m-auto" v-html="selectedEmail.Message"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <!-- END EMAIL TABLE MODAL -->

    </div>
</template>
<script>

    import Vue from 'vue';
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import { BasePagination } from 'src/components';
    import laravel_vue_pagination from 'laravel-vue-pagination';
    import axios from 'axios';
    import vmodal from 'vue-js-modal'
    Vue.use(vmodal);
    Vue.component('pagination', laravel_vue_pagination);
    export default {
        components: {
            BasePagination,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        data() {
            return {
                tableData :{},
                tableColumns: [
                    {
                        prop: 'user.name',
                        label: 'User',
                        minWidth: 150
                    },
                    {
                        prop: 'mytrip_data.person_email',
                        label: 'To',
                        minWidth: 200
                    },
                    {
                        prop: 'created_at',
                        label: 'DateTime',
                        minWidth: 120
                    }
                ],
                arrayData :[],
                selectedEmail :{},
                searchQuery : ""
            };
        },
        mounted() {
            this.getResults();
        },
        methods: {
            getResults(page = 1 ){
                let data = {
                    'page'  : page
                };
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/EmailsData/get-emails', data, User.prototype.getHeader())
                    .then((response)=>{
                         this.tableData = response.data.data;
                         this.arrayData = this.tableData.data;
                    })
            },
            handleRead(index,row){
                this.selectedEmail = row;
                this.$modal.show('read-email-mytrip');
            }
        },
    };
</script>

<style>
  .mytrip_operators td,.mytrip_operators th{
    text-align: center !important;
  }
</style>
