<template>
    <div>
        <div v-has-permission="`review`">
            <div class="col-12">
                <card card-body-classes="table-full-width">
                    <div>
                        <!-- TABLE SHOW DETAILS -->
                        <el-table
                            style="width: 100%;"
                            :data="arrayData.data"
                            class="center-header-table"
                        >
                            <el-table-column
                                width="80"
                                type="index"
                            ></el-table-column>
                            <el-table-column
                                v-for="column in tableColumns"
                                :key="column.label"
                                :min-width="column.minWidth"
                                :prop="column.prop"
                                :label="column.label"
                            >
                            </el-table-column>
                        </el-table>
                        <!-- END TABLE SHOW DETAILS -->
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import axios from "axios";

export default {
    components: {
        BasePagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    props: {
        arrayData: {
            default: [
                {
                    data: [{}]
                }
            ]
        }
    },
    data() {
        return {
            tableColumns: [
                {
                    prop: "E-mail",
                    label: "E-mail",
                    minWidth: 250
                },
                {
                    prop: "Country",
                    label: "Country",
                    minWidth: 180
                },
                {
                    prop: "Phone",
                    label: "Phone",
                    minWidth: 180
                },
                {
                    prop: "Status",
                    label: "Status",
                    minWidth: 180
                },
                {
                    prop: "Accommodation",
                    label: "Accommodation",
                    minWidth: 180
                },
                {
                    prop: "Adults",
                    label: "Adults",
                    minWidth: 100
                },
                {
                    prop: "Approved discount",
                    label: "Approved discount",
                    minWidth: 120
                },
                {
                    prop: "Arrival",
                    label: "Arrival",
                    minWidth: 120
                },
                {
                    prop: "Children",
                    label: "Children",
                    minWidth: 100
                },
                {
                    prop: "Code",
                    label: "Code",
                    minWidth: 180
                },
                {
                    prop: "Contingent",
                    label: "Contingent",
                    minWidth: 180
                },
                {
                    prop: "Currency",
                    label: "Currency",
                    minWidth: 180
                },
                {
                    prop: "Date of creation",
                    label: "Date of creation",
                    minWidth: 180
                },
                {
                    prop: "Departure",
                    label: "Departure",
                    minWidth: 180
                },
                {
                    prop: "No. nights",
                    label: "No. nights",
                    minWidth: 100
                },
                {
                    prop: "Object",
                    label: "Object",
                    minWidth: 180
                },
                {
                    prop: "Origin",
                    label: "Origin",
                    minWidth: 180
                },
                {
                    prop: "Rate plan",
                    label: "Rate plan",
                    minWidth: 180
                },
                {
                    prop: "Reservation holder",
                    label: "Reservation holder",
                    minWidth: 180
                },
                {
                    prop: "Total",
                    label: "Total",
                    minWidth: 100
                }
            ],
            searchQuery: ""
        };
    }
};
</script>

<style>
.center-header-table td,
.center-header-table th {
    text-align: center !important;
}
</style>
