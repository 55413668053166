<template>
    <div>
        <div class="d-flex flex-row justify-content-between align-items-center mb-4">
            <!-- Page Title -->
            <h1 class="mb-0">Discount Codes</h1>
            <!-- /Page Title -->

            <!-- Add Discount Button -->
            <base-button type="info" size="sm" @click="addDiscountButton()">Add Discount</base-button>
            <!-- /Add Discount Button -->

            <!-- Create Discount Modal -->
            <create-discount-modal :modalStatus="modalStatus" v-on:closemodal="closeDiscountModal()"></create-discount-modal>
            <!-- /Create Discount Modal -->
        </div>

        <!-- Discounts List -->
        <discounts-list></discounts-list>
        <!-- /Discounts List -->
    </div>
</template>


<script>
    import DiscountsList from "src/components/Flight/Discounts/DiscountsList";
    import CreateDiscountModal from "src/components/Flight/Discounts/CreateDiscountModal";


    export default {
        name: 'DiscountsIndex',


        components: {
            CreateDiscountModal,
            DiscountsList
        },


        data(){
            return{
                modalStatus: false,
            }
        },


        methods:{
            addDiscountButton(){
                this.modalStatus = true;
            },

            closeDiscountModal(){
                this.modalStatus = false;
            }
        },

    }
</script>

