<template>
  <div>
    <div class="content">
      <!-- CHARTS -->
      <div class="row">

        <!-- MONTHLY CHART -->
        <div class="col-12" v-has-permission="`mytrip_monthsChart`">
          <monthly-chart></monthly-chart>
        </div>
        <!-- END MONTHLY CHART -->

        <!--GROUP TYPE CHART-->
        <div class="col-12"  v-has-permission="`mytrip_categoryChart`">
          <group-type-chart></group-type-chart>
        </div>
        <!--GROUP TYPE CHART-->

      </div>
      <!-- END CHARTS -->
    </div>

      <!-- FORM LIST -->
      <div v-has-permission="`mytrip_formList`">
        <div class="col-12">
          <card card-body-classes="table-full-width">
            <div class="table-shopping">

              <!-- SEARCH IN MYTRIP DATA -->
              <div class="col-6 d-flex justify-content-center justify-content-sm-between flex-wrap float-left">
                <base-input>
                  <el-input
                    v-has-permission="`mytrip_search`"
                    type="search"
                    class="mb-3 search-input"
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    aria-controls="datatables"
                    v-model="searchQuery"
                  >
                  </el-input>
                </base-input>
              </div>
              <!-- END SEARCH IN MYTRIP DATA -->

              <!-- MYTRIP CATEGORY CHART BUTTON-->
              <div class="col-6 d-flex justify-content-center justify-content-sm-between flex-wrap float-right"
                   style="text-align: right;display: block;width: 100%;"
                   v-has-permission = "`mytrip_categoryChart`"
              >
                <div
                  style="text-align: right;display: block;width: 100%;"
                  v-has-permission = "`mytrip_categoryChart`"
                >
                  <base-button
                    @click.native="handleThings(true)"
                    style="width: 220px"
                    icon
                    v-has-permission = "`mytrip_categoryChart`"
                    type="success"
                    class="animation-on-hover btn btn-behance"
                  >
                    Show Things Chart
                  </base-button>
                </div>
              </div>
              <!-- END MYTRIP CATEGORY CHART BUTTON-->

              <!-- TABLE PAGINATION -->
              <el-table style="width: 100%" :data="arrayData">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>

                <!-- READ OR UNREAD STATUS  -->
                <el-table-column :min-width="95" label="Status" align="center">
                  <div slot-scope="{ row }">
                    <p v-if="row.status === 'YES'" style="color: green">read</p>
                    <p v-if="row.status === 'No'"  style="color: red;">unread</p>
                  </div>
                </el-table-column>
                <!-- END READ OR UNREAD STATUS  -->

                <!-- READ MORE BUTTON -->
                <el-table-column :min-width="135" align="right" label="Actions">
                  <div slot-scope="props">
                    <router-link
                      v-has-permission="`mytrip_formPersonDetails`"
                      :to="{ name: 'information', params: { data: props.row } }"
                      type="success"
                      class="animation-on-hover btn btn-behance"
                      style="padding: 12px; font-size: 14px"
                    >Read More
                    </router-link>
                  </div>
                </el-table-column>
                <!-- END READ MORE BUTTON -->

              </el-table>
              <!-- END TABLE PAGINATION -->

            </div>

            <!-- PAGINATION -->
            <div class="pagination_number pt-3">
              <pagination :data="tableData" @pagination-change-page="getResults" :limit="3"></pagination>
            </div>
            <!-- END PAGINATION -->

          </card>
        </div>
      </div>
      <!--END FORM LIST -->

      <!-- CATEGORY CHART MODAL -->
      <modal name="ThingsChart" v-has-permission = "`mytrip_categoryChart`">
          <base-button
            @click.native="handleThings(false)"
            class="add btn-link"
            style="color: #ec250d;font-size: 2em;float: right"
            type="danger"
            icon
          >
            <i class="tim-icons el-icon-close"></i>
          </base-button>
          <things-group-chart></things-group-chart>
      </modal>
      <!-- END CATEGORY CHART MODAL -->

    </div>
  </div>
</template>
<script>
    import Vue from 'vue';
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import { BasePagination } from 'src/components';
    import laravel_vue_pagination from 'laravel-vue-pagination';
    import axios from 'axios';
    import monthlyChart from "./MonthlyChart.vue";
    import groupTypeChart from "./GroupTypeChart.vue";
    import thingsGroupChart from "./ThingsGroupChart.vue";
    import vmodal from 'vue-js-modal'
    Vue.use(vmodal);
    Vue.component('pagination', laravel_vue_pagination);
    export default {
        components: {
            BasePagination,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            monthlyChart,
            groupTypeChart,
            thingsGroupChart
        },
        data() {
            return {
                tableData :{},
                tableColumns: [
                    {
                        prop: 'person_name',
                        label: 'Name',
                        minWidth: 110
                    },
                    {
                        prop: 'person_email',
                        label: 'Email',
                        minWidth: 280
                    },
                    {
                        prop: 'person_phone',
                        label: 'Phone',
                        minWidth: 180
                    },
                    {
                        prop: 'mytrip_agencies.name',
                        label: 'Agency',
                        minWidth: 180
                    },
                    {
                        prop: 'created_at',
                        label: 'Registration date',
                        minWidth: 190
                    }
                ],
                arrayData :[],
                searchQuery :"",
            };
        },
        watch:{
            'searchQuery'(){
                if (!! this.searchQuery.length){
                    this.search(this.searchQuery)
                } else {
                    this.getResults(1);
                }
            }
        },
        mounted() {
            this.getResults();
        },
        methods: {
            /**
             * get mytrip data page by page
            **/
            getResults(page = 1 ){
                let data = {
                    'page'  : page
                };
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/mytripData/get_mytripData' , data , User.prototype.getHeader())
                    .then((response)=>{
                        this.tableData = response.data.data.data;
                        this.arrayData = this.tableData.data;
                    })
            },
            /**
             * search word by word
             * searchData key is required .
            **/
            search(search){

                let data = {
                    'searchData'  : search
                };
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/mytripData/search_mytripData' , data , User.prototype.getHeader())
                    .then((response)=>{
                        this.tableData = response.data.data.data;
                        this.arrayData = this.tableData.data;
                    })

            },
            handleThings(status){
                status ? this.$modal.show('ThingsChart') : this.$modal.hide('ThingsChart');
            }
        },
    };
</script>

<style>
  .mytrip_dashboard td,.mytrip_dashboard th{
    text-align: center !important;
  }
  .mytrip_dashboard .v--modal-box.v--modal{
    top: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
  }
  .mytrip_dashboard .v--modal-overlay{
    z-index: 999999;
  }
  .mytrip_dashboard .v--modal-overlay .v--modal-box {
    position: absolute !important;
  }
  .mytrip_dashboard .stats-container {
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.7);
    padding-right: 20px;
  }

  .mytrip_dashboard .stats-total {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .mytrip_dashboard .stats-total-numbers {
    min-width: 240px;
    display: flex;
    justify-content: space-between;
  }
</style>
