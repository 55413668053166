<template>
  <div class="row mytrip_operators">
    <TravelInsuranceForm></TravelInsuranceForm>
  </div>
</template>
<script>
    import TravelInsuranceForm from 'src/components/Form/TravelInsuranceForm.vue';
    export default {
        components: {
            TravelInsuranceForm
        },
        data() {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
        mounted() {

        },
        beforeDestroy() {

        }
    };
</script>

<style></style>
