var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("review"),expression:"`review`"}]},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"card-body-classes":"table-full-width"}},[_c('div',[_c('el-table',{staticClass:"center-header-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.arrayData}},[_vm._l((_vm.tableColumns),function(column){return _c('el-table-column',{key:column.label,attrs:{"min-width":column.minWidth,"prop":column.prop,"label":column.label}})}),_c('el-table-column',{attrs:{"min-width":"135","label":"Answers"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(props.row.answers.length != 0),expression:"props.row.answers.length != 0"}]},[_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("review"),expression:"`review`"}],staticClass:"animation-on-hover btn btn-info",staticStyle:{"padding":"5px","font-size":"12px"},attrs:{"to":{
                                        path:
                                            "/reviews/answer/" +
                                            props.row.id,
                                        params: props.row
                                    },"type":"info"}},[_vm._v("Read Answers ")])],1)}}])}),_c('el-table-column',{attrs:{"min-width":"135","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("review"),expression:"`review`"},{name:"show",rawName:"v-show",value:(
                                        props.row.reviewable_type ===
                                            'App\\Entities\\Hotel\\HotelDetails'
                                    ),expression:"\n                                        props.row.reviewable_type ===\n                                            'App\\\\Entities\\\\Hotel\\\\HotelDetails'\n                                    "}],staticClass:"animation-on-hover btn btn-behance",staticStyle:{"padding":"12px","font-size":"14px"},attrs:{"to":{
                                        path:
                                            "/reviews/hotel/" +
                                            props.row.id,
                                        params: props.row
                                    },"type":"success"}},[_vm._v("Read More ")])],1)}}])})],2)],1),_c('div',{staticClass:"pagination_number"},[_c('pagination',{attrs:{"data":_vm.tableData,"limit":3},on:{"pagination-change-page":_vm.getResults}})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }