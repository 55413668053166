<template>
    <modal :show.sync="modal"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">
            <template>
                <div class="text-center text-muted mb-4">
                    <h3>Filter UTMs</h3>
                </div>
                <form role="form">
                    <base-input label="Start Date">
                        <el-date-picker v-model="filterData.from"
                                        type="date"
                                        placeholder="Start Date">
                        </el-date-picker>
                    </base-input>
                    <base-input label="End Date">
                        <el-date-picker v-model="filterData.to"
                                        type="date"
                                        placeholder="End Date">
                        </el-date-picker>
                    </base-input>
                    <base-input label="Source">
                        <select class="form-control" id="exampleFormControlSelect1" v-model="filterData.source">
                            <option value="all" selected>All</option>
                            <option :value="source" v-for="source in utmFilters.sources">{{ source }}</option>
                        </select>
                    </base-input>
                    <base-input label="Campaign">
                        <select class="form-control" id="exampleFormControlSelect1" v-model="filterData.campaign">
                            <option value="all" selected>All</option>
                            <option :value="campaign" v-for="campaign in utmFilters.campaigns">{{ campaign }}</option>
                        </select>
                    </base-input>
                    <base-input label="Medium">
                        <select class="form-control" id="exampleFormControlSelect1" v-model="filterData.medium">
                            <option value="all" selected>All</option>
                            <option :value="medium" v-for="medium in utmFilters.mediums">{{ medium }}</option>
                        </select>
                    </base-input>
                    <base-input label="Content">
                        <select class="form-control" id="exampleFormControlSelect1" v-model="filterData.content">
                            <option value="all" selected>All</option>
                            <option :value="content" v-for="content in utmFilters.contents">{{ content }}</option>
                        </select>
                    </base-input>
                    <div class="text-center">
                        <base-button type="info" class="my-4" @click="doFilter()">Filter</base-button>
                        <base-button type="danger" class="my-4" @click="resetFilter()">Reset</base-button>
                    </div>
                </form>
            </template>
        </card>
    </modal>
</template>


<script>
    import {BaseButton, Modal} from 'src/components'
    import {DatePicker, TimeSelect} from 'element-ui'
    import axios from 'axios';
    import Vue from 'vue';
    import store from 'src/store';
    import moment from 'moment';

    export default {
        name: 'FilterUTMModal',


        components: {
            BaseButton,
            Modal,
            [DatePicker.name]: DatePicker,
            [TimeSelect.name]: TimeSelect,
        },


        data(){
            return{
                filterData: {
                    from: null,
                    to: null,
                    source: 'all',
                    campaign: 'all',
                    medium: 'all',
                    content: 'all',
                },
                utms: [],
                orgUTMs: [],
            }
        },


        mounted(){
            // this.$root.$on('get-companies', function (value) {
            //     this.companies = value;
            // }.bind(this));
        },


        methods:{
            doFilter(){
                this.utmItems = store.getters.getOrgUTMItems;

                // Source
                if(this.filterData.source != null && this.filterData.source != 'all')
                    this.utmItems = this.utmItems.filter((item) => item.utm_source == this.filterData.source)

                // Campaign
                if(this.filterData.campaign != null && this.filterData.campaign != 'all')
                    this.utmItems = this.utmItems.filter((item) => item.utm_campaign == this.filterData.campaign)

                // Medium
                if(this.filterData.medium != null && this.filterData.medium != 'all')
                    this.utmItems = this.utmItems.filter((item) => item.utm_medium == this.filterData.medium)

                // Content
                if(this.filterData.content != null && this.filterData.content != 'all'){
                    this.utmItems = this.utmItems.filter((item) => item.utm_content == this.filterData.content)
                }

                // From
                if(this.filterData.from != null){
                    this.utmItems = this.utmItems.filter((item) => moment(item.created_at).isAfter(moment(this.filterData.from)))
                }

                // To
                if(this.filterData.to != null){
                    this.utmItems = this.utmItems.filter((item) => moment(item.created_at).isBefore(moment(this.filterData.to)))
                }

                this.modal = false;
            },


            resetFilter(){
                this.filterData.from = true;
                this.filterData.to = true;
                this.filterData.source = 'all';
                this.filterData.campaign = 'all';
                this.filterData.medium = 'all';
                this.filterData.content = 'all';

                this.utmItems = store.getters.getOrgUTMItems;

                this.modal = false;
            },
        },


        props: [
            'filterModalStatus',
        ],


        computed: {
            modal:{
                get(){
                    return this.filterModalStatus
                },

                set(value){
                    this.$emit('closemodal')
                }
            },

            // utmItems(){
            //     return store.getters.getUTMItems;
            // },

            utmItems:{
                get(){
                    return store.getters.getUTMItems;
                },

                set(value){
                    store.dispatch('setUTMItemsAction', value);
                }
            },

            utmFilters(){
                return store.getters.getUTMFilterItems;
            },
        },

    }
</script>