var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("review"),expression:"`review`"}]},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"card-body-classes":"table-full-width"}},[_c('div',[_c('div',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("hashtag"),expression:"`hashtag`"}],staticClass:"col-6 d-flex justify-content-center justify-content-sm-between flex-wrap float-right",staticStyle:{"text-align":"right","display":"block","width":"100%"}},[_c('base-input',{staticStyle:{"width":"100%"}},[_c('router-link',{staticClass:"animation-on-hover btn btn-behance",staticStyle:{"padding":"12px","font-size":"14px"},attrs:{"to":{ name: 'Create Hashtag' },"type":"success"}},[_vm._v("Create New Tag ")])],1)],1),_c('el-table',{staticClass:"center-header-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.arrayData}},[_vm._l((_vm.tableColumns),function(column){return _c('el-table-column',{key:column.label,attrs:{"min-width":column.minWidth,"prop":column.prop,"label":column.label}})}),_c('el-table-column',{attrs:{"min-width":"135","label":"Edit"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('div',{},[_c('router-link',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("hashtag"),expression:"`hashtag`"}],staticClass:"edit btn-link",attrs:{"to":{
                                        path:
                                            "/hashtag/update/" +
                                            props.row.name +
                                            "/" +
                                            props.row.service,
                                        params: props.row
                                    },"type":"warning","size":"sm","icon":""}},[_c('i',{staticClass:"tim-icons icon-pencil",staticStyle:{"color":"green"}})])],1)}}])})],2)],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }