<template>
    <!--<div>-->
        <!--<h1>Agents</h1>-->
        <!--<agents-list></agents-list>-->
    <!--</div>-->

    <div>

        <div class="d-flex flex-row justify-content-between align-items-center mb-4">
            <!-- Page Title -->
            <h1 class="mb-0">Agents</h1>
            <!-- /Page Title -->

            <!-- Filter Results -->
            <base-button type="info" size="sm" @click="modalStatus=true">Update</base-button>
            <!-- /Filter Results -->

            <!-- Filter Booked Modal -->
            <update-agent-modal :modalStatus="modalStatus" v-on:closemodal="modalStatus=false"></update-agent-modal>
            <!-- /Filter Booked Modal -->
        </div>

        <agents-list></agents-list>

    </div>
</template>


<script>
    import AgentsList from "src/components/Flight/Agents/AgentsList";
    import UpdateAgentModal from "src/components/Flight/Agents/UpdateAgentModal";

    export default {
        name: 'AgentsIndex',
        components: {
            AgentsList,
            UpdateAgentModal
        },


        data(){
            return{
                modalStatus: false,
            }
        },
    }
</script>

