<template>
    <div>
        <div class="d-flex flex-row justify-content-between align-items-center mb-4">
            <!-- Page Title -->
            <h1 class="mb-0">Booked Flight</h1>
            <!-- /Page Title -->


            <!-- Filter & Export Buttons -->
            <div>
                <base-button type="info" size="sm" @click="modalStatus=true">Filter</base-button>
                <download-excel
                        class   = "btn btn-sm btn-primary"
                        :data   = "excelReport"
                        :fields = "excelFields"
                        type    = "csv"
                        name    = "flight-report.xls">
                    Download Excel
                </download-excel>
            </div>
            <!-- /Filter & Export Buttons -->


            <!-- Filter Booked Modal -->
            <filter-booked-flight-modal :modalStatus="modalStatus" v-on:closemodal="modalStatus=false"></filter-booked-flight-modal>
            <!-- /Filter Booked Modal -->
        </div>

        <booked-flight-table></booked-flight-table>
    </div>
</template>


<script>
    import BookedFlightTable from "src/components/Flight/Booked/BookedFlightTable";
    import FilterBookedFlightModal from "src/components/Flight/Booked/FilterBookedFlightModal";
    import Vue from 'vue'
    import axios from 'axios';

    export default {
        name: 'BookedFlightIndex',


        components: {
            FilterBookedFlightModal,
            BookedFlightTable
        },


        data(){
            return{
                modalStatus: false,
                excelFields: {
                    "ID": "uniID",
                    "From": "from",
                    "to": "to",
                    "Departure Date": "departureDate",
                    "Airline": "airline",
                    "Flight Number": "flightNumber",
                    "Passengers Count": "passengersCount",
                    "Phone": "phone",
                    "Email": "email",
                    "Rial Amount": "rialAmount",
                    "Euro Amount": "euroAmount",
                    "Company": "company",
                    "API": "api",
                    "Time": "createdAt",
                },
                excelReport: [],
            }
        },



        mounted(){
            this.getExcelReportData();
        },



        methods:{
            getExcelReportData(){
                axios.get(Vue.prototype.websiteURL + 'admin/flight/' + 'export/excel')
                    .then(res => {
                        // Get Data for Excel Report
                        this.excelReport = res.data.data;
                    })
                    .catch(e => {
                        //
                    })
            }
        },

    }
</script>
