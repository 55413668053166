<template>
  <card class="card-chart" v-if="groupTypeChart.status" style="height: 100%;min-height: 1100px">
    <template slot="header">
      <div class="row">
        <div class="col-6 text-left">
          <h5 class="card-category">Chart by Things type</h5>
        </div>
        <div class="col-sm-6 d-flex d-sm-block" id="changeChart">
          <div
            class="btn-group btn-group-toggle float-right"
            data-toggle="buttons"
          >
            <!-- 3 BUTTON TO CHANGE TYPE OF CHART -> THINGS,HOTEL,PLACES -->
            <label
              v-for="(option, index) in ThingsTypeCategories"
              :key="option.name"
              class="btn btn-sm btn-primary btn-simple"
              :class="{ active: ThingsType.activeIndex === index }"
              :id="index"
            >

              <!-- INPUT FOR CHANGE CATEGORY BY initThingsChart METHOD -->
              <input
                type="radio"
                @click="initThingsChart(index)"
                name="options"
                autocomplete="off"
                :checked="ThingsType.activeIndex === index"
              />
              <!-- END INPUT FOR CHANGE CATEGORY BY initThingsChart METHOD -->

              <span class="d-none d-sm-block">{{ option.name }}</span>
              <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
            </label>
            <!-- END 3 BUTTON TO CHANGE TYPE OF CHART -> THINGS,HOTEL,PLACES -->

          </div>
        </div>
      </div>
    </template>
    <!-- CHART -->
    <div class="chart-area" style="height: 100%;">
      <bar-chart
        ref="thingsChart"
        :chart-data="groupTypeChart.chartData"
        :extra-options="groupTypeChart.extraOptions"
        :gradient-colors="groupTypeChart.gradientColors"
        :gradient-stops="groupTypeChart.gradientStops"
        :height = "290"
      >
      </bar-chart>
    </div>
    <!-- END CHART -->

  </card>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios';
    import BarChart from 'src/components/Charts/BarChart';
    import config from '@/config';
    import * as chartConfigs from '@/components/Charts/config';
    let ChartDatasetOptions = {
        extraOptions: {
            maintainAspectRatio: true,
            legend: {
                display: false
            },
            responsive: true,
            tooltips: {
                backgroundColor: '#f5f5f5',
                titleFontColor: '#333',
                bodyFontColor: '#666',
                bodySpacing: 4,
                xPadding: 12,
                mode: "nearest",
                intersect: 0,
                position: "nearest"
            },
            scales: {
                yAxes: [{
                    barPercentage: 1.0,
                    gridLines: {
                        drawBorder: false,
                        color: 'rgba(29,140,248,0.0)',
                        zeroLineColor: "transparent",
                    },
                    ticks: {
                        suggestedMin: 50,
                        suggestedMax: 50,
                        padding: 0,
                        fontColor: "#000000"
                    }
                }],

                xAxes: [{
                    barPercentage: 1.0,
                    gridLines: {
                        drawBorder: false,
                        color: 'rgba(220,53,69,0.1)',
                        zeroLineColor: "transparent",
                    },
                    ticks: {
                        padding: 0,
                        fontColor: "#000000"
                    }
                }]
            }


        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0],
        categories: []
    };
    export default {
        name: "ThingsGroupChart.vue",
        components:{
            BarChart,
            [Option.name]: Option,
        },
        data(){
            return{
                groupTypeChart: {
                    chartData: {
                        labels: ["2019 Oct", "2019 Nov"],
                        datasets: []
                    },
                    status : false,
                },
                ThingsType : {
                    activeIndex: 0
                }}
        },
        computed: {
            ThingsTypeCategories() {
                return [{ name: 'Things', icon: 'tim-icons icon-single-02' }, { name: 'Hotel', icon: 'tim-icons icon-gift-2' }, { name: 'Places', icon: 'tim-icons icon-tap-02' }];
            }
        },
        mounted() {
            this.getThingsTypeChart();
        },
        methods:{
            /**
             * get charts values
            **/
            getThingsTypeChart(){
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/get/chart/get-tings-for-chart' , null , User.prototype.getHeader())
                    .then((response)=>{
                        this.groupTypeChart.chartData.labels    = response.data.data.labels[0];
                        this.groupTypeChart.chartData.datasets  = response.data.data.values;
                        this.groupTypeChart.status = true ;
                    })
            },

            /**
             * change chart state
             * post
            **/
            initThingsChart(index) {
                let data = {
                    'status' : this.ThingsTypeCategories[index].name
                };
                this.ChartStatus(false);
                axios.post(Vue.prototype.websiteURL + 'admin/mytrip/panel/get/chart/get-tings-for-chart' , data , User.prototype.getHeader())
                    .then((response)=>{
                        let chartData = {
                            datasets  : response.data.data.values,
                            labels    : response.data.data.labels[0]
                        };
                        this.$refs.thingsChart.updateGradients(chartData);
                        this.groupTypeChart.chartData    = chartData;
                        this.ThingsType.activeIndex      = index;
                        this.ChartStatus(true);
                    });
            },
            ChartStatus(status){
                let changeChart = document.getElementById("changeChart").children[0];
                status ? changeChart.style.display = 'block' : changeChart.style.display = 'none';
            }
        }
    }
</script>

<style scoped>

</style>
