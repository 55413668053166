<template>
    <div class="content" v-has-permission = "`Form`">
      <!-- EMAIL LIST  -->
      <div class="row">
        <div class="col-12">
          <card card-body-classes="table-full-width">

            <!-- TABLE -->
            <div>
              <el-table :data="arrayData">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>

                <!-- READ BUTTON -->
                <el-table-column :min-width="135" align="right" label="Actions">
                  <div slot-scope="props">
                    <base-button
                      @click.native="handleRead(props.$index, props.row)"
                      class="add btn-link"
                      style="color: #ec250d;font-size: 2em"
                      type="danger"
                      icon
                      v-has-permission = "`Form`"
                    >
                      <i class="tim-icons icon-email-85"></i>
                    </base-button>

                  </div>
                </el-table-column>
                <!-- END READ BUTTON -->

              </el-table>
            </div>
            <!-- END TABLE -->

            <!-- PAGINATION -->
            <div class="pagination_number">
              <pagination :data="tableData" @pagination-change-page="getResults" :limit="5"></pagination>
            </div>
            <!-- END PAGINATION -->

          </card>
        </div>
      </div>
      <!-- END LIST  -->

      <!-- options MODAL -->
      <modal name="options" v-has-permission = "`Form`" :dynamic="true" :resizable="true" :scrollable="true" :width="900" :height="800">
        <div class="row" style="height: 100%">
          <div class="col-12">
            <div class="card" style="height: 100%;border-radius: 0px">
              <h4 class="card-header title">options:</h4>
              <div class="card-body">
                <div class="modal-list-item">
                <h5 class="card-header title">Name:</h5>
                <p class="card-body m-auto" v-html="selected.options.name"></p>
                </div>
                <div class="modal-list-item">
                  <h5 class="card-header title">gender:</h5>
                  <p class="card-body m-auto" v-html="selected.options.gender"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Arrival Date:</h5>
                <p class="card-body m-auto" v-html="selected.options.arrival_date"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Duration:</h5>
                <p class="card-body m-auto" v-html="selected.options.duration"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Price: {{selected.options.price}}</h5>
                <h5 class="card-header title" v-if="selected.options.full_price ? true : false" >full price: {{selected.options.full_price}}</h5>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Phone:</h5>
                <p class="card-body m-auto" v-html="selected.options.phone"></p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">social media:</h5>
                <p class="card-body m-auto" v-for="social in selected.options.social_media" :key="social" style="display:inline-block">{{ social }}</p>
                </div>
                <div class="modal-list-item">
                <h5 class="card-header title">Email:</h5>
                <p class="card-body m-auto" v-html="selected.options.email"></p>
                </div>
                
                <div class="modal-list-block p-10">
                  <h5 class="card-header title">wirecard masked account number:</h5>
                  <p class="card-body m-auto" v-html="selected.options.wirecard_masked_account_number"></p>
                </div>

                <div class="modal-list-block p-10" v-if="selected.options.options !== [] || selected.options.options !== null">
                  <h5 class="card-header title">options:</h5>
                  <div class="card-body m-auto">
                      <ul  v-for="option in selected.options.options" :key="option.desctiption"  style="display:inline-block">
                        <li>count: {{option.count? option.count : 'null'}}</li>
                        <li>price: {{option.price? option.price : '0'}}</li>
                        <li>desctiption: {{option.desctiption? option.desctiption: 'null'}}</li>
                      </ul>
                  </div>
                </div>
                <div class="modal-list-block p-10">
                  <h5 class="card-header title">Images:</h5>
                  <a class="card-body m-auto" :href="selected.options.image" target="_blank"> image </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <!-- END options MODAL -->

    </div>
</template>
<script>

    import Vue from 'vue';
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import { BasePagination } from 'src/components';
    import laravel_vue_pagination from 'laravel-vue-pagination';
    import axios from 'axios';
    import vmodal from 'vue-js-modal'
    Vue.use(vmodal);
    Vue.component('pagination', laravel_vue_pagination);
    export default {
        components: {
            BasePagination,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        data() {
            return {
                tableData :{},
                tableColumns: [
                    {
                        prop: 'options.name',
                        label: 'name',
                        minWidth: 100
                    },
                    {
                        prop: 'options.price',
                        label: 'price',
                        minWidth: 40
                    },
                    {
                        prop: 'options.phone',
                        label: 'phone',
                        minWidth: 120
                    },
                    {
                        prop: 'options.email',
                        label: 'email',
                        minWidth: 150
                    },
                    {
                        prop: 'options.status',
                        label: 'status',
                        minWidth: 50
                    },
                    {
                        prop: 'order_number',
                        label: 'resnumber',
                        minWidth: 50
                    },
                ],
                arrayData :[],
                selected :{
                  "id": 1,
                  "options": {
                      "type": "",
                      "name": "",
                      "email": "",
                      "arrival_date": "",
                      "duration": "",
                      "price": "",
                      "status": "",
                      "gender": "",
                      "phone": "",
                      "social_media": [],
                      "image": "",
                      "url": "",
                      "full_price": "0",
                      "code": "0",
                      "discount_id": 0,
                      "wirecard_masked_account_number": "null",
                      "wirecard_created_at": "null",
                      "options": [
                        {
                            "count": 0,
                            "price": 0,
                            "desctiption": "0"
                        }
                    ],
                  },
                  "token": "",
                  "order_number": "",
                  "type": "",
                  "created_at": "",
                  "updated_at": ""
                },
                searchQuery : ""
            };
        },
        mounted() {
            this.getResults();
        },
        methods: {
            getResults(page = 1 ){
                axios.get(Vue.prototype.websiteURL + 'admin/form/travel-insurance?page=' + page, User.prototype.getHeader())
                    .then((response)=>{
                        this.tableData = response.data.data;
                        this.arrayData = this.tableData.data;
                    })
            },
            handleRead(index,row){
                this.selected = row;
                this.$modal.show('options');
            }
        },
    };
</script>

<style>
  .mytrip_operators td,.mytrip_operators th{
    text-align: center !important;
  }
  .modal-list-item {
    display: inline-block;
    min-width: 250px;
    border: solid 1px silver;
    margin: 15px;
    min-height: 100px;
  }
  .modal-list-block{
    border: solid 1px silver;
    margin: 15px;
    display: block;
  }
  .p-10{
      padding: 10px;
  }
</style>
