<template>
  <div class="row">
    <div class="col-md-12">
      <el-table :data="queriedData">

        <!-- Index -->
        <!--<el-table-column min-width="50"-->
                         <!--type="index"-->
                         <!--label="#"-->
        <!--&gt;-->
        <!--</el-table-column>-->
        <!-- /Index -->

        <!-- Unique ID -->
        <el-table-column min-width="150"
                         prop="uniID"
                         label="Unique ID"
        >
        </el-table-column>
        <!-- Unique ID -->

        <!-- Departure Airport -->
        <el-table-column min-width="300"
                         prop="from"
                         label="From"
        >
        </el-table-column>
        <!-- /Departure Airport -->

        <!-- Arrival Airport -->
        <el-table-column min-width="300"
                         prop="to"
                         label="To"
        >
        </el-table-column>
        <!-- /Arrival Airport -->

        <!-- Way of Trip -->
        <el-table-column min-width="200"
                         prop="way"
                         label="Way"
        >
          <span slot-scope="{ row }">
            <span v-if="row.way == 'dep' || row.way == 'departure'">
              Departure
            </span>
            <span v-if="row.way == 'arr' || row.way == 'arrival'">
              Arrival
            </span>
          </span>
        </el-table-column>
        <!-- /Way of Trip -->

        <!-- Departure Time -->
        <el-table-column min-width="300"
                         prop="departureDate"
                         label="Departure Date"
        >
        </el-table-column>
        <!-- /Departure Time -->

        <!-- Arrival Time -->
        <el-table-column min-width="300"
                         prop="arrivalDate"
                         label="Arrival Date"
        >
        </el-table-column>
        <!-- /Arrival Time -->

        <!-- Flight Number -->
        <el-table-column min-width="200"
                         prop="flightNumber"
                         label="Flight Number"
        >
        </el-table-column>
        <!-- /Flight Number -->

        <!-- Reservation Status -->
        <el-table-column min-width="250"
                         prop="reserveStatus"
                         label="Reserve Status"
        >
        </el-table-column>
        <!-- /Reservation Status -->

        <!-- Passenger Email -->
        <el-table-column min-width="350"
                         prop="email"
                         label="Email"
        >
        </el-table-column>
        <!-- /Passenger Email -->

        <!-- Rial Amount -->
        <el-table-column min-width="150"
                         prop="rialAmount"
                         label="Rial Amount"
        >
                    <span slot-scope="{ row }" v-text="row.rialAmount + ' Rial'">

                    </span>
        </el-table-column>
        <!-- /Rial Amount -->

        <!-- Euro Amount -->
        <el-table-column min-width="150"
                         prop="paidAmount"
                         label="Paid Amount"
        >
                    <span slot-scope="{ row }" v-text="Math.max(row.totalEuro, row.realEuro) + ' €'">

                    </span>
        </el-table-column>
        <!-- /Euro Amount -->

        <!-- Operation Airline -->
        <el-table-column min-width="300"
                         prop="operatingAirline"
                         label="Operation Airline"
        >
        </el-table-column>
        <!-- /Operation Airline -->

        <!-- Payment Status -->
        <el-table-column min-width="170"
                         prop="paymentStatus"
                         label="Payment Status"
        >
          <span slot-scope="{ row }">
            <span v-if="row.paymentStatus=='SUCCESS'">
              <i class="tim-icons icon-check-2 icon--green"></i>
            </span>
            <span v-else>
              <i class="tim-icons icon-simple-remove icon--red"></i>
            </span>
          </span>
        </el-table-column>
        <!-- /Payment Status -->

        <!-- Travelers Information Page Link -->
        <el-table-column min-width="170"
                         prop="travelersInfo"
                         label="Travelers Info."
        >
          <span slot-scope="{ row }">
            <a :href="row.travelersInfo" target="_blank">Show</a>
          </span>
        </el-table-column>
        <!-- /Travelers Information Page Link -->

        <!-- Company -->
        <el-table-column min-width="220"
                         prop="company"
                         label="Company"
        >
        </el-table-column>
        <!-- /Company -->

        <!-- Flight API -->
        <el-table-column min-width="200"
                         prop="api"
                         label="API"
        >
          <span slot-scope="{ row }">
            <span v-if="row.api == 'ch724'">
              Charter724
            </span>
            <span v-if="row.api != 'ch724'">
              Alibaba
            </span>
          </span>
        </el-table-column>
        <!-- /Flight API -->

        <!-- Creation Time -->
        <el-table-column min-width="170"
                         prop="time"
                         label="Time"
        >
        </el-table-column>
        <!-- /Creation Time -->

      </el-table>

      <!-- Pagination -->
      <base-pagination v-model="pagination.currentPage"
                       :per-page="pagination.perPage"
                       :total="total"
      >
      </base-pagination>
      <!-- /Pagination -->

    </div>
  </div>
</template>


<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import { BasePagination } from 'src/components'
  import axios from 'axios'
  import Vue from 'vue'


  export default {
      name: 'ReservedFlightTable',


      components: {
        BasePagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
      },


      data(){
          return{
              reservedFlightsData: [],
              pageNum: 1,
              pagination: {
                  perPage: 20,
                  currentPage: 1,
                  total: 0
              },
              searchQuery: '',
          }
      },


      mounted() {
          this.getData();
      },

      methods:{
          getData(){
              axios.get( Vue.prototype.websiteURL + "admin/flight/" + "reports?mode=reserve")
                  .then(res => {
                      res.data.reservedFlights.forEach(function (item) {
                          this.reservedFlightsData.push({
                              'uniID'           : item.uniId,
                              'from'            : item.from,
                              'to'              : item.to,
                              'way'             : item.way,
                              'departureDate'   : item.departureDate,
                              'arrivalDate'     : item.arrivalDate,
                              'flightNumber'    : item.flightNumber,
                              'reserveStatus'   : item.reserve_status,
                              'email'           : item.email,
                              'rialAmount'      : item.alibaba_amount,
                              'totalEuro'       : item.total_euro_amount,
                              'realEuro'        : item.real_paid_amount,
                              'operatingAirline': item.operatingAirLine,
                              'paymentStatus'   : item.payment_status,
                              'travelersInfo'   : '/flight/travelers-info/' + item.uniId,
                              'company'         : item.company,
                              'api'             : item.source,
                              'time'            : item.created_at,
                          })
                      }.bind(this))
                  })
                  .catch(e => {
                      //
                  })
          }
      },


      computed: {
          queriedData() {
              let result = this.reservedFlightsData;
              if (this.reservedFlightsData.length > 0) {
                  result = this.reservedFlightsData;
              }
              return result.slice(this.from, this.to);
          },
          to() {
              let highBound = this.from + this.pagination.perPage;
              if (this.total < highBound) {
                  highBound = this.total;
              }
              return highBound;
          },
          from() {
              return this.pagination.perPage * (this.pagination.currentPage - 1);
          },
          total() {
              return this.reservedFlightsData.length > 0
                      ? this.reservedFlightsData.length
                      : this.reservedFlightsData.length;
          }
      },
  }
</script>


<style>
  .icon--green{
    color: #00897B;
  }

  .icon--red{
    color: #E91E63;
  }
</style>
