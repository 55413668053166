<template>
    <div class="content" v-has-permission="`Hotel`">
        <div class="row">
            <div class="col-12">
                <card card-body-classes="table-full-width">
                    <div>
                        <!-- SEARCH IN MYTRIP DATA -->
                        <div
                            class="col-6 d-flex justify-content-center justify-content-sm-between flex-wrap float-left"
                        >
                            <base-input>
                                <el-input
                                    type="search"
                                    class="mb-3 search-input"
                                    prefix-icon="el-icon-search"
                                    placeholder="Search records"
                                    aria-controls="datatables"
                                    v-model="searchQuery"
                                >
                                </el-input>
                            </base-input>
                        </div>
                        <!-- END SEARCH IN MYTRIP DATA -->
                        <!-- CREATE A NEW THING -->
                        <div
                            class="col-6 d-flex justify-content-center justify-content-sm-between flex-wrap float-right"
                            style="text-align: right;display: block;width: 100%;"
                            v-has-permission="`Hotel`"
                        >
                            <!-- CREATE A NEW THING BUTTON -->
                            <base-input style="width: 100%;">
                                <router-link
                                    :to="{ name: 'Create Hotel' }"
                                    type="success"
                                    class="animation-on-hover btn btn-behance"
                                    style="padding: 12px; font-size: 14px"
                                    >Create New Hotel
                                </router-link>
                            </base-input>
                            <!-- END CREATE A NEW THING BUTTON -->
                        </div>
                        <!-- END CREATE A NEW THING -->

                        <!-- THINGS TABLE -->
                        <el-table :data="arrayData">
                            <el-table-column
                                v-for="column in tableColumns"
                                :key="column.label"
                                :min-width="column.minWidth"
                                :prop="column.prop"
                                :label="column.label"
                            >
                            </el-table-column>

                            <!-- BUTTONS IN THINGS TABLE -->
                            <el-table-column
                                :min-width="135"
                                align="right"
                                label="Actions"
                            >
                                <div slot-scope="props">
                                    <!-- UPDATE A THING IF STATUS === YES  -->
                                    <router-link
                                        :to="{
                                            name: 'Edit Hotel',
                                            params: { data: props.row }
                                        }"
                                        class="edit btn-link"
                                        type="warning"
                                        size="sm"
                                        icon
                                        v-has-permission="`Hotel`"
                                    >
                                        <i
                                            class="tim-icons icon-pencil"
                                            style="color: green"
                                        ></i>
                                    </router-link>
                                    <!-- END UPDATE A THING IF STATUS === YES  -->
                                </div>
                            </el-table-column>
                            <!-- END BUTTONS IN THINGS TABLE -->
                        </el-table>
                        <!-- END THINGS TABLE -->
                    </div>

                    <!-- PAGINATION -->
                    <div class="pagination_number">
                        <pagination
                            :data="tableData"
                            @pagination-change-page="getResults"
                            :limit="5"
                        ></pagination>
                    </div>
                    <!-- END PAGINATION -->
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import swal from "sweetalert2";
import laravel_vue_pagination from "laravel-vue-pagination";
import axios from "axios";
Vue.component("pagination", laravel_vue_pagination);
export default {
    components: {
        BasePagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            searchQuery: "",
            tableData: {},
            tableColumns: [
                {
                    prop: "name",
                    label: "Hotel Name",
                    minWidth: 150
                },
                {
                    prop: "second_name",
                    label: "Second Name",
                    minWidth: 180
                },
                {
                    prop: "star",
                    label: "Star",
                    minWidth: 100
                },
                {
                    prop: "token",
                    label: "Token",
                    minWidth: 180
                },
                {
                    prop: "updated_at",
                    label: "Update At",
                    minWidth: 120
                }
            ],
            arrayData: []
        };
    },
    watch: {
        searchQuery() {
            if (!!this.searchQuery.length) {
                this.search(this.searchQuery);
            } else {
                this.getResults(1);
            }
        }
    },
    mounted() {
        this.getResults();
    },
    methods: {
        /**
         * get things page by page
         **/
        getResults(page = 1) {
            axios
                .get(
                    Vue.prototype.websiteURL +
                        "admin/hotel/hotels?page=" +
                        page,
                    User.prototype.getHeader()
                )
                .then(response => {
                    this.tableData = response.data.data;
                    this.arrayData = this.tableData.data;
                });
        },
        /**
         * handleDelete for remove things by call deleteRow
         **/
        handleDelete(index, row) {
            swal({
                title: "Are you sure?",
                text: `You won't be able to revert this!`,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, delete it!",
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.deleteRow(index, row);
                }
            });
        },
        /**
         * remove thing by ID
         **/
        deleteRow(index, row) {
            axios
                .delete(
                    Vue.prototype.websiteURL + "admin/hotel/hotels",
                    { id: row.id },
                    User.prototype.getHeader()
                )
                .then(response => {
                    let indexToDelete = this.tableData.data.findIndex(
                        tableRow => tableRow.id === row.id
                    );
                    if (indexToDelete >= 0) {
                        this.tableData.data.splice(indexToDelete, 1);
                    }
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                })
                .catch(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "warning",
                        "The operation you requested did not succeed"
                    );
                });
        },
        notifyVue(verticalAlign, horizontalAlign, status, message) {
            this.$notify({
                message: message,
                timeout: 30000,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: horizontalAlign,
                verticalAlign: verticalAlign,
                type: status
            });
        },
        /**
         * handle the status of thing
         **/
        handleStatus(index, row) {
            axios
                .post(
                    Vue.prototype.websiteURL +
                        "admin/hotel/hotels/update/status",
                    { id: row.id },
                    User.prototype.getHeader()
                )
                .then(response => {
                    let updateIndex = this.tableData.data.findIndex(
                        tableRow => tableRow.id === row.id
                    );
                    this.tableData.data[updateIndex].status = "YES";
                    this.notifyVue(
                        "top",
                        "right",
                        "success",
                        "The operation you requested was successful"
                    );
                })
                .catch(response => {
                    this.notifyVue(
                        "top",
                        "right",
                        "warning",
                        "The operation you requested did not succeed"
                    );
                });
        },
        /**
         * search word by word
         * searchData key is required .
         **/
        search(search) {
            let data = {
                searchData: search
            };
            axios
                .post(
                    Vue.prototype.websiteURL + "admin/hotel/hotels/search/all",
                    data,
                    User.prototype.getHeader()
                )
                .then(response => {
                    this.tableData = response.data.data;
                    this.arrayData = this.tableData.data;
                });
        }
    }
};
</script>

<style>
.mytrip_things td,
.mytrip_things th {
    text-align: center !important;
}
</style>
