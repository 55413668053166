<template>
  <div class="row" v-has-permission="`transfer_dashboard_formList_read`" v-show="show">
    <div class="col-12">
      <div class="card">
        <div class="row">

          <!-- PERSON INFO -> PASSENGER, LEADER NAME, EMAIL, PHONE  -->
          <div v-has-permission="`transfer_dashboard_formList_read_person`" class="Transfer_person col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Person:
                </h4>
                <ul class="card-body">
                  <li>Passengers: {{fullInformation.passengers}}</li>
                  <li>Leader Name: {{fullInformation.leader_name}}</li>
                  <li>Email: {{fullInformation.email}}</li>
                  <li>Phone: {{fullInformation.phone}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END PERSON INFO -> PASSENGER, LEADER NAME, EMAIL, PHONE  -->

          <!-- PERSON FORM LIST -> Departure -->
          <div v-has-permission="`transfer_dashboard_formList_read_departure`" class="Transfer_departure col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Departure:
                </h4>
                <ul class="card-body">
                  <li>Departure: {{fullInformation.departure_address}}</li>
                  <li>Destination Name: {{fullInformation.Destination_Address}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END PERSON FORM LIST -> Departure -->

          <!-- FORM LIST INFO -> CITY, FROM, TO, VISA, AMOUNT -->
          <div v-has-permission="`transfer_dashboard_formList_read_information`" class="Transfer_information col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  information:
                </h4>
                <ul class="card-body">
                  <li>City: {{fullInformation.city}}</li>
                  <li>From: {{fullInformation.from}}</li>
                  <li>To: {{fullInformation.to}}</li>
                  <li v-show="fullInformation.airline !== null">Flight: {{fullInformation.airline}}</li>
                  <li>Visa: {{fullInformation.visa}}</li>
                  <li>Amount: {{fullInformation.amount}}</li>
                  <li v-show="fullInformation.file !== ''">File: {{fullInformation.file}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END FORM LIST INFO -> CITY, FROM, TO, VISA, AMOUNT -->

          <!-- ARRIVAL DATE -> DATE/TIME -->
          <div v-has-permission="`transfer_dashboard_formList_read_arrivalDate`" class="Transfer_arrivalDate col-12">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Arrival Date:
                </h4>
                <ul class="card-body">
                  <li>Date / Time: {{fullInformation.arrival_date}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END ARRIVAL DATE -> DATE/TIME -->

          <!-- EXTRA SERVICES -->
          <div v-has-permission="`transfer_dashboard_formList_read_extraServices`" class="Transfer_extraServices col-12" v-show="fullInformation.vip_services !== null">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Extra Services:
                </h4>
                <ul class="card-body">
                  <li v-for="(item,key) in fullInformation.vip_services" v-bind:key="key">{{item}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END EXTRA SERVICES -->

          <!-- COMMENT -->
          <div v-has-permission="`transfer_dashboard_formList_read_comment`" class="Transfer_comment col-12" v-show="fullInformation.comment !== null">
            <ul class="list-group list-group-flush">
              <li class="list-group-item card-header">
                <h4 class="text-bold card-title">
                  Comment:
                </h4>
                <ul class="card-body">
                  <li>{{fullInformation.comment}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- END COMMENT -->

        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import Vue from 'vue';
    import axios from 'axios';
    import { Modal, BaseAlert } from 'src/components';
    export default {
        components:{
            Modal,
            BaseAlert
        },
        data(){
            return {
                show : false,
                fullInformation :{},
            }
        },
        mounted(){
            this.getData();
        },
        methods:{
            /**
             * GET data from url
            **/
            getData(){
                this.fullInformation = this.$route.params.data;
                this.fullInformation.status !== "YES" ? this.checkStatus() : this.show = true;
            },

            /**
             * check status and update status
            **/
            checkStatus(){
                axios.post(Vue.prototype.websiteURL + 'admin/transfer/panel/transfer/update/update-transfer-status' , {'id' : this.fullInformation.id} , User.prototype.getHeader())
                    .then(response=>{
                        this.fullInformation = response.data.data;
                        this.show = true;
                    })
                    .catch(response=>{
                        this.show = false;
                        this.$router.push({ name: "Transfer Dashboard"});
                    });
            },

            notifyVue(verticalAlign , horizontalAlign , status , message) {
                this.$notify({
                    message: message,
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: horizontalAlign,
                    verticalAlign: verticalAlign,
                    type: status
                });
            }
        }
    };
</script>

<style>
  .m-t-b-3{
    margin: 30px auto !important;
  }
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline{
    padding: 14px;
  }

  .card .alert {
    position: relative !important;
    width: 100%;
  }

  .Transfer_person ul.list-group.list-group-flush,
  .Transfer_information ul.list-group.list-group-flush,
  .Transfer_information ul.list-group.list-group-flush,
  .Transfer_extraServices ul.list-group.list-group-flush {
    background: rgba(130,130,130,00.1);
  }
</style>
